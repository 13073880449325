import PromotionsManager from './PromotionsManager'
import PromotionPriority from './PromotionPriority'
export default [
  {
    path: 'promotion/catalog-rule',
    component: () => import('@/components/Views/Catalog/PriceRule/Main'),
    name: 'marketing_promotion_catalog_rule',
    meta: {
      title: 'Catalog Price Rules'
    }
  },
  {
    path: 'promotion/catalog-rule/new',
    component: () => import('@/components/Views/Catalog/PriceRule/Form'),
    name: 'marketing_promotion_catalog_rule_new',
    meta: {
      title: 'New Catalog Price Rule'
    }
  },
  {
    path: 'promotion/catalog-rule/edit/:id',
    component: () => import('@/components/Views/Catalog/PriceRule/Form'),
    name: 'marketing_promotion_catalog_rule_edit',
    meta: {
      title: 'Edit Catalog Price Rule'
    }
  },
  {
    path: 'promotions',
    component: () => import('@/components/Views/Marketing/Promotions/Main'),
    name: 'marketing_promotion',
    meta: {
      title: 'Promotion Manager'
    }
  },
  {
    path: 'promotions-priority',
    component: () => import('@/components/Views/Marketing/Promotions/Priority'),
    name: 'marketing_promotion_priority',
    meta: {
      title: 'Promotion Priority'
    }
  },
  {
    path: 'promotion/new/:type?',
    component: () => import('@/components/Views/Marketing/Promotions/Form'),
    name: 'marketing_promotion_new',
    meta: {
      title: 'New Promotion',
      type: 'new'
    }
  },
  {
    path: 'promotion/:id',
    component: () => import('@/components/Views/Marketing/Promotions/Form'),
    name: 'marketing_promotion_edit',
    meta: {
      title: 'Update Promotion',
      type: 'edit'
    }
  },
  {
    path: 'promotions-manager',
    component: () => import('@/components/Layouts/ChildLayout'),
    children: PromotionsManager
  },
  {
    path: 'priority-manager',
    component: () => import('@/components/Layouts/ChildLayout'),
    children: PromotionPriority
  },
  /** NEWSLETTER */
  {
    path: 'newsletters',
    component: () => import('@/components/Views/Customer/Newsletters/Main'),
    name: 'newsletters',
    meta: {
      title: 'Newsletter'
    }
  },
  {
    path: 'email-signups',
    component: () => import('@/components/Views/EmailSignupQueue/Main'),
    name: 'Email Signups',
    meta: {
      title: 'Email Signups'
    }
  },
]
