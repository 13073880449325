<template>
  <div class="d-flex flex-wrap" :class="className">
    <div v-for="(button, index) in buttons" :key="(button.type ? button.type : button.label) + index" :class="button.class || 'ml-2'">
      <template v-if="button && !button.hidden">
        <jv-button
          v-if="button && !button.key"
          :type="button.type"
          :plain="button.plain"
          :icon="button.icon && button.iconPosition !== 'right' ? button.icon : ''"
          :class="$isMobile ? 'w-100' : ''"
          @click="doAction(button)"
          :disabled="button.disabled"
          :custom-style="button.customStyle"
          :id="button.id || `toolbar-button-${index}`"
        >
          <template v-if="!button.isHideLabel">{{ button.label }}</template>
          <template v-if="button.icon && button.iconPosition === 'right'"
            ><i :class="button.icon + (!button.isHideLabel ? '  ml-2' : '')"></i
          ></template>
        </jv-button>
        <el-dropdown 
          v-if="button && button.key === 'dropdown'" 
          class="w-100" 
          @command="handleCommand" 
          :disabled="button.disabled" 
          :show-timeout="50"
          :trigger="$isMobile ? 'click' : 'hover'"
        >
          <jv-button
            :type="button.type"
            :plain="button.plain"
            :icon="button.icon && button.iconPosition !== 'right' ? button.icon : ''"
            :class="button.customClass + ` ${$isMobile ? 'w-100' : ''}`"
            @click="doAction(button)"
            :disabled="button.disabled"
            :custom-style="button.customStyle"
          >
            <template v-if="!button.isHideLabel">{{ button.label }}</template>
            <template v-if="button.icon && button.iconPosition === 'right'"
              ><i :class="button.icon + (!button.isHideLabel ? '  ml-2' : '')"></i
            ></template>
          </jv-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(btn, j) in button.data" :key="j" :command="btn" :disabled="btn.disabled">{{
              btn.label
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-switch v-if="button && button.key === 'switch'" :inactive-text="button.label" v-model="button.value" @change="doAction(button)">
        </el-switch>
        <el-popover
          trigger="click"
          placement="bottom-end"
          v-model="button.visible"
          v-if="button && button.key === 'popover' && button.newUI && !$isMobile"
          popper-class="popover-content p-0"
        >
          <h6 class="new fw-600 fs-18 ml-3 mr-3">{{ button.headerLabel }}</h6>
          <div class="preset-columns">
            <table class="wr-table options-table">
              <tr v-for="(item, idx) in button.data?.filter(item => item.fixed)" :key="item.field_name + idx" class="text-left handle">
                <td class="text-left pl-3 td-icon"></td>
                <td><span style="font-weight: 500">{{ item.title }}</span></td>
                <td class="text-right pr-3">
                  <el-switch 
                    v-model="item.is_active" :disabled="item.is_default"
                    inactive-color="#89909F"
                    active-color="#5183B6"
                    class="el-switch__jovy green"
                  > </el-switch>
                </td>
              </tr>
              <draggable
                v-if="Array.isArray(button.data) && button.data?.length"
                v-model="button.data"
                tag="tbody"
                handle=".handle"
                :animation="250"
              >
                <tr v-for="(item, idx) in button.data" :key="item.field_name + idx" class="text-left handle">
                  <template v-if="!item.fixed">
                    <td class="text-left pl-3 td-icon"><i class="bi bi-grip-vertical"></i></td>
                    <td><span style="font-weight: 500">{{ item.title }}</span></td>
                    <td class="text-right pr-3">
                      <el-switch 
                        v-model="item.is_active" :disabled="item.is_default"
                        inactive-color="#89909F"
                        active-color="#5183B6"
                        class="el-switch__jovy green"
                      > </el-switch>
                    </td>
                  </template>
                </tr>
              </draggable>
            </table>
          </div>
          <div
            class="d-flex justify-content-between mt-2 p-3"
            v-if="Array.isArray(button.buttons) && button.buttons.length"
          >
            <jv-button
              v-for="(btnAction, idx) in button.buttons"
              :key="'btnAction' + idx"
              size="medium"
              :type="btnAction.type"
              :plain="btnAction.plain"
              :icon="btnAction.icon && btnAction.iconPosition !== 'right' ? btnAction.icon : ''"
              :class="$isMobile ? 'w-100' + btnAction.class : btnAction.class"
              @click="doAction(btnAction)"
              :disabled="btnAction.disabled"
            >
              <template v-if="!btnAction.isHideLabel">{{ btnAction.label }}</template>
              <template v-if="btnAction.icon && btnAction.iconPosition === 'right'"
                ><i :class="btnAction.icon + (!btnAction.isHideLabel ? '  ml-2' : '')"></i
              ></template>
            </jv-button>
          </div>
          <jv-button 
            slot="reference"
            :icon="button.icon"
            :custom-class="button.customClass"
            type="primary" plain size="icon"
          >
          </jv-button>
        </el-popover>
        <el-popover
          trigger="click"
          placement="bottom-end"
          v-model="button.visible"
          v-else-if="button && button.key === 'popover' && !$isMobile"
          popper-class="popover-content p-0"
        >
          <h6>{{ button.headerLabel }}</h6>
          <div class="preset-columns">
            <table class="wr-table options-table">
              <draggable
                v-if="Array.isArray(button.data) && button.data?.length"
                v-model="button.data"
                tag="tbody"
                handle=".handle"
                :animation="250"
              >
                <tr v-for="(item, idx) in button.data" :key="item.field_name + idx" class="text-left handle">
                  <td class="text-left pl-3 td-icon"><img :src="require(`@/assets/images/cc/union.svg`)" height="auto" /></td>
                  <td><span style="font-weight: 500">{{ item.title }}</span></td>
                  <td class="text-right pr-3">
                    <el-switch v-model="item.is_active" :disabled="item.is_default"> </el-switch>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>

          <div
            class="d-flex justify-content-between mt-2 p-3"
            v-if="Array.isArray(button.buttons) && button.buttons.length"
          >
            <el-button
              v-for="(btnAction, idx) in button.buttons"
              :key="'btnAction' + idx"
              size="medium"
              :type="btnAction.type"
              :plain="btnAction.plain"
              :icon="btnAction.icon && btnAction.iconPosition !== 'right' ? btnAction.icon : ''"
              :class="$isMobile ? 'w-100' + btnAction.class : btnAction.class"
              @click="doAction(btnAction)"
              :disabled="btnAction.disabled"
            >
              <template v-if="!btnAction.isHideLabel">{{ btnAction.label }}</template>
              <template v-if="btnAction.icon && btnAction.iconPosition === 'right'"
                ><i :class="btnAction.icon + (!btnAction.isHideLabel ? '  ml-2' : '')"></i
              ></template>
            </el-button>
          </div>
          <el-button
            slot="reference"
            size="medium"
            :type="button.type"
            :plain="button.plain"
            :icon="button.icon && button.iconPosition !== 'right' ? button.icon : ''"
            @click="doAction(button)"
            :disabled="button.disabled"
          >
            <template v-if="!button.isHideLabel">{{ button.label }}</template>
            <template v-if="button.icon && button.iconPosition === 'right'"
              ><i :class="button.icon + (!button.isHideLabel ? '  ml-2' : '')"></i
            ></template>
          </el-button>
        </el-popover>
        <jv-button 
          v-if="button && button.key === 'popover' && $isMobile && button.newUI"
          :icon="button.icon"
          :custom-class="button.customClass"
          type="primary" plain size="icon"
          @click="button.visible = true"
        >
        </jv-button>
        <el-button
          v-else-if="button && button.key === 'popover' && $isMobile"
          @click="button.visible = true"
          slot="reference"
          size="medium"
          :type="button.type"
          :plain="button.plain"
          :icon="button.icon && button.iconPosition !== 'right' ? button.icon : ''"
          :class="$isMobile ? 'w-100' : ''"
          :disabled="button.disabled"
        >
          <template v-if="!button.isHideLabel">{{ button.label }}</template>
          <template v-if="button.icon && button.iconPosition === 'right'"
            ><i :class="button.icon + (!button.isHideLabel ? '  ml-2' : '')"></i
          ></template>
        </el-button>
        <el-drawer
          :append-to-body="true"
          v-if="$isMobile"
          :visible.sync="button.visible"
          :with-header="false"
          size="85%"
          custom-class="drawer-content"
        >
          <h6>{{ button.headerLabel }}</h6>
          <table class="wr-table options-table">
            <tr v-for="(item, idx) in button.data?.filter(item => item.fixed)" :key="item.field_name + idx" class="text-left handle">
              <td class="text-left pl-3 td-icon"></td>
              <td><span style="font-weight: 500">{{ item.title }}</span></td>
              <td class="text-right pr-3">
                <el-switch v-model="item.is_active" :disabled="item.is_default"> </el-switch>
              </td>
            </tr>
            <draggable
              v-if="Array.isArray(button.data) && button.data?.length"
              v-model="button.data"
              tag="tbody"
              handle=".handle"
              :animation="250"
            >
              <tr v-for="(item, idx) in button.data" :key="item.field_name + idx" class="text-left handle">
                <template v-if="!item.fixed">
                  <td class="text-left pl-3 td-icon"><img :src="require(`@/assets/images/cc/union.svg`)" height="auto" /></td>
                  <td><span style="font-weight: 500">{{ item.title }}</span></td>
                  <td class="text-right pr-3">
                    <el-switch v-model="item.is_active" :disabled="item.is_default"> </el-switch>
                  </td>
                </template>
              </tr>
            </draggable>
          </table>
          <div
            class="d-flex justify-content-between mt-2 p-3"
            v-if="Array.isArray(button.buttons) && button.buttons.length"
          >
            <el-button
              v-for="(btnAction, idx) in button.buttons"
              :key="'btnAction' + idx"
              size="medium"
              :type="btnAction.type"
              :plain="btnAction.plain"
              :icon="btnAction.icon && btnAction.iconPosition !== 'right' ? btnAction.icon : ''"
              :class="$isMobile ? 'w-100 ' + btnAction.class : btnAction.class"
              @click="doAction(btnAction)"
              :disabled="btnAction.disabled"
            >
              <template v-if="!btnAction.isHideLabel">{{ btnAction.label }}</template>
              <template v-if="btnAction.icon && btnAction.iconPosition === 'right'"
                ><i :class="btnAction.icon + (!btnAction.isHideLabel ? '  ml-2' : '')"></i
              ></template>
            </el-button>
          </div>
        </el-drawer>
      </template>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: {
    buttons: {
      type: Array
    },
    className: {
      type: String
    }
  },
  components: {
    draggable,
  },
  data() {
    return {
      scrollY: null,
      originalTop: 0,
      isFixed: false,
      drawer: false,
    }
  },
  methods: {
    handleCommand(option) {
      if (option.callback && typeof option.callback == 'function') {
        option.callback()
      }
    },
    doAction(button) {
      if (button.data) return
      if (button.callback && typeof button.callback == 'function') {
        button.callback()
        // this.$emit('btnAction',button);
      } else if (button.route) {
        let route = { name: button.route }
        if (button.routeParams) {
          route.params = button.routeParams
        }
        this.$router.push(route)
      } else if (button.path) {
        this.$router.push(button.path)
      } else {
        console.warn(`Button "${button.label}" has an invalid action`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
:deep() .el-button {
  height: 36px;
}
#main-toolbar {
  padding: 15px 0;
  margin-bottom: 5px;
}
.wr-scroll-fixed {
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  width: 100%;
  z-index: 111;
  box-shadow: -4px -5px 7px black;
  padding: 15px 15px 15px ($sidebar-size + 15);
  height: $wr-nav-menu-height;
  max-height: $wr-nav-menu-height;
}
.popover-content h6 {
  text-align: center;
  background-color: #f2f2f2;
  padding: 10px 0;
  width: 350px;
  &.new {
    background-color: var(--jv-white);
    border-bottom: 1px solid var(--jv-gray-300);
    color: var(--jv-text);
  }
}
.drawer-content h6 {
  text-align: center;
  font-weight: 500;
  background-color: #F5F7FA;
  padding: 10px 0;
  line-height: 24px;
  font-size: 14px;
  color: var(--color);
}
.preset-columns {
  display: list-item;
  min-height: 150px;
  max-height: calc(80vh - 250px);
  overflow-y: auto;
}
:deep().wr-table tbody tr:nth-child(even) {
  background: #fff !important;
}
:deep().wr-table td {
  border-bottom: none !important;
  font-weight: 500;
  font-size: 14px;
  color: var(--color);
}
.td-icon {
  width: 40px;
}
</style>
