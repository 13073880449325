<template>
  <aside>
    <div class="sidebar d-flex justify-content-between flex-column" ref="sidebarWrapper" :class="{ 'h-100': !$isMobile }">
      <div class="d-flex align-items-center justify-content-start justify-content-lg-center logo">
        <img src="@/assets/images/jovy-logo-white.svg" alt="logo" class="d-none d-lg-block" />
        <account-info class="d-block d-lg-none w-100"></account-info>
      </div>
      <div v-if="isLimitedClient && !isAllShopSetupCompleted" class="shop-setup-container">
        <a id="btnShopSetup" href="/#/settings/shop-setup">
          <i class="bi bi-clipboard-check fs-24"></i>
          <span class="flex-fill">Shop Setup</span>
          <span>{{ shopSetupCompletedCount }}/{{ shopSetupCount }}</span>
        </a>
      </div>
      <div class="sidebar-items" v-if="!settingMenuTogle">
        <div class="wrapper-sidebar-menu" ref="sidebar">
          <ul v-if="hasACL && sidebars.length" class="mb-4">
            <template v-for="(sidebar, idx) in sidebars">
              <li
                :key="sidebar.url + idx"
                v-if="acl(sidebar.url) && !isMenuDisabled(sidebar.url) && featureToggleShow(sidebar, sidebars)"
                :class="{
                  'accordion-actived': activeNames === sidebar.url || sidebar.accordion_actived,
                  accordion: mobileScreen,
                  flyout: !mobileScreen && activeNames !== sidebar.url,
                }"
                @mouseover="handleFlyout(idx, activeNames.includes(sidebar.url), 'over')"
                @mouseout="handleFlyout(idx)"
                @click="handleArrcodion(sidebar)"
              >
                <div class="sidebar-item" v-if="sidebar.children && sidebar.children.length">
                  <template>
                    <div class="icon" v-if="sidebar.icon.includes('el-icon') || sidebar.icon.includes('bi bi-')">
                      <i :class="sidebar.icon"></i>
                    </div>
                    <feather-icon v-else :icon="sidebar.icon" />
                  </template>
                  <span class="w-100 d-flex align-items-center justify-content-between">
                    <span class="d-flex">
                      {{ sidebar.name }}
                      <span v-if="bubbleCounter(sidebar.name)" class="notification" role="status">{{ bubbleCounter(sidebar.name) }}</span>
                    </span>
                    <i
                      :class="
                        activeNames === sidebar.url || sidebar.accordion_actived
                          ? mobileScreen
                            ? 'el-icon-arrow-down'
                            : ''
                          : 'el-icon-arrow-right'
                      "
                    ></i>
                  </span>
                </div>
                <div v-else-if="$isMobile && sidebar.name == 'Settings'" class="sidebar-item d-lg-none">
                  <template>
                    <div class="icon" v-if="sidebar.icon.includes('el-icon') || sidebar.icon.includes('bi bi-')">
                      <i :class="sidebar.icon"></i>
                    </div>
                    <feather-icon v-else :icon="sidebar.icon" />
                  </template>
                  <span class="w-100 d-flex align-items-center justify-content-between">
                    {{ sidebar.name }}
                    <i class="el-icon-arrow-right"></i>
                  </span>
                </div>
                <template v-else-if="sidebar.externalLink">
                  <a v-show="sidebar.url && !sidebar.url.startsWith('.')" :href="getLink(sidebar)" target="_blank">
                    <template>
                      <div class="icon" v-if="sidebar.icon.includes('el-icon') || sidebar.icon.includes('bi bi-')">
                        <i :class="sidebar.icon"></i>
                      </div>
                      <feather-icon v-else :icon="sidebar.icon" />
                    </template>
                    {{ sidebar.name }}
                  </a>
                </template>
                <router-link v-else :to="getLink(sidebar)">
                  <template>
                    <div class="icon" v-if="sidebar.icon.includes('el-icon') || sidebar.icon.includes('bi bi-')">
                      <i :class="sidebar.icon"></i>
                    </div>
                    <feather-icon v-else :icon="sidebar.icon" />
                  </template>
                  {{ sidebar.name }}
                  <span v-if="bubbleCounter(sidebar.name)" class="notification" role="status">{{ bubbleCounter(sidebar.name) }}</span>
                </router-link>
                <div class="sub-flyout" :ref="`flyout-${idx}`" v-if="sidebar.children && sidebar.children.length">
                  <ul>
                    <template v-for="(child, indx) in sidebar.children">
                      <li
                        v-if="acl(sidebar.url) && !isMenuDisabled(getLink(sidebar, child)) && featureToggleShow(child, sidebar.children)"
                        :key="`${sidebar.url}-${child.url}-${indx}`"
                      >
                        <router-link :to="getLink(sidebar, child)" :class="{ active: getRouteActive(sidebar, child) }">
                          {{ child.name }}
                        </router-link
                        >
                      </li>
                    </template>
                  </ul>
                </div>
              </li>
            </template>
          </ul>
          <div class="px-2" v-else>
            <el-skeleton animated >
              <template slot="template">
                <el-skeleton-item v-for="(item, index) in Array.from({ length: 9 })" :key="index" variant="button" class="w-100 mt-2" />
              </template>
            </el-skeleton>
          </div>
          <div class="contact-support">
            <a href="mailto:support@jovy.shop">Contact Jovy support</a>
          </div>
        </div>
      </div>
      <div class="setting-sidebar p-12" v-else>
        <el-button @click="settingMenuTogle = false" type="text" class="p-0 lh-22 mb-1 text-brand-400">
          <i class="el-icon-arrow-left mr-2"></i>
          Main Menu
        </el-button>
        <setting-sidebar />
      </div>
    </div>
  </aside>
</template>
<script>
import { getNavItems } from '@/api/helper'
import FeatureToggleMixin from '@/mixins/feature-toggle'
import SettingSidebar from '@/components/Views/Settings/SidebarSettings'
import settingNavItems from '../Views/Settings/items'

export default {
  mixins: [FeatureToggleMixin],
  components: {
    AccountInfo: () => import('./Header/AccountInfo.vue'),
    SettingSidebar,
  },
  props: {
    isHeader: {
      type: Boolean,
      default: false,
    },
  },
  async created() {    
    window.addEventListener('resize', this.setActiveNames)
    await Promise.all([
      this.getMenuBubbleCounter(), 
      this.getNavItems()
    ])
  },
  computed: {
    sidebarHeight() {
      return this.$store.getters.sidebarHeight
    },
    hasACL() {
      let acl = this.$store.getters['acl']
      return acl === '*' || (Array.isArray(acl) && acl.length)
    },
    getCurrentSidebar() {
      return this.$store.getters.currentSidebar
    },
    siteUrl() {
      return this.$store.getters['baseUrl'] || ''
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.setActiveNames)
  },
  data() {
    return {
      sidebars: [],
      isSidebarExpand: false,
      activeNames: '',
      settingMenuTogle: false,
      mobileScreen: false,
    }
  },
  methods: {
    bubbleCounter(menuName) {
      if (!['Orders', 'Messages'].includes(menuName)) {
        return 0
      }
      const bubbleCount = this.$store.getters['menuBubbleCounter'] || {}
      let count = 0
      switch (menuName) {
        case 'Orders':
          count = bubbleCount.inprogressOrderCount
          break;
      case 'Messages':
          count = bubbleCount.unreadConversationCount
          break;
        default:
          break;
      }
      return count
    },
    async getNavItems() {
      await getNavItems()
        .then(({ data }) => {
          if (data.items) {
            this.sidebars = [...data.items] || []
            this.setActiveNames()
          }
          let restrictedItems = []
          if (data.restrictedItems) {
            restrictedItems = data.restrictedItems
          }
          settingNavItems.forEach(item => {
            const parentPath = `/${item.url}`
            if (!this.canShowItem(item)) {
              restrictedItems.push({ ...item, url: parentPath, children: [] })
            } else if (Array.isArray(item.children)) {
              restrictedItems.push(
                ...item.children
                  .filter(child => !this.canShowItem(child))
                  .map(childItem => ({ ...childItem, url: `${parentPath}/${childItem.url}` }))
              )
            }
          })
          const nonRestrictedRoutes = ['settings', 'content', 'headers', 'footers']
          restrictedItems = restrictedItems.filter(item => !nonRestrictedRoutes.includes(item.url))
          this.$store.dispatch('setRestrictedRoutes', restrictedItems)
          localStorage.setItem('restrictedItems', JSON.stringify(restrictedItems))
          if (data.disabledMenus && data.disabledMenus.length) {
            this.$store.dispatch('setDisabledMenus', { action: 'add', items: data.disabledMenus })
          }
        })
        .catch(() => {})
      this.sidebars.push({ name:"View Site", externalLink: true, url: '.siteUrl', icon:"bi bi-box-arrow-up-right" })
    },
    getLink(sidebar, children = null) {
      let path = '/'
      if (!sidebar.children || !sidebar.children.length) {
        if (sidebar.url.startsWith('.')) {
          const key = sidebar.url.substring(1)          
          if (this[key]) {
            this.$set(sidebar, 'url', this[key])
          }
        }
        path = /^(\/|https?)/.test(sidebar.url) ? sidebar.url : `/${sidebar.url}`
      } else if (children) {
        path = children.url.startsWith('/') ? children.url : `/${sidebar.url}/${children.url}`
      }
      return path
    },
    getRouteActive(sidebar, child) {
      const path = (child && child.url.startsWith('/') ? child.url : `/${sidebar.url}/${child.url}`) + '/'
      const currentPath = this.$route.meta.activePath || (this.$route.fullPath + '/')
      return currentPath === path
    },
    handleFlyout(idx, isAccordion = false, type = '') {
      const el = this.$refs[`flyout-${idx}`]
      const sidebarElem = this.$refs.sidebar
      const sidebarWrapper = this.$refs.sidebarWrapper
      if (!this.mobileScreen && el) {
        if (type && !isAccordion) {
          const topBarHeight = 67
          const rect = el[0].getBoundingClientRect()
          let top = -50 - sidebarElem.scrollTop
          if (rect.bottom - sidebarElem.scrollTop > window.innerHeight) {
            top = (rect.top - topBarHeight) * -1
            if (topBarHeight + rect.height < rect.top) top = (rect.top - (window.innerHeight - rect.height)) * -1
          }
          el[0].style.transform = `translate(183px, ${top}px)`
          sidebarWrapper.style['z-index']= '2006'
        } else {
          el[0].style.transform = 'none'
          sidebarWrapper.style.removeProperty('z-index');
        }
      }
    },
    setActiveNames() {
      this.mobileScreen = window.innerWidth < 1024
      let sideActived = this.sidebars.find((item) => {
        return (
          this.$route.fullPath.startsWith(`/${item.url}`) ||
          (item.children || []).some(
            (s) => (s.url.startsWith('/') ? s.url : `/${item.url}/${s.url}`) === this.$route.fullPath
          )
        )
      })
      if (this.$route.fullPath.startsWith('/settings/')) {
        sideActived = this.sidebars.find((sidebar) => sidebar.name === 'Settings')
        this.settingMenuTogle = this.mobileScreen
      }
      this.activeNames = sideActived ? sideActived.url : ''
    },
    handleArrcodion(sidebar) {
      if (sidebar.name == 'Settings' && this.mobileScreen) {
        this.settingMenuTogle = true
        return
      }
      if (this.mobileScreen) {
        this.sidebars.forEach((item) => {
          item.accordion_actived = item.url === sidebar.url || this.$route.fullPath.includes(item.url)
        })
        this.sidebars = JSON.parse(JSON.stringify(this.sidebars))
      }
    },
    featureToggleShow(item, list = []) {
      let show = this.featuresConfig[item.toggleKey] === 'all'
      if (!show) {
        show = item.featureToggle === this.featuresConfig[item.toggleKey]
      }
      if (show && item.featureToggle === 'new' && this.featuresConfig[item.toggleKey] === 'all') {
        const hasOldItem = list.find(i => i !== item && i.name === item.name && i.featureToggle === 'old')
        if (hasOldItem && !item.name.endsWith(' *')) {
          item.name += ' *'
        }
      }
      return show
    },
  },
  watch: {
    '$route.fullPath': {
      handler(val) {
        this.setActiveNames()
        this.handleArrcodion({ url: '' })
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.sidebar-menu {
  list-style: none;
  padding: 0;
  text-align: center;
}

.sidebar {
  @media (min-width: $lg) {
    width: $min-sidebar-size;
    position: fixed;
    top: 8px;
    left: 8px;
    bottom: 8px;
    z-index: 1008;
    max-height: calc(100% - 16px);
    overflow: auto;
  }
  .sidebar-item {
    cursor: pointer;
  }
  .sidebar-items {
    flex-grow: 1;
    padding-top: 8px;
    background: white;
    @media (min-width: $lg) {
      border-radius: 4px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    }
  }
  .wrapper-sidebar-menu {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      > li {
        > .sidebar-item,
        > a {
          padding: 9px 6px;
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          font-style: normal;
          color: var(--jv-text);
          border: 1px solid transparent;
          border-radius: 4px;
          display: flex;
          align-items: center;
        }
        &:hover {
          > .sidebar-item,
          > a {
            background: var(--jv-gray-100);
            border-color: var(--jv-gray-200);
            text-decoration: none;
            color: var(--jv-secondary);
            .icon {
              color: var(--jv-secondary);
            }
          }
        }
      }
    }
    > ul {
      padding: 0;
      > li {
        position: relative;
        padding: 0 0.5rem;
        margin-bottom: 0.5rem;
        a {
          color: var(--jv-text);
          font-weight: 500;
          &:hover {
            text-decoration: none;
          }
          &.active {
            font-weight: 700;
          }
        }
        svg {
          width: 26px;
        }

        .icon {
          font-size: 24px;
          color: var(--jv-brand-500);
          margin-right: 0.5rem;
        }
        > .sub-flyout {
          display: none;
          padding: 0.5rem 0 0.5rem 0.5rem;
        }
        ul {
          padding: 0;
          margin: 0;
        }
        &.accordion {
          .sub-flyout {
            display: none;
            li {
              a {
                border: 0;
                border-radius: 0;
                border-left: 2px solid var(--border-color);
                white-space: nowrap;
                overflow: hidden;

                &:hover,
                &.active {
                  background: var(--light-primary);
                  border-color: var(--primary);
                  text-decoration: none;
                }
              }
            }
          }
          .icon {
            color: var(--text-input);
          }
        }
        &.accordion-actived,
        .accordion {
          > .sidebar-item,
          > a {
            border-color: var(--border-color);
            background: var(--disabled-color);
            color: var(--text-input);
            font-weight: 700;
          }
          > .sub-flyout {
            display: block;
            li {
              a {
                border: 0;
                border-radius: 0;
                border-left: 2px solid var(--border-color);
                white-space: nowrap;
                overflow: hidden;
                display: block;
                &:hover,
                &.active {
                  background: var(--light-primary);
                  border-color: var(--primary);
                  text-decoration: none;
                }
              }
            }
          }
        }
        &.flyout {
          > .sub-flyout {
            position: fixed;
            min-width: 160px;
            z-index: 3010;
          }
          ul {
            border-width: 1px 1px 1px 0px;
            border-style: solid;
            border-color: #e4e9f1;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
            border-radius: 0px 4px 4px 0px;
            padding: 0;
            max-height: 100%;
            overflow: auto;
            background: #ffffff;
            margin-left: 0.5rem;
            li {
              overflow: hidden;
              a {
                border: 0;
                border-radius: 0;
                white-space: nowrap;
                overflow: hidden;
                display: block;
                font-weight: 500;
                padding: 12px;
                &:hover,
                &.active {
                  background: var(--light-primary);
                  color: var(--default);
                  text-decoration: none;
                  font-weight: 700;
                }
              }
            }
          }
          &:hover {
            > .sub-flyout {
              display: block;
            }
          }
        }
        .accordion-actived {
          .icon {
            color: var(--jv-secondary);
          }
        }
      }
    }
    overflow: auto;
    max-height: calc(100vh - 95px);
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      background: var(--light-primary);
      display: block;
    }
  }
}
.logo {
  background: var(--jv-brand-500);
  text-align: center;
  padding: 15px; // followed Figma
  height: $jv-header-height;
  @media (min-width: $lg) {
    border-radius: 4px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  }
  img {
    max-width: 100%;
    object-fit: contain;
    height: 30px; // followed Figma
  }
}
.shop-setup-container {
  background: var(--jv-white);
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px;
  #btnShopSetup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 4px 12px 0px 8px; 
    border-radius: 4px;
    background: var(--jv-yellow-200);
    border: 1px solid var(--jv-yellow-300);
    color: var(--jv-yellow-500);
    font-weight: 600;
    font-size: 14px;
  }
}
.notification {
  margin-left: 0.5em;
  min-width: 1.6em; 
  height: 1.6em;
  border-radius: 0.8em;
  border: 0.05em solid white;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.9em;
  padding: 5px;
}
.contact-support {
  text-align: center;
  position: fixed;
  bottom: 10px;
  background-color: white;
  margin-left: 0px;
  font-size: 14px;
  width: 260px;
  text-decoration: underline;
  @media (min-width: 981px) {
    width: 200px;
  }
}
</style>
