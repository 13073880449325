<template>
  <div v-if="Array.isArray(buttons) && buttons.length">
    <template v-for="(button, idx) in buttons">
      <el-button
        v-if="!button.is_hide"
        :class="(button.className || column.className) + ((button.key === 'up' && firstIndex) || (button.key === 'down' && lastIndex) ? ' invisible' : '')"
        :key="idx + button.label"
        :type="button.type"
        :plain="button.plain"
        @click="handleAction($event, button)"
        size="small"
        :icon="button.icon"
      ></el-button>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    buttons: {
      type: Array,
    },
    row: {
      type: Object,
    },
    column: {
      type: Object,
    },
    firstIndex: {
      type: Boolean,
    },
    lastIndex: {
      type: Boolean,
    },
  },
  methods: {
    handleAction(e, button) {
      e.stopPropagation()
      /** router = { name: 'marketing_promotion_edit', params: { id: row._id } } */
      if (button.router) {
        if (button.router.params && button.router.params.id) {
          button.router.params.id = this.row._id
          this.$router.push(button.router)
        }
      } else if (button.callback) button.callback(this.row)
    },
  },
}
</script>
