<template>
  <div class="sidebar-items">
    <el-collapse accordion class="border-0" v-model="activeNames">
      <template v-for="(sidebar, idx) in sidebarItems">
        <sidebar-item :sidebar="sidebar" :key="sidebar.url + idx" :search="search" :index="idx"
          v-if="(acl(sidebar.url) && !isMenuDisabled(sidebar.url))">
        </sidebar-item>
      </template>
    </el-collapse>
  </div>
</template>
<script>
import SidebarItem from './Sidebar/SidebarItem.vue'
export default {
  components: {
    SidebarItem,
  },
  props: {
    sidebarItems: {
      type: Array,
      require: true,
    },
    search: {
      type: Boolean,
      default: false,
    },
    searching: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSidebarExpand: false,
      activeNames: [],
    }
  },
  methods: {
    handleChange(val) {
      if (val) {
        let path = `/${val}`
        const activeItem = this.sidebarItems.find(item => item.url === val)
        if (activeItem && activeItem.children && activeItem.children.length) path += `/${activeItem.children[0].url}`
        this.$router.push(path).catch(() => { })
      } else {
        this.activeCollapse()
      }
    },
    activeCollapse() {
      let sideActived = (this.sidebarItems || []).filter(
        (f) =>
          (f.children || []).some(
            (s) => (s.url.startsWith('/') ? s.url : `/${f.url}/${s.url}`) === this.$route.fullPath) || this.$route.fullPath.includes(f.url)
      )
      if(this.searching) {
        sideActived = (this.sidebarItems || []).filter(sidebar => {
          return (sidebar.children || []).some(item => !item.isHide)
        })
      }
      this.activeNames = sideActived.length ? sideActived.map(item => item.url) : []
    }
  },
  watch: {
    '$route.fullPath': {
      handler(val) {
        this.activeCollapse()
      },
      deep: true,
    },
    sidebarItems: {
      handler() {
        this.activeCollapse()
      },
      deep: true,
    },
  },
  created() {
    this.activeCollapse()
  },
}
</script>
<style lang="scss" scoped>
.sidebar-items {
  :deep().el-collapse {
    background: transparent;

    .el-collapse-item {
      &.no-child {
        .el-collapse-item__arrow {
          display: none;
        }
      }

      &__header {
        border: 1px solid transparent;
        background-color: transparent;
        font-size: 14px !important;
        font-weight: 700;
        height: auto;
        line-height: 30px;
        padding: 0.5rem;

        &.is-active {
          background: var(--disabled-color);
          border-color: var(--border-color);
          border-radius: 4px;
          color: var(--text-input) !important;

          .icon {
            color: var(--text-input) !important;
          }
        }
      }

      &__content {
        margin-left: 0.5rem;
        margin-top: 0.5rem;
        padding: 0;

        div {
          padding: 0.5rem 0 0.5rem 0.75rem;
          border-left: 2px solid var(--border-color);
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;

          &.active {
            border-color: var(--primary);
            background: var(--light-primary);
            border-radius: 0 4px 4px 0;
            color: var(--primary);
          }
        }
      }

      &__wrap {
        border-bottom: 0;
      }

      .el-collapse-item {
        box-shadow: none;
        border-radius: 0;
        margin: 0;

        .el-collapse-item__header {
          padding-left: 3rem;
          box-shadow: inset 0px -1px 0px #dcdfe6;
        }

        .el-collapse-item__content {
          padding-left: 4rem;

          a {
            display: block;
            padding: 0.75rem 0;
            font-weight: bold;
            font-size: 14px;
            color: #606266;

            &:hover {
              color: $primary;
            }
          }
        }
      }

      .icon {
        font-size: 24px;
        color: var(--primary);
      }
    }
  }
}
</style>
