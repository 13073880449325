export default [{
    path: 'new/:type?',
    component: () => import('@/components/Views/Marketing/PromotionsManager/Form'),
    name: 'new_promotion',
    meta: {
      title: 'New Promotion',
      type: 'new'
    }
  },
  {
    path: '/',
    component: () => import('@/components/Views/Marketing/PromotionsManager/Main'),
    name: 'promotion_manager',
    meta: {
      title: 'Promotion Manager'
    }
  },
  {
    path: ':id',
    component: () => import('@/components/Views/Marketing/PromotionsManager/Form'),
    name: 'new_promotion_edit',
    meta: {
      title: 'Update Promotion',
      type: 'edit'
    }
  },
]
