import request from '@/utils/request'
export function login (form) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: form
  })
}

export function logout () {
  return request({
    url: '/auth/logout',
    method: 'post'
  })
}

export function sendVerificationCode(params) {
  return request({
    url: '/auth/send-verification-code',
    method: 'get',
    params: params,
  })
}

export function verifyCode(params) {
  return request({
    url: '/auth/verify-code',
    method: 'get',
    params: params
  })
}


export function forgotPassword(params) {
  return request({
    url: '/auth/forgot-password',
    method: 'get',
    params: params
  })
}

export function saveNewPassword(params) {
  return request({
    url: '/auth/forgot-password',
    method: 'post',
    data: params
  })
}

export function getUserByInviteHash(params) {
  return request({
    url: `/auth/user-by-invite-hash`,
    method: 'post',
    data: params
  })
}

export function updateInviteUser(params) {
  return request({
    url: `/auth/update-user-by-invite-hash`,
    method: 'put',
    data: params
  })
}
