import Vue from 'vue'

import ToolbarWidget from './ToolbarWidget'
import ToolbarButtons from './ToolbarButtons'
import FilterWidget from './FilterWidget'
import WebsiteSelector from './WebsiteSelector'
import PageSelector from './PageSelector'
import FilterWidgetV2 from './FilterWidgetV2'
import HMGrid from './HMGrid.vue'
import ModalWidget from './ModalWidget'
import FeatherIcon from './FeatherIcon'
import ElTableDraggable from './ElTableDraggable'
import FilterWidgetV3 from './FilterWidgetV3'
import OverrideInput from './OverrideInput'

Vue.component('toolbar-widget', ToolbarWidget)
Vue.component('filter-widget', FilterWidget)
Vue.component('website-selector', WebsiteSelector)
Vue.component('page-selector', PageSelector)
Vue.component('filter-widget-v2', FilterWidgetV2)
Vue.component('toolbar-buttons', ToolbarButtons)
Vue.component('hm-grid', HMGrid)
Vue.component('modal-widget', ModalWidget)
Vue.component('feather-icon', FeatherIcon)
Vue.component('el-table-draggable', ElTableDraggable)
Vue.component('filter-widget-v3', FilterWidgetV3)
Vue.component('override-input', OverrideInput)