<template>
	<el-collapse-item class="sidebar-menu-item" :name="sidebar.url"
		:class="{ 'no-child': !sidebar.children || !sidebar.children.length, 'search': search }"
		v-if="!sidebar.isHide">
		<div slot="title" class="d-flex flex-wrap align-items-center sidebar-item bg-none w-100"
			@click="showSidebarItem(sidebar)">
			<span v-if="sidebar.icon">
				<div class="icon" v-if="sidebar.icon && (sidebar.icon.includes('el-icon') || sidebar.icon.includes('fa fa-'))">
					<i :class="sidebar.icon"></i>
				</div>
				<feather-icon v-else :icon="sidebar.icon" />
			</span>
			<div class="text pl-1">{{ sidebar.name }}</div>
		</div>
		<template v-if="sidebar.children && sidebar.children.length">
			<template v-for="(children, idx) in sidebar.children">
				<div :class="{ 'active': getRouteActive(sidebar, children) }" v-if="!children.isHide && !isSettingMenuDisabled(childUrlToCheck(children.url)) && featureToggleShow(children)"
					:key="`${sidebar.name}-${children.name}-${idx}`" :name="`${sidebar.name}-${children.name}-${idx}`"
					@click="showSidebarItem(sidebar, children)">
					{{ children.name }} {{ featuresConfig[children.toggleKey] === 'all' && children.featureToggle === 'new' ? '*' : '' }}
				</div>
			</template>
		</template>
	</el-collapse-item>
</template>
<script>
import FeatureToggleMixin from '@/mixins/feature-toggle'
export default {
  mixins: [FeatureToggleMixin],
	props: {
		sidebar: {
			type: Object
		},
		index: {
			type: Number
		},
		search: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			currentActive: null
		}
	},
	methods: {
		showSidebarItem(sidebar, children = null) {
			if (!this.hasChildren) this.sidebarLink(sidebar)
			else if (children) {
				this.$store.dispatch('changeCurrentSidebar', sidebar.url)
				const path = children.url.startsWith('/') ? children.url : `/${sidebar.url}/${children.url}`
				return this.$router.push({ path }).catch(() => { })
			}
		},
		sidebarLink(sidebar) {
			this.$store.dispatch('changeCurrentSidebar', sidebar.url)
			if (sidebar.url === 'dashboard') {
				return this.$router.push({ path: '/' }).catch(() => { })
			}
			return this.$router.push({ path: `/${sidebar.url}` }).catch(() => { })
		},
		clickOutSide() {
			if (this.getCurrentSidebar === this.sidebar.url) {
				this.$store.dispatch('changeCurrentSidebar', null)
			}
		},
		hasACLChildren(items, url) {
			let result = false
			if (Array.isArray(items)) {
				result = items.some(item => this.acl(url + '/' + item.url))
			}
			return result
		},
		getRouteActive(sidebar, children) {
			const path = children.url.startsWith('/') ? children.url : `/${sidebar.url}/${children.url}`
			children.active = path === this.$route.fullPath
			return children.active
		},
    featureToggleShow(item) {
      if (item.toggleKey !== 'undefined') {
        return item.featureToggle === this.featuresConfig[item.toggleKey] || this.featuresConfig[item.toggleKey] === 'all'
      }
      return true
    },
    childUrlToCheck(url) {
      return url === 'etsy' ? 'iomnic' : url
    }
	},
	computed: {
		getCurrentSidebar() {
			return this.$store.getters.currentSidebar
		},
		hasChildren() {
			return this.sidebar.children && this.sidebar.children.length >= 1
		},
		getSidebarActive() {
			return this.getCurrentSidebar === this.sidebar.url
		}
	}
}
</script>
<style lang="scss" scoped>
.search {
  :deep().el-collapse-item__arrow {
    transform: rotate(90deg);
    &.is-active {
      transform: rotate(270deg);
    }
  }
}
</style>
