export default () => {
    // Polyfill - check to see if need to override Number.prototype.toFixed method
  const checkToFixed = (1.005).toFixed(2) === '1.01'
  if (!checkToFixed) {
    const toFixed = Number.prototype.toFixed
    Number.prototype.toFixed = function (fractionDigits = 0, asNumber = false) {
      if (typeof fractionDigits !== 'number' || fractionDigits < 0 || fractionDigits > 100) {
        throw RangeError('toFixed() digits argument must be number between 0 and 100')
      }
      let num = toFixed.call(this, fractionDigits + 1)
      num = Number(Math.round(num + `e${fractionDigits}`) + `e-${fractionDigits}`)
      if (!asNumber) {
        num = toFixed.call(num, fractionDigits)
      }
      return num
    }
  }
}
