import request from '@/utils/request'



export function disableShippingOption () {
  return request({
    url: `/modules/shipping/shipping-options/disable`,
    method: 'get'
  })
}

export function enableShippingOption () {
  return request({
    url: `/modules/shipping/shipping-options/enable`,
    method: 'get'
  })
}
export function getShippingOptionsCollection(params) {
  return request({
    url: `/modules/shipping/shipping-options/collection`,
    method: 'post',
    data: params
  })
}

export function deleteShippingOption (id) {
  return request({
    url: `/modules/shipping/shipping-options/${id}`,
    method: 'delete'
  })
}

export function getShippingOption (id) {
  return request({
    url: `/modules/shipping/shipping-options/${id}`,
    method: 'get'
  })
}

export function checkPerProductShippingOption() {
  return request({
    url: `/modules/shipping/shipping-options/is-per-product`,
    method: 'get',
    timeout: 0
  })
}

export function createShippingOption (params) {
  return request({
    url: `/modules/shipping/shipping-options`,
    method: 'post',
    data: params
  })
}

export function updateShippingOption (params, id) {
  return request({
    url: `/modules/shipping/shipping-options/${id}`,
    method: 'put',
    data: params
  })
}
export function importShippingOptions (params) {
  return request({
    url: `/modules/shipping/shipping-options/import`,
    method: 'post',
    data: params,
    timeout: 0
  })
}

export function exportShippingOptions () {
  return request({
    url: `/modules/shipping/shipping-options/export`,
    method: 'post',
    timeout: 0
  })
}

export function getShippingFeesCollection(id, params = {}) {
  return request({
    url: `/modules/shipping/shipping-options/${id}/shipping-fees/collection`,
    method: 'post',
    data: params,
    timeout: 0
  })
}

export function saveShippingFee (params, id) {
  return request({
    url: `/modules/shipping/shipping-options/${id}/shipping-fees`,
    method: 'post',
    data: params
  })
}


export function deleteShippingFee (id) {
  return request({
    url: `/modules/shipping/shipping-options/shipping-fees/${id}`,
    method: 'delete'
  })
}

export function importShippingFees (params, id) {
  return request({
    url: `/modules/shipping/shipping-options/shipping-fees/import/${id}`,
    method: 'post',
    data: params,
    timeout: 0
  })
}

export function exportShippingFees (id) {
  return request({
    url: `/modules/shipping/shipping-options/shipping-fees/export/${id}`,
    method: 'post',
    timeout: 0
  })
}

export function getCollection(params) {
  return request({
    url: `/shipping/collection`,
    method: 'post',
    data: params
  })
}

export function getShippingRates (id) {
  return request({
    url: `/shipping/${id}`,
    method: 'get'
  })
}

export function deleteShippingRates (id) {
  return request({
    url: '/shipping/' + id,
    method: 'delete',
  })
}

export function saveShippingRates (params) {
  return request({
    url: `/shipping`,
    method: 'post',
    data: params
  })
}

export function importShippingRates(body = {}) {
  return request({
    url: '/shipping/matrix_rates/import',
    method: 'post',
    data: body,
    timeout: 0
  })
}

export function exportShippingRates() {
  return request({
    url: '/shipping/matrix_rates/export',
    method: 'post',
    timeout: 0
  })
}

export function getShippingOptions () {
  return request({
    url: `/shipping/options`,
    method: 'get'
  })
}

export function getDeliveryEstimates(params) {
  return request({
    url: `/modules/shipping/delivery-estimate/collection`,
    method: 'post',
    data: params
  })
}

export function deleteDeliveryEstimate (id) {
  return request({
    url: `/modules/shipping/delivery-estimate/${id}`,
    method: 'delete'
  })
}

export function getDeliveryEstimate (id) {
  return request({
    url: `/modules/shipping/delivery-estimate/${id}`,
    method: 'get'
  })
}

export function createDeliveryEstimate (params) {
  return request({
    url: `/modules/shipping/delivery-estimate`,
    method: 'post',
    data: params
  })
}

export function getEstimateTime (params) {
  return request({
    url: `/modules/shipping/delivery-estimate/getEstimateTime`,
    method: 'post',
    data: params
  })
}

export function updateDeliveryEstimate (id, params) {
  return request({
    url: `/modules/shipping/delivery-estimate/${id}`,
    method: 'put',
    data: params
  })
}

export function getWarehouses(params) {
  return request({
    url: `/modules/shipping/warehouses/collection`,
    method: 'post',
    data: params
  })
}

export function getWarehouse(id) {
  return request({
    url: `/modules/shipping/warehouses/${id}`,
    method: 'get'
  })
}

export function saveWarehouse(params) {
  return request({
    url: `/modules/shipping/warehouses/`,
    method: 'post',
    data: params
  })
}

export function deleteWarehouse (id) {
  return request({
    url: `/modules/shipping/warehouses/${id}`,
    method: 'delete',
  })
}

export function getRategroupCollection(params = {}) {
  return request({
    url: `/modules/shipping/rategroup/collection`,
    method: 'post',
    data: params
  })
}

export function saveRateGroup(params) {
  return request({
    url: `/modules/shipping/rategroup/`,
    method: 'post',
    data: params
  })
}

export function deleteRateGroup (id) {
  return request({
    url: `/modules/shipping/rategroup/${id}`,
    method: 'delete',
  })
}

export function getCarriers(params = {}) {
  return request({
    url: `/modules/shipping/carriers`,
    method: 'post',
    data: params,
  })
}

export function getPackageTypes(params) {
  return request({
    url: `/modules/shipping/package-types/collection`,
    method: 'post',
    data: params
  })
}

export function updatePackageTypesPriorities(data) {
  return request({
    url: '/modules/shipping/package-types/priorities',
    method: 'put',
    data
  })
}

export function getShippingProfiles(data = {}) {
  return request({
    url: `/modules/shipping/shipping-profiles/collection`,
    method: 'get',
  })
}

export function saveShippingProfile(data = {}) {
  return request({
    url: `/modules/shipping/shipping-profiles`,
    method: 'post',
    data
  })
}
