import axios from 'axios'
import route from './../routes'
import store from './../store'
import { getToken, setToken } from '@/utils/auth'
import Vue from 'vue'
let vue = new Vue()
const service = axios.create({
  timeout: 15000, // 15s
  headers: {
    accept: 'application/json'
  }
})
service.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = 'Bearer ' + getToken()
    config.headers['hm-context'] = 'admin'
    config.headers['website-id'] = store.getters['websiteId']
    config.headers['client-id'] = store.getters['client'] || window.HM_ENV.CLIENT_ID
    config.headers['client-domain'] = window.HM_ENV.SITE_URL

    if (config.report_api) {
      config.baseURL = window.HM_ENV.API_REPORT
      /* another custom for reporting here */
    } else if (config.sso_auth) {
      config.baseURL = window.HM_ENV.API_AUTH
      /* another custom for SSO Auth here */
    } else if (config.central_service) {
      config.baseURL = window.HM_ENV.API_CENTRAL
    } else {
      config.baseURL = window.HM_ENV.API_ADMIN
    }

    // Check if a timeout property is provided in the request config
    if (config.timeout) {
      // Set the timeout value in the axios instance
      service.defaults.timeout = config.timeout;
      // Remove the timeout property from the request config to prevent it from being sent
      delete config.timeout;
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

const beforeResponse = (response) => {
  if ('data' in response) {
    if (response.data.messageType === 'modal') {
      vue.$wrConfirmModal(response.data)
    } else if ('status' in response.data && 'message' in response.data && !('cat' in response.data)) {
      vue.$notify({
        type: response.data.status,
        message: response.data.message,
        dangerouslyUseHTMLString: response.data.dangerouslyUseHTMLString,
        showClose: true,
        duration: 7000,
        customClass: 'jovy',
      })
    } else if ('status' in response.data && 'messages' in response.data) {
      response.data.messages.forEach(message => {
        setTimeout(() => {
          vue.$notify({
            type: response.data.status,
            message: message,
            dangerouslyUseHTMLString: response.data.dangerouslyUseHTMLString,
            showClose: true,
            duration: 7000,
            customClass: 'jovy',
          })
        }, 500) 
      })
    }
    if ('wr_renew_token' in response.data) {
      setToken(response.data.wr_renew_token)
    }
    if (response.data['is_locked']) {
      store.commit('LOCK_SESSION')
    }
  }
}

service.interceptors.response.use(
  (response) => {
    const contentType = response.headers['content-type'] || response.headers['Content-Type']
    if (contentType.startsWith('application/json')) {
      beforeResponse(response)
    }
    return response
  },
  (err) => {
    store.dispatch('toggleLoading', 'reset')
    if (err.response) {
      beforeResponse(err.response)
      // for not permission access
      if (err.response.status === 403) {
        route.push({ path: '/' })
      }
      // if 401 || 400 status code then redirect to login route
      if (err.response.status === 401 || err.response.status === 400) {
        store.dispatch('tokenNotValid').then(() => {
          route.push({ path: '/login' }).catch(() => {}) //added catch by tang
        })
        return Promise.reject(err.response)
      }
    } else {
      console.log('Unknown error while connecting to server:', err)
      let message = "Can't connect to server. Please try again later."
      if (/timeout of \d+ms exceeded/.test(err.message)) {
        message = 'Connection timeout. Please try reloading page again.'
      }
      vue.$notify({ type: 'error', message, showClose: true, duration: 7000, customClass: 'jovy' })
    }
    return Promise.reject(err.response)
  }
)

export default service
