import { updateSetting, getConfigurationValues } from '@/api/setting'
import ToggleSidebarButton from '@/components/Views/Settings/General/ToggleSidebarButton'

export default {
  components: { ToggleSidebarButton },
  data () {
    return {
      isDirty: false,
      toolbarButtons: [],
      counter: 1,
      formData: {},
      labelWidth: '270px',
      originFormData: {},
    }
  },
  async created() {
    /* chance for some components that want to prepare setting keys */
    if (typeof this.onBeforeCreated === 'function') {
      await this.onBeforeCreated()
    }
    if (this.settingKeys && this.settingKeys.length) {
      await this.getFormData()
    }
    // new hook for components want to run some data init that depends on the formData
    if (typeof this.onCreated === 'function') {
      await this.onCreated()
    }
  },
  computed: {
    validateKeysMap() { // turn 'general.app.name' into 'general_app_name' to use in el-form-item prop
      const keys = {}
      this.settingKeys.forEach(key => {
        keys[key] = key.replaceAll('.', '_')
      })
      return keys
    },
    validateModel() {
      const validateModel = {}
      this.settingKeys.forEach(key => {
        validateModel[this.validateKeysMap[key]] = this.formData[key]
      })
      return validateModel
    },
  },
  methods: {
    //NEW SETTING METHODS
    async getFormData() {
      try {
        const { data: { data, success } } = await getConfigurationValues(this.settingKeys)
        if (success && Array.isArray(data)) {
          for (const model of data) {
            if (model.client_guid || this.$checkClientType(['full', 'marketplace'])) {
              /* Client specific setting */
              this.$set(this.formData, model.key, model.value)
              this.$set(this.originFormData, model.key, model.value)
            } else {
              /* Essential global setting */
              if (this.globalFormData) {
                this.$set(this.globalFormData, model.key, model.value)
              }
            }
          }
        }
        // chance for components to prepare its formData (without causing dirty flag)
        if (typeof this.onFormData === 'function') {
          await this.onFormData()
        }
        this.initWatcher()
      } catch (err) {
        console.log('Error while geting Form Data', err)
        return { success: false }
      }
    },
    async initWatcher() {
      const keys = Object.keys(this.formData)
      for (const key of keys) {
        this.$watch(
          () => this.formData[key],
          () => this.handleIsDirty(),
          { deep: true }
        )
      }
    },
    handleIsDirty() {
      this.isDirty = true
      if (this.noDirty) return
      this.toolbarButtons = [
        { label: 'Revert', callback: this.handleReset },
        { label: 'Save', type: 'primary', callback: this.handleSave }
      ]
    },
    async handleReset() {
      await this.getFormData(this.settingKeys)
      this.counter++
      this.toolbarButtons = []
    },
    async handleSave(saveData, options = {}) {
      if (!this.validate()) {
        return false
      }
      const { data: { success } } = await updateSetting(saveData || this.formData).catch(() => ({ success: false }))
      if (success) {
        if (typeof this.afterSave === 'function') {
          await this.afterSave()
        }
        this.toolbarButtons = []
        this.isDirty = false
        const { reRender = true } = options
        if (reRender) {
          this.$store.dispatch('reRender')
        }

        try {
          if (Object.keys(this.formData).includes('client_name')) {
            const currentClient = this.$store.getters.clients.find(client => client.code === this.$store.getters.client)
            currentClient.name = this.formData['client_name']
          }
        } catch (error) {
          console.log(error);
        }
      }
      return success
    },
    validate() {
      if (!this.$refs['setting-form']) {
        return true
      }
      let validateResult = false
      this.$refs['setting-form'].validate((valid) => {
        if (!valid) {
          this.$notify({
            message: 'There is some invalid setting value.',
            type: 'error'
          })
        }
        validateResult = valid
      })
      return validateResult
    },
    validateUrl(rule, value, callback) {
      if (rule.required && value === '') {
        return callback(new Error('An URL is required'))
      }
      if (value && !isValidUrl(value)) {
        return callback(new Error('Invalid URL'))
      }
      callback()
    },
  },
  watch: {
    counter() {
      this.isDirty = false
    }
  },
}

function isValidUrl (urlString) {
  // eslint-disable-next-line
  const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
  return regex.test(urlString)
}