export default [
  {
    path: 'orders-management',
    component: () => import('@/components/Views/Sales/Orders/Management/Index'),
    name: 'sales_orders_management',
    meta: {
      title: 'Orders',
    },
  },
  {
    path: 'orders/detail/:id/:section?/:sectionId?',
    props: true,
    name: 'sales_orders_detail',
    meta: { title: 'Order Detail' },
    component: () => import('@/components/Views/Sales/Orders/Detail/Index'),
  },
  {
    path: 'orders-old/new',
    component: () => import('@/components/Views/Sales/Orders/New/Form'),
    name: 'sales_orders_new_old_ui',
    meta: {
      title: 'New Order',
    },
  },
  {
    path: 'orders-old/duplicate',
    component: () => import('@/components/Views/Sales/Orders/New/Form'),
    name: 'sales_orders_duplicate_old_ui',
    meta: {
      title: 'New Order',
      copying: true,
    },
  },
  {
    path: 'orders/new',
    component: () => import('@/components/Views/Sales/Orders/Management/Form'),
    name: 'sales_orders_new',
    meta: {
      title: 'New Order',
      order_source: 'Admin',
    },
  },
  {
    path: 'orders/new-pos-order',
    component: () => import('@/components/Views/Sales/Orders/Management/Form'),
    name: 'sales_orders_new_pos',
    meta: {
      title: 'New POS Order',
      order_source: 'POS',
    },
  },
  {
    path: 'orders/edit/:order_id',
    component: () => import('@/components/Views/Sales/Orders/Management/Form'),
    name: 'sales_orders_quote_order',
    meta: {
      title: 'Edit Quote Order',
    },
  },
  {
    path: 'orders/duplicate',
    component: () => import('@/components/Views/Sales/Orders/Management/Form'),
    name: 'sales_orders_duplicate',
    meta: {
      title: 'New Order',
      copying: true,
    },
  },
  {
    path: 'orders/edit/:order_id',
    component: () => import('@/components/Views/Sales/Orders/New/Form'),
    name: 'sales_orders_edit_quote_order',
    meta: {
      title: 'Edit Quote Order',
    },
  },
  {
    path: 'claims',
    component: () => import('@/components/Views/Sales/Claim/Main'),
    name: 'sales_claim',
    meta: {
      title: 'Claims',
    },
  },
  {
    path: 'cart-price-rules',
    component: () => import('@/components/Views/Sales/Promotions/CartPriceRule/Main'),
    name: 'sales_cart_price_rules_index',
    meta: {
      title: 'Cart Price Rules',
    },
  },
  {
    path: 'cart-price-rules/new',
    component: () => import('@/components/Views/Sales/Promotions/CartPriceRule/Form'),
    name: 'sales_cart_price_rules_new',
    meta: {
      title: 'Create New Rule',
      type: 'new',
    },
  },
  {
    path: 'cart-price-rules/:id',
    component: () => import('@/components/Views/Sales/Promotions/CartPriceRule/Form'),
    name: 'sales_cart_price_rules_edit',
    meta: {
      title: 'Edit Cart Price Rule',
      type: 'edit',
    },
  },
  {
    path: 'subscriptions',
    component: () => import('@/components/Views/Sales/Subscription/Main'),
    name: 'sales_subscriptions_main',
    meta: {
      title: 'Subscriptions',
    },
  },
  {
    path: 'subscriptions/:id',
    component: () => import('@/components/Views/Sales/Subscription/Form'),
    name: 'sales_subscriptions_edit',
    meta: {
      title: 'Edit Subscription',
      type: 'edit',
    },
  },
  {
    path: 'gift-certificate',
    component: () => import('@/components/Views/Sales/GiftCertificate/Main'),
    name: 'sales_gift_certificate_main',
    meta: {
      title: 'Gift Certificate Manager',
    },
  },
  {
    path: 'gift-certificate/new',
    component: () => import('@/components/Views/Sales/GiftCertificate/Form'),
    name: 'sales_gift_certificate_new',
    meta: {
      title: 'New Gift Certificate',
    },
  },
  {
    path: 'gift-certificate/:id',
    component: () => import('@/components/Views/Sales/GiftCertificate/Detail'),
    name: 'sales_gift_certificate_edit',
    meta: {
      title: 'Gift Certificate Details',
    },
  },
  {
    path: 'fraud-rules',
    component: () => import('@/components/Views/Sales/FraudRule/Main'),
    name: 'sales_fraud_rules',
    meta: {
      title: 'Fraud Rules',
    },
  },
  {
    path: 'fraud-rule-new',
    component: () => import('@/components/Views/Sales/FraudRule/Form'),
    name: 'sales_fraud_rule_new',
    meta: {
      title: 'Add Fraud Rule',
    },
  },
  {
    path: 'fraud-rule-edit/:id',
    component: () => import('@/components/Views/Sales/FraudRule/Form'),
    name: 'sales_fraud_rule_edit',
    meta: {
      title: 'Edit Fraud Rule',
    },
  },
  {
    path: 'order-rules',
    component: () => import('@/components/Views/Sales/OrderRule/Main'),
    name: 'sales_order_rules',
    meta: {
      title: 'Order Rules',
    },
  },
  {
    path: 'order-rule-new',
    component: () => import('@/components/Views/Sales/OrderRule/Form'),
    name: 'sales_order_rule_new',
    meta: {
      title: 'Add Order Rule',
    },
  },
  {
    path: 'order-rule-edit/:id',
    component: () => import('@/components/Views/Sales/OrderRule/Form'),
    name: 'sales_order_rule_edit',
    meta: {
      title: 'Edit Order Rule',
    },
  },
  {
    path: 'order-status-manager',
    component: () => import('@/components/Views/Sales/OrderStatus/Main'),
    name: 'sales_order_status_manager',
    meta: {
      title: 'Order Status Manager',
    },
  },
  {
    path: 'order-status-new',
    component: () => import('@/components/Views/Sales/OrderStatus/Form'),
    name: 'sales_order_status_new',
    meta: {
      title: 'Create Order Status',
    },
  },
  {
    path: 'order-status-edit/:id',
    component: () => import('@/components/Views/Sales/OrderStatus/Form'),
    name: 'sales_order_status_edit',
    meta: {
      title: 'Edit Order Status',
    },
  },

  /* TAX >>> */
  {
    path: 'tax/category',
    component: () => import('@/components/Views/Sales/Tax/Category'),
    name: 'sales_tax_category',
    meta: {
      title: 'Tax Category Manager',
    },
  },
  {
    path: 'tax/rate',
    component: () => import('@/components/Views/Sales/Tax/Rate'),
    name: 'sales_tax_rate',
    meta: {
      title: 'Tax Rate Manager',
    },
  },
  {
    path: 'tax/rate/new',
    component: () => import('@/components/Views/Sales/Tax/Rate/Form'),
    name: 'sales_tax_rate_new',
    meta: {
      title: 'Add New Tax Rate',
      type: 'new',
    },
  },
  {
    path: 'tax/rate/edit/:id',
    component: () => import('@/components/Views/Sales/Tax/Rate/Form'),
    name: 'sales_tax_rate_edit',
    meta: {
      title: 'Edit Tax Rate',
      type: 'edit',
    },
  },
  {
    path: 'tax/rule',
    component: () => import('@/components/Views/Sales/Tax/Rule'),
    name: 'sales_tax_rule',
    meta: {
      title: 'Tax Rule Manager',
    },
  },
  {
    path: 'tax/rule/new',
    component: () => import('@/components/Views/Sales/Tax/Rule/Form'),
    name: 'sales_tax_rule_new',
    meta: {
      title: 'Add New Tax Rule',
      type: 'new',
    },
  },
  {
    path: 'tax/rule/edit/:id',
    component: () => import('@/components/Views/Sales/Tax/Rule/Form'),
    name: 'sales_tax_rule_edit',
    meta: {
      title: 'Edit Tax Rule',
      type: 'edit',
    },
  },
  /* <<< TAX */
  /* SHIPPING METHOD >>> */
  {
    path: 'shipping/matrix_rate',
    component: () => import('@/components/Views/Sales/ShippingMethod/Main'),
    name: 'sales_shipping_matrix_rate_main',
    meta: {
      title: 'Matrix Shipping Rates',
    },
  },
  {
    path: 'shipping/matrix_rate/new',
    component: () => import('@/components/Views/Sales/ShippingMethod/Form'),
    name: 'sales_shipping_matrix_rate_new',
    meta: {
      title: 'Add New Shipping Rate',
      type: 'new',
    },
  },
  {
    path: 'shipping/matrix_rate/edit/:id',
    component: () => import('@/components/Views/Sales/ShippingMethod/Form'),
    name: 'sales_shipping_matrix_rate_edit',
    meta: {
      title: 'Edit Shipping Rate',
      type: 'edit',
    },
  },
  /* <<< SHIPPING METHOD */
  //reason
  {
    path: 'reasons',
    component: () => import('@/components/Views/Sales/Reason/Main'),
    name: 'sales_reason_main',
    meta: {
      title: 'Reasons',
    },
  },
  {
    path: 'reason/new',
    component: () => import('@/components/Views/Sales/Reason/Form'),
    name: 'sales_reason_new',
    meta: {
      title: 'Add New Reason',
      type: 'new',
    },
  },
  {
    path: 'reason/edit/:id',
    component: () => import('@/components/Views/Sales/Reason/Form'),
    name: 'sales_reason_edit',
    meta: {
      title: 'Edit reason',
      type: 'edit',
    },
  },
  /* <<< reason */
  //replacement
  {
    path: 'replacements',
    component: () => import('@/components/Views/Sales/Replacement/Main'),
    name: 'sales_replacement_main',
    meta: {
      title: 'Replacements',
    },
  },
  {
    path: 'replacement/new',
    component: () => import('@/components/Views/Sales/Replacement/Form'),
    name: 'sales_replacement_new',
    meta: {
      title: 'Add New Replacement',
      type: 'new',
    },
  },
  {
    path: 'replacement/edit/:id',
    component: () => import('@/components/Views/Sales/Replacement/Form'),
    name: 'sales_replacement_edit',
    meta: {
      title: 'Edit replacement',
      type: 'edit',
    },
  },
  //Digital Purchase Management
  {
    path: 'digital-purchases',
    component: () => import('@/components/Views/Sales/DigitalPurchases/Main'),
    name: 'sales_digital_purchases',
    meta: {
      title: 'Digital Purchase Management',
    },
  },
  {
    path: 'digital-purchase/:id',
    component: () => import('@/components/Views/Sales/DigitalPurchases/Detail'),
    name: 'sales_digital_purchase',
    meta: {
      title: 'Digital Purchase',
    },
  },
  {
    path: 'payment-log',
    component: () => import('@/components/Views/Sales/PaymentLog/Management/Index'),
    name: 'payment_log_management',
    meta: {
      title: 'Payment Log',
    },
  },
]
