<!--
This is component to extends el-table to custom css on table

-->
<template>
  <el-table
    :data="tableData"
    class="el-table__jovy w-100"
    style="width: 100%">
    <slot v-if="$slots.prefix" name="prefix" />
    <slot />
    <template v-if="Array.isArray(columns) && columns.length">
      <el-table-column
        :prop="column.key"
        :label="column.label"
        :align="column.align"
        :width="column.width" v-for="column in columns" :key="column.key">
        <template slot-scope="{ row }">
          <div v-html="getValueColumn(column, row)"></div>
        </template>
      </el-table-column>
    </template>    
    <slot v-if="$slots.suffix" name="suffix" />
  </el-table>
</template>
<script>
export default {
  name: 'jv-table',
  props: {
    tableData: {
      type: Array,
      require: true,
    },
    /**
     * Columns property can empty to display slot from parent component
     * object column
     *  {
          key: String, // key is property of row to view data
          label: String,
          width: String | Number,
          align: String, 'left | right | center'
          formatter: String, 'date | price | ucfirst'
          isEmpty: Boolean, // to empty column if value is 0
          useHTML: Function, // function to return html data
        }
     */
    columns: {
      type: Array,
      default: () => {
        return []
      },
    }
  },
  methods: {
    getValueColumn(column, row) {
      let value = row[column.key]
      if (column.isEmpty) {
        if (row[column.key]) {
          value = row[column.key]
        } else {
          value = null
        }
      }
      if (value !== null) {
        switch(column.formatter) {
          case 'date':
            value = this.formatDate(value)
            break
          case 'price':
            value = this.formatPrice(value)
            break
          case 'ucfirst':
            value = this.ucfirst(value)
            break
        }
        if (column.useHTML) {
          value = column.useHTML(row)
        }
      }
      return value
    },
    formatDate(value) {
      return new Date(value).toLocaleString().replace(',', "<br/>")
    },
  }
}
</script>
<style lang="scss" scoped>
.el-table {
  &.el-table__jovy {
    .el-table__header-wrapper {
      .el-table__cell {
        border-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: var(--jv-text);
        padding: 0 8px;
        .el-checkbox__inner {
          width: 16px;
          height: 16px;
        }
        &.text-nowrap {
          .cell {
            white-space: nowrap;
            text-overflow: clip;
          }
        }
        .cell {
          padding-right: 0;
        }
        &.el-table__expand-column {
          .cell {
            text-align: right;
            .el-button__jovy {
              width: 32px;
              height: 32px;
              padding: 0;
              text-align: center;
            }
          }
        }
        &.ascending {
          .sort-caret {
            &.ascending {
              display: none;
            }
            &.descending {
              display: block;
            }
          }
        }
        &.descending {
          .sort-caret {
            &.ascending {
              display: block;
            }
            &.descending {
              display: none;
            }
          }
        }
        .sort-caret {
          width: auto;
          height: auto;
          border: 0;
          font-size: 16px;
          font-family: bootstrap-icons !important;
          font-style: normal;
          font-weight: normal !important;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          vertical-align: -0.125em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          &.ascending, &.descending {
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
          }
          &.ascending {
            display: none;
            &:before {
              content: "\f575";
            }
          }
          &.descending {
            display: none;
            &:before {
              content: "\f57a";
            }
          }
        }
      }
    }
    .el-table__body-wrapper {
      .el-table__body {
        border-spacing: 0 8px;
        tr {
          td {
            &.hidden-cell {
              .cell {
                visibility: hidden;
              }
            }
          }
          &.is-new {
            >td {
              &.el-table__cell {
                background-color: var(--jv-green-100);
                border-top: 1px solid var(--jv-green-300);
                border-bottom: 1px solid var(--jv-green-300);
                &:first-child {
                  &::before {
                    content: 'New';
                    background-color: var(--jv-green-300);
                    padding: 0px 4px 0px 4px;
                    border-radius: 4px 0px;
                    color: white;
                    font-weight: 700;
                    font-size: 10px;
                    position: absolute;
                    width: 30px;
                    height: 15px;
                    left: 0px;
                    top: 0px;
                  }
                  border-left: 1px solid var(--jv-green-300);
                }
                &:last-child {
                  border-right: 1px solid var(--jv-green-300);
                }
              }
            }
          }
          &.is-updated {
            >td {
              &.el-table__cell {
                background-color: var(--jv-yellow-100);
                border-top: 1px solid var(--jv-yellow-300);
                border-bottom: 1px solid var(--jv-yellow-300);
                &:first-child {
                  &::before {
                    content: 'Updated';
                    background-color: var(--jv-yellow-300);
                    padding: 0px 4px 0px 4px;
                    border-radius: 4px 0px;
                    color: white;
                    font-weight: 700;
                    font-size: 10px;
                    position: absolute;
                    width: 51px;
                    height: 15px;
                    left: 0px;
                    top: 0px;
                  }
                  border-left: 1px solid var(--jv-yellow-300);
                }
                &:last-child {
                  border-right: 1px solid var(--jv-yellow-300);
                }
              }
            }
          }
          &.is-disabled {
            cursor: not-allowed;
            >td {
              &.el-table__cell {
                background-color: var(--jv-gray-100);
                .cell {
                  opacity: 0.5;
                }
              }
              &:last-child {
                .cell {
                  opacity: 1;
                }
              }
            }
          }
          &.not-available, &.gray {
            >td {
              &.el-table__cell {
                background-color: var(--jv-gray-100);
                border-top: 1px solid var(--jv-gray-300);
                border-bottom: 1px solid var(--jv-gray-300);
                &:first-child {
                  border-left: 1px solid var(--jv-gray-300);
                }
                &:last-child {
                  border-right: 1px solid var(--jv-gray-300);
                }
              }
            }
          }
          &.current-row, &:hover {
            >td {
              &.el-table__cell {
                background-color: var(--jv-brand-100);
                border-top: 1px solid var(--jv-brand-300);
                border-bottom: 1px solid var(--jv-brand-300);
                &:first-child {
                  border-left: 1px solid var(--jv-brand-300);
                }
                &:last-child {
                  border-right: 1px solid var(--jv-brand-300);
                }
              }
              &:has(+ tr:hover) {
                background-color: var(--jv-brand-100);
                border-color: var(--jv-brand-300);
              }
            }
          }
          &.is-selected {
            >td {
              &.el-table__cell {
                background-color: var(--jv-brand-100);
                border-color: var(--jv-brand-300);
              }
            }
            &:hover {
              >td {
                &.el-table__cell {
                  background-color: var(--jv-brand-100);
                  border-color: var(--jv-gray-300);
                }
              }
            }
          }
        }
      }
      .el-table__row {
        margin-bottom: 8px;
        .el-table__cell {
          border-top: 1px solid var(--jv-gray-300);
          border-bottom: 1px solid var(--jv-gray-300);
          padding: 12px 8px;
          transition: background-color 0.25s ease-in-out;
          .el-checkbox__inner {
            width: 16px;
            height: 16px;
          }
          &:first-child {
            border-left: 1px solid var(--jv-gray-300);
            padding-left: 12px;
            border-radius: 4px 0 0 4px;
          }
          &:last-child {
            border-right: 1px solid var(--jv-gray-300);
            padding-right: 12px;
            border-radius: 0 4px 4px 0;
          }
          .cell {
            padding-right: 0;
            &.el-tooltip {
              max-width: 100%;
            }
          }
          &.el-table__expand-column {
            .cell {
              overflow: visible;
              .el-table__expand-icon {
                background: var(--jv-gray-100);
                border: 1px solid var(--jv-gray-300);
                border-radius: 4px;
                width: 32px;
                height: 32px;
                margin-left: auto;
                transform: none;
                -webkit-transform: none;
                font-size: 16px;
                >.el-icon {
                  transform: translate(-50%, -50%);
                  margin-left: auto;
                  margin-top: auto;
                }
                i {
                  display: inline-block;
                  font-family: bootstrap-icons !important;
                  font-style: normal;
                  font-weight: normal !important;
                  font-variant: normal;
                  text-transform: none;
                  line-height: 1;
                  vertical-align: -0.125em;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  font-size: 16px;
                  line-height: 16px;
                  &::before {
                    content: "\F282";
                  }
                }
                &.el-table__expand-icon--expanded {
                  i {
                    &::before {
                      content: "\F286";
                    }
                  }
                }
              }
            }
          }
        }
        &.expanded {
          .el-table__cell {
            border-bottom: 0;
            &:first-child {
              border-left: 1px solid var(--jv-gray-300);
              border-radius: 4px 0 0 0;
            }
            &:last-child {
              border-right: 1px solid var(--jv-gray-300);
              border-radius: 0 4px 0 0;
            }
          }
          @media (min-width: $sm) {
            &:hover, &:has( + tr:hover) {
              .el-table__cell {
                background-color: var(--jv-brand-100);
                border-color: var(--jv-brand-300);
                border-bottom: 0;
              }
              + tr {
                .el-table__cell {
                  background-color: var(--jv-brand-100);
                  border-color: var(--jv-brand-300) !important;
                }
              }
            }
          }
          +tr {
            .el-table__cell {
              border-top: 0;
              top: -16px;
              border-bottom: 1px solid var(--jv-gray-300);
              padding-top: 0;
              &:first-child {
                border-left: 1px solid var(--jv-gray-300);
                border-right: 1px solid var(--jv-gray-300);
                padding-left: 12px;
                padding-right: 12px;
                border-radius: 0 0 4px 4px;
              }
              @media (min-width: $sm) {
                top: -8px;
              }
            }
            @media (min-width: $sm) {
              &:hover {
                .el-table__cell {
                  border-top: 0;
                  background-color: var(--jv-brand-100) !important;
                  border-color: var(--jv-brand-300);
                }
              }
            }
          }
        }
      }
    }
    .el-table__cell {
      .cell {
        .el-checkbox {
          .el-checkbox__input {
            .el-checkbox__inner {
              &:after {
                border: 0;
                transition: none;
                color: white;
                font-family: bootstrap-icons !important;
                font-weight: bolder;
                line-height: 16px;
                font-size: 16px;
                content: "";
                left: 0;
                top: 0;
                width: auto;
                height: auto;
                transform-origin: unset;
                transform: translate(-1px, -1px);
              }
            }
            &.is-checked, &.is-indeterminate {
              .el-checkbox__inner {
                background-color: var(--jv-green-400);
                border-color: var(--jv-green-400);
                &:after {
                  content: "\F26E";
                }
              }
            }
          }
        }
      }
    }
    &::before {
      height: 0;
    }
    &.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
      background-color: transparent;
    }
  }
}
</style>