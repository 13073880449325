export default [
  // {
  //   path: 'invoice-items',
  //   component: () => import('@/components/Views/Report/invoice'),
  //   name: 'invoice_items_report',
  //   meta: {
  //     title: 'Invoice Items'
  //   }
  // },
  // {
  //   path: 'order-items',
  //   component: () => import('@/components/Views/Report/orders'),
  //   name: 'order_items_report',
  //   meta: {
  //     title: 'Order Items'
  //   }
  // },
  // {
  //   path: 'order-summary',
  //   component: () => import('@/components/Views/Report/order-summary'),
  //   name: 'order-summary',
  //   meta: {
  //     title: 'Order Summary'
  //   }
  // },
  // {
  //   path: 'invoice-summary',
  //   component: () => import('@/components/Views/Report/invoice-summary'),
  //   name: 'invoice-summary',
  //   meta: {
  //     title: 'Invoice Summary'
  //   }
  // },
  {
    path: 'sales-by-date',
    component: () => import('@/components/Views/Report/sales-by-date'),
    name: 'sales-by-date',
    meta: {
      title: 'Sales by Date'
    }
  },
  {
    path: 'sales-by-state',
    component: () => import('@/components/Views/Report/sales-by-state'),
    name: 'sales-by-state',
    meta: {
      title: 'Sales Tax'
    }
  },
  {
    path: 'sales-by-customer',
    component: () => import('@/components/Views/Report/sales-by-customer'),
    name: 'sales-by-customer',
    meta: {
      title: 'Sales by Customer'
    }
  },
  {
    path: 'sales-by-rep',
    component: () => import('@/components/Views/Report/sales-by-rep'),
    name: 'sales-by-rep',
    meta: {
      title: 'Sales by Rep'
    }
  },
  {
    path: 'sales-by-product',
    component: () => import('@/components/Views/Report/sales-by-product'),
    name: 'sales-by-product',
    meta: {
      title: 'Sales by Product'
    }
  },
  {
    path: 'current-inventory-snapshot',
    component: () => import('@/components/Views/Report/inventory-snapshot'),
    name: 'current-inventory-snapshot',
    meta: {
      title: 'Inventory Snapshot'
    }
  },
  {
    path: 'net-on-shelf',
    component: () => import('@/components/Views/Report/net-on-shelf'),
    name: 'net-on-shelf',
    meta: {
      title: 'Full Stock'
    }
  },
  {
    path: 'current-backorder-snapshot',
    component: () => import('@/components/Views/Report/current-backorder-snapshot'),
    name: 'current-backorder-snapshot',
    meta: {
      title: 'Backorders'
    }
  },
  {
    path: 'kit',
    component: () => import('@/components/Views/Report/kit'),
    name: 'kit',
    meta: {
      title: 'Kit Report'
    }
  },
  {
    path: 'kits-by-component',
    component: () => import('@/components/Views/Report/kits-by-component'),
    name: 'kits-by-component',
    meta: {
      title: 'Kits by Component'
    }
  },
  {
    path: 'consumption',
    component: () => import('@/components/Views/Report/consumption'),
    name: 'consumption',
    meta: {
      title: 'Consumption'
    }
  },
    // {
    //   path: 'promotion-product-sales',
    //   component: () => import('@/components/Views/Report/productsalespromotion'),
    //   name: 'promotion-product-sales',
    //   meta: {
    //     title: 'Promotion Product Sales'
    //   }
    // },
    // {
    //   path: 'state-product-sales',
    //   component: () => import('@/components/Views/Report/stateproductsales'),
    //   name: 'state-product-sales',
    //   meta: {
    //     title: 'State Product Sales'
    //   }
    // },
    // {
    //   path: 'product-returns',
    //   component: () => import('@/components/Views/Report/productreturns'),
    //   name: 'product-returns',
    //   meta: {
    //     title: 'Product Returns'
    //   }
    // },
    // {
    //   path: 'product-tax',
    //   component: () => import('@/components/Views/Report/producttax'),
    //   name: 'product-tax',
    //   meta: {
    //     title: 'Product Tax'
    //   }
    // },
    // {
    //   path: 'product-handling',
    //   component: () => import('@/components/Views/Report/product-handling'),
    //   name: 'product-handling',
    //   meta: {
    //     title: 'Product Handling'
    //   }
    // },
    // {
    //   path: 'date-sales',
    //   component: () => import('@/components/Views/Report/date-sale'),
    //   name: 'date-sales',
    //   meta: {
    //     title: 'Date Sales'
    //   }
    // },
    // {
    //   path: 'sales-tax-date',
    //   component: () => import('@/components/Views/Report/date-tax'),
    //   name: 'sales-tax-date',
    //   meta: {
    //     title: 'Date Sales Tax'
    //   }
    // },
    // {
    //   path: 'customer-sales',
    //   component: () => import('@/components/Views/Report/customer-sales'),
    //   name: 'customer-sales',
    //   meta: {
    //     title: 'Customer Sales'
    //   }
    // },
    // {
    //   path: 'promotion-sales',
    //   component: () => import('@/components/Views/Report/promotion-sales'),
    //   name: 'promotion-sales',
    //   meta: {
    //     title: 'Promotion Sales'
    //   }
    // },
    // {
    //   path: 'advance-search',
    //   component: () => import('@/components/Views/Report/advance-search'),
    //   name: 'advance-search',
    //   meta: {
    //     title: 'Advance Search'
    //   }
    // }
  ]
