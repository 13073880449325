<template>
  <div>
    <el-table-draggable :animate="300" handle=".handle-draggable" @drop="handleDroped" v-if="!$isMobile">
      <el-table
        :header-cell-style="{ background: '#F5F7FA' }"
        small
        hover
        :height="tableHeight"
        style="width: 100%"
        :data="tableData"
        border
        v-loading="loading"
      >
        <template v-for="(column, index) in gridColumn">
          <el-table-column
            v-if="column.is_active"
            :key="index"
            :prop="column.field_name"
            :label="column.title"
            :min-width="column.minWidth ? column.minWidth : 150"
            :width="column.width ? column.width : ''"
            :align="column.align || 'left'"
            :sortable="column.hide_sort ? false : true"
            :show-overflow-tooltip="isShowTooltip(column)"
            :class-name="column.class_name || (column.draggable ? 'handle-draggable' : '')"
          >
            <template slot-scope="{row, $index}">
              <template v-if="!column.type || column.type === 'text'">
                {{ formatValue(row, column) }}
              </template>
              <component
                v-else
                :is="`type-${column.type}`"
                :row="row"
                :column="column"
                :buttons="column.buttons || actionsGrid"
                :className="column.className"
                :firstIndex="$index === 0"
                :lastIndex="($index+1) === tableData.length"
              />
            </template>
          </el-table-column>
        </template>
      </el-table>
    </el-table-draggable>

    <el-collapse v-if="$isMobile" accordion>
      <el-collapse-item v-for="(row, index) in tableData" :name="index" :key="index">
        <template slot="title">
          <div
            class="title-header"
            v-if="collapseTitle && collapseTitle.length"
            :class="{ 'hide-icon': hideIconCollapse }"
          >
            <template v-for="(column, idx) in collapseTitle">
              <div v-if="Array.isArray(column)" :key="'collapse-title-' + column.field_name + idx" class="col-item">
                <div
                  v-for="(col, idx1) in column"
                  :key="'collapse-title-' + column.field_name + idx + idx1"
                  :class="col.className"
                >
                  <div class="font-weight-normal pr-1" v-if="col.show_label_in_mobile">
                    {{ col.mobile_title || col.title }}:
                  </div>
                  <div :key="'collapse-title-' + col.field_name + idx + idx1" v-if="!col.type || col.type === 'text'">
                    {{ formatValue(row, col) }}
                  </div>
                  <component
                    :key="'collapse-title-' + col.field_name + idx + idx1"
                    v-else
                    :is="`type-${col.type}`"
                    :row="row"
                    :column="col"
                    :buttons="col.buttons || actionsGrid"
                  />
                </div>
              </div>
              <div
                v-else
                class="d-flex w-100"
                :class="idx == 0 && !column.type === 'actions' ? 'main-title' : 'text-light-dark'"
                :key="'collapse-title-' + column.field_name + idx"
              >
                <div class="font-weight-normal pr-1" v-if="column.show_label_in_mobile">
                  {{ column.mobile_title || column.title }}:
                </div>
                <div :key="'collapse-title-' + column.field_name + idx" v-if="!column.type || column.type === 'text'">
                  {{ formatValue(row, column) }}
                  <template v-if="column.child">
                    <type-adjustment-tag
                      v-if="column.child.type === 'adjustment-tag'"
                      :row="row"
                      :column="column.child"
                      :className="'bg-none border-0 p-0'"
                      :is-bracket="true"
                      :is-tag="false"
                    />
                  </template>
                </div>
                <component
                  :key="'collapse-title-' + column.field_name + idx"
                  v-else
                  :is="`type-${column.type}`"
                  :row="row"
                  :column="column"
                  :buttons="column.buttons || actionsGrid"
                />
              </div>
            </template>
          </div>
        </template>
        <template v-for="(column, idx) in gridColumn">
          <div
            v-if="column.is_active && !column.hide_in_expand"
            class="d-flex justify-content-between w-100 pt-1 pb-1"
            :key="column.field_name + idx"
          >
            <div class="font-weight-normal pr-3" v-if="column.type != 'actions'">
              {{ column.mobile_title || column.title }}
            </div>
            <div class="text-light-dark">
              <template>
                <template v-if="!column.type || column.type === 'text'">
                  {{ formatValue(row, column) }}
                </template>
                <type-adjustment-tag
                  v-else-if="column.type === 'adjustment-tag'"
                  :row="row"
                  :column="column"
                  :is-tag="false"
                  :className="column.className + ' border-0 p-0'"
                />
                <component
                  v-else
                  :is="`type-${column.type}`"
                  :row="row"
                  :column="column"
                  :buttons="column.buttons || actionsGrid"
                  :className="column.className"
                />
              </template>
            </div>
          </div>
        </template>
      </el-collapse-item>
    </el-collapse>
    <div v-if="$isMobile && elLoading" class="loading-scroll">
      <i v-if="isScrollLoading" class="fa fa-spinner fa-spin font-weight-bold"></i>
    </div>
    <div class="wr-pagination" v-if="!$isMobile && pagination">
      <el-pagination
        align="right"
        background
        layout="prev, pager, next, sizes, total"
        :page-sizes="[10, 25, 50, 100]"
        :pager-count="5"
        :page-size.sync="pagination.page_size"
        :total="pagination.count"
        :current-page.sync="pagination.current_page"
        @current-change="pageCurrentChange"
        @size-change="pageSizeChange"
      />
    </div>
  </div>
</template>
<script>
import {
  TypeNumber,
  TypeAdjustmentTag,
  TypePrice,
  TypeDecimal,
  TypeDate,
  TypeActions,
  TypeTag,
} from './HMColumns'
import TypeApiResponse from '@/components/Widgets/HMColumns/ApiResponse'
import * as _ from 'lodash/lodash.min'
export default {
  name: 'GridComponents',
  components: {
    TypeNumber,
    TypeAdjustmentTag,
    TypePrice,
    TypeDecimal,
    TypeDate,
    TypeActions,
    TypeTag,
    TypeApiResponse,
    TypeOrderData: () => import('@/components/Widgets/HMColumns/OrderData'),
  },
  props: {
    tableData: {
      type: Array,
      require: true,
    },
    gridColumn: {
      type: Array,
      require: true,
    },
    collapseTitle: {
      type: Array,
    },
    pagination: {
      type: Object,
    },
    actionsGrid: {
      type: Array,
    },
    isScrollLoading: {
      type: Boolean,
    },
    hideIconCollapse: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tableHeight: {
      type: String,
      default: '60vh',
    },
  },
  computed: {
    showColPosition1() {
      return this.gridColumn.some((s) => s.col_position && s.col_position === 1)
    },
    showColPosition2() {
      return this.gridColumn.some((s) => s.col_position && s.col_position === 2)
    },
  },
  data() {
    return {
      elLoading: true
    }
  },
  methods: {
    formatValue(row, column) {
      let value = row[column.field_name]
      if (column.relation && row[column.relation]) value = row[column.relation][column.field_name]
      if (column.options) {
        let option = column.options.find((v) => value === v._id)
        if (option) return (value = option.name)
      }
      return value || 'N/A'
    },
    pageCurrentChange(index) {
      this.$emit('pageCurrentChange', index)
    },
    pageSizeChange(page) {
      this.$emit('pageSizeChange', page)
    },
    handleScroll: _.debounce(async function () {
      if (this.pagination.current_page < this.pagination.last_page) {
        this.elLoading = true
        let scroll = window.scrollY
        if (scroll) {
          const bodyHeight = document.body.scrollHeight /* Get height of element body  */
          const clientHeight = window.innerHeight /* max height of display device's client  */
          const maxScroll = bodyHeight - clientHeight /* max Scroll this page  */
          if (maxScroll - 100 <= scroll) {
            this.pageCurrentChange(this.pagination.current_page + 1)
          }
        }
      } else {
        this.elLoading = false
      }
    }, 500),
    handleDroped(data) {
      this.$emit('handleDroped', data)
    },
    isShowTooltip(column) {
      if (typeof column.show_overflow_tooltip !== 'undefined') {
        return column.show_overflow_tooltip
      }
      return column.type != 'actions'
    }
  },
  mounted() {
    if (this.$isMobile) window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    if (this.$isMobile) window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="scss" scoped>
:deep().el-collapse {
  .el-collapse-item {
    box-shadow: 0 1px 0 1px #dcdfe6;
    .el-collapse-item__header {
      height: auto !important;
      line-height: 22px;
      padding: 0.75em 1em !important;
      font-weight: 400;
      font-size: 14px !important;
      background: #f5f7fa;
      .title-header {
        .main-title {
          color: var(--color);
          .el-tag {
            height: auto;
            line-height: inherit;
          }
        }
        &.hide-icon {
          width: 100%;
          + .el-collapse-item__arrow {
            display: none;
          }
        }
      }
      .is-active {
        color: var(--color) !important;
      }
    }
  }
}

:deep().el-table--border {
  border-radius: 4px 4px 0 0;
}

:deep().el-table {
  color: var(--color);
  .el-table__cell {
    > .cell {
      padding-left: 10px !important;
      &.el-tooltip {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  th.el-table__cell {
    border-color: var(--bg);
    padding: 14px 0;
    > .cell {
      color: var(--color);
      word-break: normal;
      font-weight: 500;
    }
  }
}
:deep().el-collapse-item__content,
:deep().el-tag {
  font-size: 14px;
}
:deep() .el-collapse-item__arrow {
  transform: rotate(90deg);
}
:deep() .el-collapse-item__arrow.is-active {
  transform: rotate(270deg);
}

:deep().e-tag-border {
  border-radius: 50em;
}
.loading-scroll {
  text-align: center;
  padding-top: 10px;
  margin-bottom: 35px;
  color: #04747c;
  font-size: 40px;
}
.text-light-dark {
  color: var(--color);
  opacity: 0.75;
}
.col-item {
  float: left;
  width: 25%;
  word-break: normal;
  text-overflow: ellipsis;
  &:nth-child(2) {
    width: 50%;
    padding: 0 0.5rem;
  }
  &:last-child {
    &::after {
      display: table;
      clear: both;
      content: '';
    }
  }
}
</style>
