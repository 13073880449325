const NAMESPACE = process.env.VUE_APP_NAMESPACE || 'hm'

const getName = function (name) {
  return NAMESPACE + '.' + name
}

const save = function (name, data) {
  localStorage.setItem(getName(name), JSON.stringify(data))
}

export function setOne (name, key, value) {
  const data = getAll(name) || {}
  data[key] = value
  save(name, data)
}

export function setMany (name, payload, options = {}) {
  const purge = options.purge === true
  if (purge) {
    return save(name, payload)
  }

  const data = getAll(name) || {}
  Object.assign(data, payload)
  save(name, data)
}

export function getOne (name, key) {
  let data = getAll(name)
  if (data) {
    return data[key]
  }
  return false
}

export function destroyOne (name, key) {
  let data = getAll(name)
  if (data) {
    delete data[key]
    save(name, data)
  }
}

export function destroyMany (name, keys = []) {
  let data = getAll(name)
  if (data) {
    keys.forEach(key => {
      delete data[key]
    })
    save(name, data)
  }
}

export function destroyAll (name) {
  localStorage.removeItem(getName(name))
}

export function getAll (name) {
  const q = localStorage.getItem(getName(name))
  if (q !== null) {
    return JSON.parse(q)
  }
  return false
}