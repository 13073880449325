import request from '@/utils/request'

export function createPage (form) {
  return request({
    url: '/content/page',
    method: 'post',
    data: form
  })
}

export function getPages (params = {}) {
  return request({
    url: '/content/page/collection',
    method: 'post',
    data: params
  })
}

export function getAllPages(params = {}) {
  return request({
    url: '/content/page/all',
    method: 'post',
    data: params
  })
}

export function getPage (id) {
  return request({
    url: '/content/page/' + id,
    method: 'get',
  })
}

export function updatePage (id, data) {
  return request({
    url: '/content/page/' + id,
    method: 'put',
    data: data
  })
}

export function deletePage (id) {
  return request({
    url: '/content/page/' + id,
    method: 'delete',
  })
}
// PageType
export function createPageType (form) {
  return request({
    url: '/content/pagetype',
    method: 'post',
    data: form
  })
}

export function getPageTypes (params = {}) {
  return request({
    url: '/content/pagetype/collection',
    method: 'post',
    data: params
  })
}

export function getPageType (id_data) {
  return request({
    url: '/content/pagetype/' + id_data.row_id + '/' + id_data.user_id,
    method: 'get',
  })
}

export function updatePageType (id_data, data) {
  return request({
    url: '/content/pagetype/' + id_data.row_id + '/' + id_data.user_id,
    method: 'put',
    data: data
  })
}

export function deletePageType (data) {
  return request({
    url: '/content/pagetype/' + data.row_id + '/' + data.user_id,
    method: 'delete',
  })
}
// BLOCK

export function createBlock (form) {
  return request({
    url: '/content/block',
    method: 'post',
    data: form
  })
}

export function getBlocks (type = null, body = {}) {
  let params = {}
  if (type) {
    params.type = type
  }
  return request({
    url: '/content/block/collection',
    method: 'post',
    params: params,
    data: body
  })
}

export function getBlockFromSettings (type = null) {
  let params = {
    from: 'settings'
  }
  if (type) {
    params.type = type
  }
  return request({
    url: '/content/block/collection',
    method: 'post',
    params: params,
  })
}

export function getPageFromSettings(classCode = '') {
  let params = {
    from: 'settings'
  }

  if (classCode) {
    params = {
      class_code: classCode
    }
  }

  return request({
    url: '/content/page/collection',
    method: 'post',
    data: params
  })
}

export function getBlock (id) {
  return request({
    url: '/content/block/' + id,
    method: 'get',
  })
}

export function updateBlock (id, data) {
  return request({
    url: '/content/block/' + id,
    method: 'put',
    data: data
  })
}

export function deleteBlock (id) {
  return request({
    url: '/content/block/' + id,
    method: 'delete',
  })
}

export function blockUploadImage (formData, image = {}, type = 'block') {
  return request({
    url: '/content/image',
    method: 'post',
    data: formData,
    timeout: 0,
    params: { type },
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (e) => {
      if (image) {
        image.percent = Math.floor(e.loaded * 100 / e.total)
      }
    }
  })
}

export function getTemplates (filter) {
  return request({
    url: '/content/templates/collection',
    method: 'post',
    data: filter
  })
}

export function getDataAssociatedPageType (id) {
  return request({
    url: '/content/pagetype/associated/' + id,
    method: 'get',
  })
}

export function getTemplateBuilderToken () {
  return request({
    url: '/content/get-template-builder-token',
    method: 'get',
  })
}

export function getTemplate(id) {
  return request({
    url: `content/template/${id}`,
    method: 'get',
  })
}

export function updateWidgets(data) {
  return request({
    url: 'content/widgets',
    method: 'put',
    data
  })
}
export function getUsingTemplatesName({ attributeIds, presetId, entityTypeId }) {
  return request({
    url: `/content/get-using-templates-name`,
    method: 'post',
    data: { attributeIds, presetId, entityTypeId }
  })
}

// header
export function getPrimaryCheckoutHeader (params = {}) {
  return request({
    url: '/content/primary-checkout-header',
    method: 'post',
    data: params
  })
}
export function getAllHeaders(params = {}) {
  return request({
    url: '/content/header/all',
    method: 'post',
    data: params
  })
}

export function getHeader (id) {
  return request({
    url: '/content/header/' + id,
    method: 'get',
  })
}
export function updateHeader (id, data) {
  return request({
    url: '/content/header/' + id,
    method: 'put',
    data: data
  })
}

//footer
export function getPrimaryCheckoutFooter (params = {}) {
  return request({
    url: '/content/primary-checkout-footer',
    method: 'post',
    data: params
  })
}
export function getAllFooters(params = {}) {
  return request({
    url: '/content/footer/all',
    method: 'post',
    data: params
  })
}
export function getFooter (id) {
  return request({
    url: '/content/footer/' + id,
    method: 'get',
  })
}
export function updateFooter (id, data) {
  return request({
    url: '/content/footer/' + id,
    method: 'put',
    data: data
  })
}