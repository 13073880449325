<template>
  <div class="d-flex align-items-start">
    <slot name="input"></slot>
    <div class="ml-2">
      <el-tooltip popper-class="el-tooltip__jovy success" placement="top" :key="contentKey" :disabled="Boolean(disableTooltip)">
        <div class="text-center" slot="content" style="width: 140px;" >
          {{ tooltipContent }}
        </div>
        <div>
          <jv-button
            v-if="!value"
            class="ml-0" plain type="success" size="icon"
            style="width: 39px;"
            @click="changeValue(true)">
            <i class="bi bi-pencil-square fs-16"></i>
          </jv-button>
          <jv-button
            v-else
            style="width: 39px;"
            class="restore-btn"
            plain type="success" size="icon"
            icon="bi-arrow-counterclockwise"
            @click="changeValue(false)">
          </jv-button>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'disable-tooltip'],
  data() {
    return {
      contentKey: Date.now()
    }
  },
  computed: {
    tooltipContent() {
      return this.value ?
        "Reset this setting back to your store's default value." :
        "This setting is inherited from your store's default value. Click edit to override and change this value."
    }
  },
  methods: {
    changeValue(value) {
      this.contentKey = Date.now()
      this.$emit('input', value)
    }
  }
}
</script>
