export default [
  {
    path: 'linked-products',
    component: () => import('@/components/Views/Etsy/LinkedProducts'),
    name: 'linked_products',
    meta: {
      title: 'Linked Products',
    },
  },
  {
    path: 'import-products',
    component: () => import('@/components/Views/Etsy/ImportProducts'),
    name: 'import_products',
    meta: {
      title: 'Import Products',
    },
  },
  {
    path: 'settings',
    component: () => import('@/components/Views/Etsy/Settings'),
    name: 'etsy_settings',
    meta: {
      title: 'Etsy Settings',
    },
  },
]
