<template>
  <div class="wr-filter-widget mb-3" @keypress.enter="handleKeypress">
    <div class="d-flex align-items-end">
      <transition name="el-zoom-in-top">
        <div class="filter-default" :class="[!data.additional && 'flex-fill']" v-if="!isShowMore">
          <div v-for="(item, index) in data.default" :key="'default' + index" class="d-inline-block">
            <div v-if="item.type !== 'hidden'" class="d-flex mr-2">
              <span v-if="item.label" class="flex-grow-0 ml-2 mr-1 text-width" :class="size === 'mini' ? 'py-1' : 'py-2'">{{ item.label }}:</span>
              <template v-if="item.hasRange">
                <component class="d-inline-block" :is="mapElement[item.type].el" v-model="filter[item.key + '.from']" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''" :type="mapElement[item.type].type"
                :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"></component>
                <span class="px-2 py-1 mx-1"> - </span>
                <component class="d-inline-block" :is="mapElement[item.type].el" v-model="filter[item.key + '.to']" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''" :type="mapElement[item.type].type"
                :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"></component>
              </template>
              <template v-else>
                <el-select v-if="item.type === 'country'" style="with: 100px" class="d-inline-block" v-model="filter[item.key]" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''" filterable @change="filter.state = ''">
                  <el-option value="" label="Select a Country..."></el-option>
                  <el-option v-for="(country, _id) in countries" :key="_id" :label="_id + ' - ' + country.name" :value="_id">
                    <div>
                      <strong class="mr-2">{{ _id }}</strong>
                      <span class="float-right">{{ country.name }}</span>
                    </div>
                  </el-option>
                </el-select>
                <template v-else-if="item.type === 'state'">
                  <template v-if="isHasState">
                    <el-select filterable style="with: 100px" class="d-inline-block" v-model="filter[item.key]" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''">
                      <el-option value="" label="Select a State..."></el-option>
                      <el-option
                        v-for="(state, index) in currentCountry.states"
                        :key="'option-state' + index"
                        :value="state._id"
                        :label="state._id + ' - ' + state.name"
                      >
                        <div>
                          <strong class="mr-2">{{ state._id }}</strong>
                          <span class="float-right">{{ state.name }}</span>
                        </div>
                      </el-option>
                    </el-select>
                  </template>
                  <template v-else >
                    <el-input style="with: 100px" class="d-inline-block" v-model="filter[item.key]" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''"></el-input>
                  </template>
                </template>
                <template v-else-if="item.type === 'keyword'">
                  <jv-input v-model="filter[item.key]" :placeholder="item.placeholder || 'Search'" class="el-input--normal">
                    <i v-if="item.prefix" :class="`bi bi-${item.prefix}`" slot="prefix"></i>
                    <el-tooltip v-if="item.attributes && item.attributes.length" placement="top" slot="suffix" effect="light" popper-class="el-tooltip__jovy success">
                      <div slot="content">
                        <div class="fw-500">You can search for:</div>
                        <ul class="search-info">
                          <li v-for="attribute of item.attributes" :key="attribute"><i class="bi bi-dot mr-1"></i> {{ attribute }}</li>
                        </ul>
                      </div>
                      <i class="bi bi-info-circle-fill info"></i>
                    </el-tooltip>
                  </jv-input>
                </template>
                <component v-else 
                  style="with: 100px" 
                  class="d-inline-block filter-input"
                  :is="mapElement[item.type].el" v-model="filter[item.key]" 
                  :size="size" 
                  :placeholder="'placeholder' in item ? item.placeholder : ''" 
                  :type="mapElement[item.type].type"
                  :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                  :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                >
                  <template v-if="mapElement[item.type].el === 'el-select'">
                    <template v-if="item.type === 'boolean'">
                      <el-option v-for="(item, index) in mapElement[item.type].child" :key="'option-boolean' + index" :label="item.label" :value="item.key"></el-option>
                    </template>
                    <template v-if="item.type === 'select'">
                      <template v-for="(item, index) in item.options">
                        <el-option v-if="item.value === undefined" :key="'option' + index" :label="item.label" :value="item.key"></el-option>
                        <el-option v-else :key="'option' + index" :label="item.label" :value="item.value"></el-option>
                      </template>
                    </template>
                  </template>
                </component>
              </template>
            </div>
          </div>
        </div>
      </transition>

      <div class="filter-action ml-auto text-right">
        <el-button type="primary" icon="el-icon-search" :size="size" @click="handleSearch()">Search</el-button>
        <el-button v-if="resetShow" type="danger" icon="el-icon-refresh" :size="size" @click="handleClickReset">Reset</el-button>
        <el-button type="default" v-if="data.more" plain :icon="'el-icon-arrow-' + (isShowMore ? 'up' : 'down')" :size="size" @click="isShowMore = !isShowMore">
          {{ isShowMore ? 'Less' : 'More' }}
        </el-button>
      </div>
      <transition v-if="data.additional" name="el-zoom-in-top">
        <div class="filter-default flex-fill" v-if="!isShowMore">
          <div v-for="(item, index) in data.additional" :key="'default' + index" class="d-inline-block">
            <div v-if="item.type !== 'hidden'" class="d-flex mr-2">
              <span v-if="item.label" class="flex-grow-0 ml-2 mr-1 text-width" :class="size === 'mini' ? 'py-1' : 'py-2'">{{ item.label }}:</span>
              <template v-if="item.hasRange">
                <component class="d-inline-block" :is="mapElement[item.type].el" v-model="filter[item.key + '.from']" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''" :type="mapElement[item.type].type"
                :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"></component>
                <span class="px-2 py-1 mx-1"> - </span>
                <component class="d-inline-block" :is="mapElement[item.type].el" v-model="filter[item.key + '.to']" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''" :type="mapElement[item.type].type"
                :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"></component>
              </template>
              <template v-else>
                <el-select v-if="item.type === 'country'" style="with: 100px" class="d-inline-block" v-model="filter[item.key]" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''" filterable @change="filter.state = ''">
                  <el-option value="" label="Select a Country..."></el-option>
                  <el-option v-for="(country, _id) in countries" :key="_id" :label="_id + ' - ' + country.name" :value="_id">
                    <div>
                      <strong class="mr-2">{{ _id }}</strong>
                      <span class="float-right">{{ country.name }}</span>
                    </div>
                  </el-option>
                </el-select>
                <template v-else-if="item.type === 'state'">
                  <template v-if="isHasState">
                    <el-select filterable style="with: 100px" class="d-inline-block" v-model="filter[item.key]" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''">
                      <el-option value="" label="Select a State..."></el-option>
                      <el-option
                        v-for="(state, index) in currentCountry.states"
                        :key="'option-state' + index"
                        :value="state._id"
                        :label="state._id + ' - ' + state.name"
                      >
                        <div>
                          <strong class="mr-2">{{ state._id }}</strong>
                          <span class="float-right">{{ state.name }}</span>
                        </div>
                      </el-option>
                    </el-select>
                  </template>
                  <template v-else >
                    <el-input style="with: 100px" class="d-inline-block" v-model="filter[item.key]" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''"></el-input>
                  </template>
                </template>
                <template v-else-if="item.type === 'keyword'">
                  <jv-input v-model="filter[item.key]" :placeholder="item.placeholder || 'Search'" class="el-input--normal">
                    <i v-if="item.prefix" :class="`bi bi-${item.prefix}`" slot="prefix"></i>
                    <el-tooltip v-if="item.attributes && item.attributes.length" placement="top" slot="suffix" effect="light" popper-class="el-tooltip__jovy success">
                      <div slot="content">
                        <div class="fw-500">You can search for:</div>
                        <ul class="search-info">
                          <li v-for="attribute of item.attributes" :key="attribute"><i class="bi bi-dot mr-1"></i> {{ attribute }}</li>
                        </ul>
                      </div>
                      <i class="bi bi-info-circle-fill info"></i>
                    </el-tooltip>
                  </jv-input>
                </template>
                <component v-else
                  style="with: 100px" class="d-inline-block filter-input"
                  :is="mapElement[item.type].el" v-model="filter[item.key]" 
                  :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''" 
                  :type="mapElement[item.type].type"
                  :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                  :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                  @change="triggerAdditional(item)"
                >
                  <template v-if="mapElement[item.type].el === 'el-select'">
                    <template v-if="item.type === 'boolean'">
                      <el-option v-for="(item, index) in mapElement[item.type].child" :key="'option-boolean' + index" :label="item.label" :value="item.key"></el-option>
                    </template>
                    <template v-if="item.type === 'select'">
                      <template v-for="(item, index) in item.options">
                        <el-option v-if="item.value === undefined" :key="'option' + index" :label="item.label" :value="item.key"></el-option>
                        <el-option v-else :key="'option' + index" :label="item.label" :value="item.value"></el-option>
                      </template>
                    </template>
                  </template>
                </component>
              </template>
            </div>
          </div>
        </div>
      </transition>
      <div v-if="data.buttons" class="filter-action ml-auto text-right">
        <el-button v-for="(button, index) of data.buttons" :key="index" type="primary" :size="size" @click="$emit(button.action)">{{ button.label }}</el-button>
      </div>
    </div>
    <el-collapse-transition>
      <div class="row" v-if="isShowMore">
        <div v-for="(item, index) in mergeFilter" :key="'default' + index" class="col-3">
          <div class="d-flex flex-column mr-2">
            <div class="flex-grow-0 mr-2 font-weight-bold" :class="size === 'mini' ? 'py-1' : 'py-2'">{{ item.label }}:</div>
            <template v-if="item.hasRange">
              <div class="d-flex mb-2">
                <span class="flex-grow-0 py-2" style="width: 80px">From: </span>
                <component class="d-inline-block" style="width: 100%;" :is="mapElement[item.type].el" v-model="filter[item.key + '.from']" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''" :type="mapElement[item.type].type"
                :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"></component>
              </div>
              <div class="d-flex">
                <span class="flex-grow-0 py-2" style="width: 80px">To: </span>
                <component class="d-inline-block" style="width: 100%;" :is="mapElement[item.type].el" v-model="filter[item.key + '.to']" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''" :type="mapElement[item.type].type"
                :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''">
                </component>
              </div>
            </template>
            <template v-else>
              <component :is="mapElement[item.type].el" style="width: 100%;" v-model="filter[item.key]" :size="size" :placeholder="'placeholder' in item ? item.placeholder : ''" :type="mapElement[item.type].type"
                :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''">
                <template v-if="mapElement[item.type].el === 'el-select'">
                  <template v-if="item.type === 'boolean'">
                    <el-option v-for="(option, index) in mapElement[item.type].child" :key="'option-boolean' + index" :label="option.label" :value="option.value"></el-option>
                  </template>
                  <template v-if="item.type === 'select'">
                      <template v-for="(item, index) in item.options">
                        <el-option v-if="item.value === undefined" :key="'option' + index" :label="item.label" :value="item.key"></el-option>
                        <el-option v-else :key="'option' + index" :label="item.label" :value="item.value"></el-option>
                      </template>
                    </template>
                </template>
              </component>
            </template>
          </div>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
  import { destroyAll, setOne } from '../../utils/app'

  export default {
    props: {
      data: {
        type: Object
      },
      reload: {
        default: false
      },
      isShowSearchMore: {
        type: Boolean,
        default: false
      },
      size: {
        default: 'medium'
      },
      resetShow: {
        type: Boolean,
        default: true
      },
      CID: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        isShowMore: false,
        isSave: false,
        mapElement: {
          text: {
            el: 'el-input',
            type: ''
          },
          datetime: {
            el: 'el-date-picker',
            type: 'date'
          },
          number: {
            el: 'el-input',
            type: 'number'
          },
          boolean: {
            el: 'el-select',
            child: [
              { value: '', label: 'Any' },
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ],
            default: ''
          },
          select: {
            el: 'el-select'
          },
          checkbox: {
            el: 'el-checkbox'
          }
        },
        filter: {}
      }
    },
    computed: {
      mergeFilter () {
        const filter = this.data
        return filter.default.concat('more' in filter ? filter.more : []).concat('additional' in filter ? filter.additional : [])
      },
      countries() {
        return this.$store.getters.countries
      },
      currentCountry() {
        return this.countries[this.filter.country_code] || {}
      },
      isHasState() {
        return this.currentCountry.states && this.currentCountry.states.length > 0
      },
      WID() {
        return this.CID || this.$route.name
      }
    },
    mounted () {
      this.isShowMore = this.isShowSearchMore
      if (this.reload !== false) {
        this.reloadData(this.reload)
      } else {
        this.init()
      }
      this.getSearchData()
    },
    methods: {
      async init () {
        this.isSave = true
        this.$set(this, 'filter', {})
        this.data.default.map(item => {
          if (['country_code', 'state'].includes(item.type)) {
            this.$set(this.filter, item.key, '')
            if (item.default) {
              this.$set(this.filter, item.key, item.default)
            }
          }
          if (item.key === 'keyword' && this.$route.params.keyword) {
            this.$set(this.filter, item.key, this.$route.params.keyword)
            delete this.$route.params.keyword
          }
        })
        this.mergeFilter.map(item => {
          if (['boolean', 'select', 'checkbox'].includes(item.type)) {
            this.$set(this.filter, item.key, '')
            if (item.default) {
              this.$set(this.filter, item.key, item.default)
            }
          }
        })
      },
      triggerAdditional (item) {
        if (item.triggerSearch) {
          this.handleSearch()
        }
      },
      handleClickReset () {
        this.$emit('onreset')
        destroyAll(this.WID)
        this.init()
        this.getSearchData(true)
      },
      reloadData (filter) {
        this.$set(this, 'filter', filter)
        this.isSave = true
      },
      handleSearch (filter = null) {
        if (filter !== null) {
          this.filter = Object.assign({}, this.filter, filter)
        }
        setOne(this.WID, 'filter', this.filter)
        setOne(this.WID, 'isShowSearchMore', this.isShowMore)
        this.getSearchData(true)
      },
      async getSearchData (resetPagination = false) {
        let filter = {}
        let newData = {}
        filter = this.preData(this.mergeFilter)
        await filter.map(item => {
          const key = item.key
          if (typeof this.filter[key] !== 'undefined' && this.filter[key] !== '') {
            if (item.type === 'text') {
              newData[key] = { $regex: this.filter[key], $options: 'i' }
              return true
            }
            newData[key] = (item.type === 'number') ? Number(this.filter[key]) : this.filter[key]
          } else if (item.default !== '') {
            newData[key] = item.default
          }
          if (item.hasRange) {
            const f = key + '.from'
            const t = key + '.to'
            if (this.filter[f] || this.filter[t]) {
              newData[key] = {}
              if (this.filter[f]) {
                newData[key]['$gte'] = (item.type === 'number') ? Number(this.filter[f]) : this.filter[f]
              }
              if (this.filter[t]) {
                newData[key]['$lte'] = (item.type === 'number') ? Number(this.filter[t]) : this.filter[t]
              }
            }
          }
        })
        this.$emit('search', {
          data: newData,
          resetPagination: resetPagination
        })
      },
      preData (arr) {
        return arr
      },
      handleKeypress ($event) {
        this.handleSearch()
      },
      reset(isOrderFilter=false, isTest=false) {
        destroyAll(this.WID)
        if (isOrderFilter) {
          this.$set(this, 'filter', { order_tags: "", status: "", is_test: isTest })
        } else {
          this.init()
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .text-width {
    min-width: max-content;
  }
  .wr-filter-widget {
    background: #f2f2f2;
    padding: 10px 10px;
    color: var(--gray);
    font-size: 15px;

    :deep().el-form--label-top .el-form-item__label {
      padding: 0 !important;
      margin-bottom: 0 !important;
    }

    :deep() .no-m-bottom {
      .el-form-item {
        margin-bottom: 0 !important;

        label {
          margin-bottom: 0 !important;
        }
      }
    }

    .filter-action {
      .el-button {
        margin-bottom: 2px;
      }
    }
  }
  .filter-input {
    margin: auto;
  }
  .search-info {
    list-style: none;
    margin: 0;
    padding: 0;
  }
</style>
