import { getToken, getUser, setToken, setUser, unsetUser, setClient, getClient, setLockin, getLockPeriod, removeToken } from '@/utils/auth'
import { login, logout } from '@/api/login'
import { lock, unlock } from '@/api/user'
const user = {
  state: {
    token: getToken(),
    user: getUser(),
    isLocked: false,
    enableLockMode: true,
    lock: {
      enabled: true,
      period: getLockPeriod(),
    },
    client: getClient(),
    clients: []
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      if (token) {
        setToken(token)
      } else {
        removeToken()
      }
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    UNSET_USER: (state) => {
      state.user = null
      state.token = null
    },
    LOCK_SESSION: (state) => {
      state.isLocked = true
    },
    UNLOCK_SESSION: (state) => {
      state.isLocked = false
    },
    LOCK_SETTING: (state, value) => {
      state.lock = value
    },
    SET_CLIENT: (state, value) => {
      state.client = value
      setClient(value)
    },
    SET_CLIENTS: (state, value) => {
      state.clients = value
    },
    SET_USER_FULLNAME: (state, data) => {      
      if(state.user._id == data._id){
        state.user.first_name = data.newUserFirstName;
        state.user.last_name = data.newUserLastName;        
        state.user.user = state.user;
        var toUpdateUser = getUser();
        toUpdateUser.first_name = data.newUserFirstName;
        toUpdateUser.last_name = data.newUserLastName;       
        setUser(toUpdateUser); 
      }       
    },
    SET_LOCK: (state, value) => {
      state.lock.period = value
    }, 
    SET_USER_TYPE: (state, type) => {
      state.user = {...state.user, type: type}
    },
  },
  actions: {
    login ({commit}, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(e => {
          if (e.data.success) {
            commit('SET_TOKEN', e.data.token)
            commit('SET_USER', e.data.user)
            setToken(e.data.token)
            setUser(e.data.user)
          }
          resolve(e)
        }).catch(err => {
          reject(err)
        })
      })
    },
    logout ({commit}) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('UNSET_USER')
          unsetUser()
          resolve()
        })
      })
    },
    setLoginData ({ commit }, data) {
      commit('SET_TOKEN', data.token)
      commit('SET_USER', data.user)
      commit('SET_LOCK', data.expires_at)
      setUser(data.user)
    },
    tokenNotValid ({commit, dispatch}) {
      commit('UNSET_USER')
      commit('SET_CLIENTS', [])
      // commit('SET_CLIENT', '')
      unsetUser()
      return Promise.resolve('true')
    },
    lockSession ({ commit }) {
      lock().then(({ data }) => {
        if (data['success']) {
          commit('LOCK_SESSION')
        }
      })
    },
    unlockSession ({ commit }, value) {
      return unlock(value).then(({ data }) => {
        if (data['success']) {
          setTimeout(() => {
            commit('UNLOCK_SESSION')
          }, 1500)
          return Promise.resolve()
        }
        return Promise.reject()
      })
    },
    setClient ({ commit }, value) {
      commit('SET_CLIENT', value)
    },
    setClients ({ commit }, value) {
      commit('SET_CLIENTS', value)
    },
    setUserFullName({ commit }, data) {
      commit('SET_USER_FULLNAME', data);      
    },
    setUserType({ commit }, type) {
      commit('SET_USER_TYPE', type);      
    },
    setLockPeriod({ commit }, expires_at) {
      commit('SET_LOCK', expires_at)
      setLockin(expires_at)
    },
  }
}


export default user
