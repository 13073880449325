export default [
  {
    path: 'customers',
    component: () => import('@/components/Views/Customer/Customers/Main'),
    name: 'customer_customers',
    meta: {
      title: 'All Customer',
      pagination: true
    }
  },
  {
    path: 'customers/new',
    component: () => import('@/components/Views/Customer/Customers/New'),
    name: 'customer_customers_new',
    meta: {
      title: 'New Customer',
      activePath: '/customer/customers/'
    }
  },
  {
    path: 'customers/edit/:id',
    component: () => import('@/components/Views/Customer/Customers/Edit'),
    name: 'customer_customers_edit',
    meta: {
      title: 'Edit Customer',
      activePath: '/customer/customers/'
    }
  },
  {
    path: 'group',
    component: () => import('@/components/Views/Customer/Group/Main'),
    name: 'customer_group_main',
    meta: {
      title: 'Customer Groups'
    }
  },
  {
    path: 'group/new',
    component: () => import('@/components/Views/Customer/Group/Edit'),
    name: 'customer_group_new',
    meta: {
      title: 'New Customer Group'
    }
  },
  {
    path: 'group/edit/:id',
    component: () => import('@/components/Views/Customer/Group/Edit'),
    name: 'customer_group_edit',
    meta: {
      title: 'Edit Customer Group'
    }
  },
  {
    path: 'contacts',
    component: () => import('@/components/Views/Customer/Contacts/Main'),
    name: 'Contacts',
    meta: {
      title: 'contacts'
    }
  }
]