<template>
  <div id="app">
    <router-view/>
    <lock-page v-if="isLocked"></lock-page>
    <auto-lock></auto-lock>
  </div>
</template>

<script>
import LockPage from './components/Pages/Lock'
import AutoLock from './components/Globals/AutoLock'
export default {
  components: {
    LockPage,
    AutoLock
  },
  computed: {
    isLocked () {
      return this.$store.getters.isLocked
    }
  }
}
</script>

<style lang="scss">
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

