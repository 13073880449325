<template>
  <component :is="`${icon}-icon`" :size="size" class="icon" @click="$emit('click')" />
</template>
<script>
/* Lazy-load icon components on demand */
const components = {}
// *** Please add in by alphabet
const icons = [
  'AlertCircleIcon',
  'AlertTriangleIcon',
  'ArrowRightCircleIcon',
  'CalendarIcon',
  'CheckCircleIcon',
  'ChevronLeftIcon',
  'ChevronRightIcon',
  'ChevronUpIcon',
  'ChevronsUpIcon',
  'ChevronDownIcon',
  'ChevronsDownIcon',
  'EditIcon',
  'Edit3Icon',
  'ExternalLinkIcon',
  'FileTextIcon',
  'GiftIcon',
  'MinusIcon',
  'InfoIcon',
  'LayersIcon',
  'MessageSquareIcon',
  'PackageIcon',
  'PauseCircleIcon',
  'SlashIcon',
  'TagIcon',
  'UserIcon',
  'XCircleIcon',
  'YoutubeIcon',
]
icons.forEach((name) => {
  components[name] = () => import(`vue-feather-icons/icons/${name}`)
})

export default {
  props: {
    icon: {
      type: String,
      require: true,
    },
    size: {
      type: String,
      default: '22',
    },
  },
  components,
}
</script>
