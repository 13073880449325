export default [
  {
    path: '/',
    component: () => import('@/components/Views/Marketing/PromotionPriority/Main'),
    name: 'priority_manager',
    meta: {
      title: 'Priority Manager'
    }
  },
]
