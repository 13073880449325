<template>
  <el-input-number class="el-input-number__jovy" :value="value" v-bind="$attrs" :step="step" :controls="controls" :placeholder="placeholder"
    @input="($event) => $emit('input', $event)" @change="$emit('change')">
    <slot v-if="$slots.prepend" name="prepend" slot="prepend"></slot>
  </el-input-number>
</template>
<script>
export default {
  name: 'jv-input-number',
  props: ['value', 'step', 'controls', 'placeholder'],
}
</script>
<style lang="scss" scoped>
.el-input-number.el-input-number__jovy {
  &:before {
    content: attr(aria-label);
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    font-size: 14px;
  }
  &.is-disabled {
    &::before, .el-input__inner {
      color: var(--jv-tertiary);
    }
  }
  :deep() {
    .el-input-number__decrease, .el-input-number__increase, .el-input__inner {
      border-color: var(--jv-gray-300);
    }
    .el-input-number__decrease, .el-input-number__increase {
      background: white;
      height: 38px;
      width: 42px;
      &:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
        border-color: var(--jv-brand-300);
      }
    }
    .el-input__inner {
      &:focus {
        border-color: var(--jv-brand-300);
      }
    }
  }
  @media (min-width: $sm) {
    max-width: 192px;
    line-height: 32px;
    :deep() {
      .el-input-number__decrease, .el-input-number__increase {
        height: 30px;
      }
      .el-input__inner {
        line-height: 32px;
        height: 32px;
      }
    }
  }
  &.el-input-number--large, &.el-input-number--large-small {
    line-height: 40px;
    max-width: none;
    :deep() {
      .el-input-number__decrease, .el-input-number__increase {
        height: 38px;
      }
      .el-input__inner {
        line-height: 40px;
        height: 40px;
        padding: 0 50px;
      }
    }
    @media (min-width: $sm) {
      line-height: 40px;
      :deep() {
        .el-input-number__decrease, .el-input-number__increase {
          height: 38px;
        }
        .el-input__inner {
          line-height: 40px;
          height: 40px;
        }
      }
    }
  }
  &.el-input-number--large-small {
    width: 100%;
  }
}
</style>
