<template>
    <el-tag :type="type" :effect="isTag ? 'light' : 'plain'" :class="className">
        <span v-html="(isBracket ? '(' : '') + (['primary', 'success'].includes(type) ? '+' : type === 'danger' ? '-' : '') + formatValue() + (isBracket ? ')' : '')"></span>
    </el-tag>
</template>
<script>
export default {
    name: 'type-adjustment-tag',
    props: {
        row: {
            type: Object,
            require: true
        },
        column: {
            type: Object,
            require: true
        },
        isTag: {
            type: Boolean,
            default: true
        },
        className: {
            type: String,
            default: null
        },
        isBracket: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        type() {
            let value = this.row[this.column.field_name]
            if(this.column.relation) value = this.row[this.column.relation][this.column.field_name]
            if(Number(value) > 0) return this.$isMobile ? 'primary' : 'success';
            if(Number(value) < 0) return 'danger';
            return 'info'
        }
    },
    methods: {
        formatValue() {
            let value = this.row[this.column.field_name]
            if(this.column.relation) value = this.row[this.column.relation][this.column.field_name]
            if(value < 0) value = value * -1;
            if (this.column.field_name === 'adjustment_cost') {
                return this.formatCost(value)
            }
            return value
        }
    }
}
</script>
