<template>
  <div v-if="websites && websites.length">
    <el-select :value="value" @change="$emit('input', $event)" :disabled="disabled">
      <el-option v-for="(website, index) in websites" :key="'website' + index" :label="website.name" :value="website._id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: ['value', 'disabled'],
  computed: {
    websites () {
      return this.$store.getters['websites']
    },
    websiteId () {
      return this.$store.getters['websiteId']
    }
  },
  mounted () {
    this.$emit('input', this.websiteId)
  },
  watch: {
    websiteId () {
      this.$emit('input', this.websiteId)
    }
  }
}
</script>
