<template>
  <jv-button v-if="isShowToggleBtn" size="icon square-36" type="default" @click="toggleSidebar" class="d-none d-lg-inline-block">
    <i :class="isShow ? 'bi bi-arrow-bar-left' : 'bi bi-arrow-bar-right'"></i>
  </jv-button>
</template>
<script>
export default {
  name: 'toggle-sidebar-button',
  inject: ['isShowSidebar'],
  props: ['position'],
  computed: {
    isShow() {
      if(typeof this.isShowSidebar === 'function') {
        return this.isShowSidebar()
      }
      return false
    },
    isShowToggleBtn() {
      if (!this.position) {
        return !this.isShow
      }
      return true
    },
  },
  methods: {
    toggleSidebar(action) {
      let isShow = this.isShow
      if (action === 'show') {
        isShow = false
      } else if (action === 'hide') {
        isShow = true
      }
      this.$root.$emit('setting-toggle-sidebar', !isShow)
    }
  }
}
</script>