<template>
  <div :class="className">
    <el-tooltip :content="tooltipMessage">
      <el-button type="primary" size="small" icon="el-icon-document-copy" @click="copyData" class="mr-1"></el-button>
    </el-tooltip>
    <el-popover
      v-if="!$isMobile"
      placement="top-start"
      popper-class="w-90 is-dark"
      trigger="hover"
      :visible-arrow="false"
      :content="formatValue">
      <span slot="reference">{{ formatValue }}</span>
    </el-popover>
    <template v-else>
      {{ formatValue }}
    </template>
  </div>
</template>
<script>

export default {
  props: {
    row: {
      type: Object,
      require: true
    },
    column: {
      type: Object,
      require: true
    },
    className: {
      type: String,
      default: null
    }
  },
  data() {
    return  {
      tooltipMessage: 'Copy API Response'
    }
  },
  computed: {
    formatValue() {
      let value = this.row[this.column.field_name]
      if (this.column.relation) value = this.row[this.column.relation][this.column.field_name]
      return value && JSON.stringify(value) || ''
    }
  },
  methods: {
    copyData() {
      navigator.clipboard.writeText(this.formatValue)
      this.tooltipMessage = 'Copied'
      setTimeout(() => {
        this.tooltipMessage = 'Copy API Response'
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
.response-column .cell {
  white-space: nowrap !important;
  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.is-dark {
  background: #303133 !important;
  color: #fff !important;
}
.w-90 {
  max-width: 90%;
}
</style>
