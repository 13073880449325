<template>
  <el-button
    :class="['el-button__jovy', customClass.split(' ')]"
    :size="size"
    :type="type"
    :plain="plain"
    :disabled="disabled"
    :loading="loading"
    @click="$emit('click', $event)"
    :style="customStyle"
  >
    <i v-if="icon" :class="iconClass" />
    <slot />
  </el-button>
</template>

<script>
export default {
  name: "Button",
  props: {
    icon: {
      type: String,
    },
    size: {
      type: String,//mini, small, medium, large, icon
    },
    type: {
      type: String,//primary, secondary, warning, info, success, danger, tertiary, element
    },
    plain: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    customClass: {
      type: String,
      default: '',
    },
    customStyle: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconClass() {
      let className = ''
      if (this.icon) {
        className = this.icon
        if (this.icon.startsWith('bi-')) {
          className += ' bi'
        }
        if (this.size !== 'icon') {
          className += ' mr-1'
        }
      }
      return className
    },
  },
}
</script>

<style lang="scss">
.el-button {
  &.el-button__jovy {
    line-height: 22px;
    padding: 6px 16px;
    & > span {
      display: inline-flex;
      align-items: center;
      line-height: 16px;
      & > .bi {
        font-size: 16px;
      }
    }
    &.el-button--link {
      padding: 0;
      border: 0;
      background: transparent;
      color: var(--jv-simple-color);
      font-weight: 400;
      &:focus {
        border: 0;
        background: transparent;
        color: var(--jv-simple-color)
      }
      &:hover {
        text-decoration: underline;
      }
    }
    &.el-button--info, &.el-button--secondary {
      background-color: var(--jv-bg-secondary);
      border-color: var(--jv-bg-secondary);
      color: white;
      &:focus {
        background-color: var(--jv-bg-secondary);
        border-color: var(--jv-bg-secondary);
        color: white;
      }
      &:hover {
        background-color: var(--jv-bg-secondary-plain);
        color: var(--jv-bg-secondary);
      }
      &.is-plain {
        background-color: var(--jv-bg-secondary-plain);
        border-color: var(--jv-secondary-border);
        color: var(--jv-secondary-color);
        &:focus {
          background-color: var(--jv-bg-secondary-plain);
          border-color: var(--jv-secondary-border);
          color: var(--jv-secondary-color);
        }
        &:hover {
          background-color: var(--jv-bg-secondary);
          color: white;
        }
      }
    }
    &.el-button--tertiary {
      background-color: var(--jv-bg-tertiary);
      border-color: var(--jv-bg-tertiary);
      color: white;
      &:focus {
        background-color: var(--jv-bg-tertiary);
        border-color: var(--jv-bg-tertiary);
        color: white;
      }
      &:hover {
        background-color: var(--jv-bg-tertiary-plain);
        color: var(--jv-bg-tertiary);
      }
      &.is-plain {
        background-color: var(--jv-bg-tertiary-plain);
        border-color: var(--jv-tertiary-border);
        color: var(--jv-tertiary-color);
        &:focus {
          background-color: var(--jv-bg-tertiary-plain);
          border-color: var(--jv-tertiary-border);
          color: var(--jv-tertiary-color);
        }
        &:hover {
          background-color: var(--jv-bg-tertiary);
          color: white;
        }
      }
    }
    &.el-button--white {
      background: transparent;
      border: 1px solid white;
      color: white;
    }
    &.el-button--white, &.el-button--default {
      &.is-plain {
        background: white;
        border-color: var(--jv-tertiary-border);
        color: var(--jv-tertiary-color);
        &:focus {
          background: white;
          border-color: var(--jv-tertiary-border);
          color: var(--jv-tertiary-color);
        }
      }
      &:hover, &:focus, &:active {
        background: var(--jv-bg-primary-plain);
        color: var(--jv-bg-primary);
        border-color: var(--jv-primary-border);
      }
    }
    &.el-button--primary {
      background-color: var(--jv-bg-primary);
      border-color: var(--jv-bg-primary);
      color: #FFFFFF;
      &.is-plain {
        background-color: var(--jv-bg-primary-plain);
        border-color: var(--jv-primary-border);
        color: var(--jv-primary-color);
        &:focus {
          background-color: var(--jv-bg-primary-plain);
          border-color: var(--jv-primary-border);
          color: var(--jv-primary-color);
        }
        &:hover {
          background-color: #E9F2E6;
          color: var(--jv-primary-color);
        }
      }
      &:focus {
        background-color: var(--jv-bg-primary);
        border-color: var(--jv-bg-primary);
        color: #FFFFFF;
      }
      &:hover {
        background-color: #3C7222;
        color: white;
      }
    }
    &.el-button--danger, &.el-button--error {
      background-color: var(--jv-bg-danger);
      border-color: var(--jv-bg-danger);
      color: white;
      &:focus {
        background-color: var(--jv-bg-danger);
        border-color: var(--jv-bg-danger);
        color: white;
      }
      &:hover {
        background-color: #C30E0E;
        color: white;
      }
      &.is-plain {
        background-color: var(--jv-bg-danger-plain);
        border-color: var(--jv-danger-border);
        color: var(--jv-danger-color);
        &:focus {
          background-color: var(--jv-bg-danger-plain);
          border-color: var(--jv-danger-border);
          color: var(--jv-danger-color);
        }
        &:hover {
          background-color: #FCD8D8;
          color: var(--jv-red-400);
        }
      }
    }
    &.el-button--danger-bold {
      background-color: var(--jv-bg-danger);
      border-color: var(--jv-bg-danger);
      color: white;
    }
    &.el-button--warning {
      background-color: var(--jv-bg-warning);
      border-color: var(--jv-bg-warning);
      color: white;
      &:focus {
        background-color: var(--jv-bg-warning);
        border-color: var(--jv-bg-warning);
        color: white;
      }
      &:hover {
        background-color: var(--jv-bg-warning-plain);
        color: var(--jv-bg-warning);
      }
      &.is-plain {
        background-color: var(--jv-bg-warning-plain);
        border-color: var(--jv-warning-border);
        color: var(--jv-warning-color);
        &:focus {
          background-color: var(--jv-bg-warning-plain);
          border-color: var(--jv-warning-border);
          color: var(--jv-warning-color);
        }
        &:hover {
          background-color: var(--jv-bg-warning);
          color: white;
        }
      }
    }
    &.el-button--success {
      background-color: var(--jv-bg-success);
      border-color: var(--jv-bg-success);
      color: white;
      &:focus {
        background-color: var(--jv-bg-success);
        border-color: var(--jv-bg-success);
        color: white;
      }
      &:hover {
        background-color: var(--jv-bg-success-plain);
        color: var(--jv-bg-success);
      }
      &.is-plain {
        background-color: var(--jv-bg-success-plain);
        border-color: var(--jv-success-border);
        color: var(--jv-success-color);
        &:focus {
          background-color: var(--jv-bg-success-plain);
          border-color: var(--jv-success-border);
          color: var(--jv-success-color);
        }
        &:hover {
          background-color: var(--jv-bg-success);
          color: white;
        }
      }
    }
    &.el-button--element {
      background-color: var(--jv-bg-element);
      border-color: var(--jv-bg-element);
      color: white;
      &:focus {
        background-color: var(--jv-bg-element);
        border-color: var(--jv-bg-element);
        color: white;
      }
      &:hover {
        background-color: var(--jv-bg-element-plain);
        color: var(--jv-bg-element);
      }
      &.is-plain {
        background-color: var(--jv-bg-element-plain);
        border-color: var(--jv-element-border);
        color: var(--jv-element-color);
        &:focus {
          background-color: var(--jv-bg-element-plain);
          border-color: var(--jv-element-border);
          color: var(--jv-element-color);
        }
        &:hover {
          background-color: var(--jv-bg-element);
          color: white;
        }
      }
    }
    &.el-button--small {
      padding: 5px 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      display: inline-block;
    }
    &.el-button--mini {
      padding: 0 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 32px;
      display: inline-block;
    }
    &.size-md {
      width: 100%;
      max-width: 200px;
    }
    &.is-disabled {
      opacity: 0.6;
    }
    &.el-button--icon {
      line-height: 32px;
      width: 32px;
      height: 32px;
      padding: 0;
      text-align: center;
      i {
        line-height: 16px;
        font-size: 16px;
      }
    }
    &.el-button--icon-small {
      line-height: 0;
      width: 22px;
      height: 22px;
      padding: 0;
      text-align: center;
      i {
        line-height: 0;
        font-size: 10px;
      }
    }
    &.el-button--option {
      padding: 0;
      .jv-button-box {
        display: flex;
        align-items: center;
        color: var(--jv-gray-500);
        border-radius: 4px;
        overflow: hidden;
        transition: all 0.5s;
        .icon-box {
          min-width: 48px;
          height: 76px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: solid 1px var(--jv-gray-300);
          background: var(--jv-gray-100);
        }
        .title {
          overflow: hidden;
          color: var(--jv-gray-500);
          text-align: left;
          .main-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            margin: 0;
            padding-bottom: 4px;
          }
          .sub-title {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            margin: 0;
            white-space: normal;
          }
        }
      }
      &:focus, &:hover {
        background-color: var(--jv-brand-100);
        border-color: var(--jv-brand-300);
        color: var(--jv-brand-500);
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
        .icon-box {
          background-color: var(--jv-brand-200);
          border-color: var(--jv-brand-300);
          color: var(--jv-brand-500);
        }
        .title {
          color: var(--jv-brand-500);
        }
      }
    }
    &.el-button--option-top {
      color: var(--jv-tertiary);
      padding: 16px 12px;
      min-width: 130px;
      background: #FFFFFF;
      border: 1px solid var(--jv-gray-300);
      border-radius: 4px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
      & > span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        & > i {
          color: var(--jv-secondary);
        }
      }
      &.selected, &:hover {
        color: var(--jv-brand-500);
        background: var(--jv-brand-100);
        border: 1px solid var(--jv-brand-300);
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
        & > span {
          i {
            color: var(--jv-brand-500);
          }
        }
      }
    }
    &.payment-log-void {
      width: 62px !important;
      height: 32px;
      &.mobile {
        top: 10px;
        position: absolute;
        right: 10px;
      }
    }
    &.square-28 {
      width: 28px !important;
      height: 28px;
    }
    &.square-32 {
      width: 32px !important;
      height: 32px;
    }
    &.square-36 {
      width: 36px !important;
      height: 36px;
    }
  }
}
</style>
