export default [
  {
    path: 'old-pages',
    component: () => import('@/components/Views/Content/OldPage/Main'),
    name: 'content_old_pages',
    meta: {
      title: 'Pages',
    },
  },
  {
    path: 'pages',
    component: () => import('@/components/Views/Content/Page/Main'),
    name: 'content_pages',
    meta: {
      title: 'Pages',
    },
  },
  {
    path: 'pages/new',
    component: () => import('@/components/Views/Content/OldPage/Edit'),
    name: 'content_pages_new',
    meta: {
      title: 'New Page',
    },
  },
  {
    path: 'old-pages/:id',
    component: () => import('@/components/Views/Content/OldPage/Edit'),
    name: 'content_old_pages_edit',
    meta: {
      title: 'Edit Page',
    },
  },
  {
    path: 'pages/:id',
    component: () => import('@/components/Views/Content/Page/PageEdit'),
    name: 'content_pages_edit',
    meta: {
      title: 'Edit Page',
    },
  },
  {
    path: 'blocks',
    component: () => import('@/components/Views/Content/Block/Main'),
    name: 'content_blocks',
    meta: {
      title: 'Blocks',
    },
  },
  {
    path: 'popups',
    component: () => import('@/components/Views/Content/Popup/Main'),
    name: 'content_popups',
    meta: {
      title: 'Popups',
    },
  },
  {
    path: 'forms',
    component: () => import('@/components/Views/Content/Form/Main'),
    name: 'content_forms',
    meta: {
      title: 'Forms',
    },
  },
  {
    path: 'forms/:id',
    component: () => import('@/components/Views/Content/Form/Edit'),
    name: 'content_form_edit',
    meta: {
      title: 'Edit Form',
      type: 'edit',
    },
  },
  {
    path: 'forms/:id/submissions',
    component: () => import('@/components/Views/Content/Form/View'),
    name: 'content_form_datalist',
    meta: {
      title: 'Submission Data',
      type: 'view',
    },
  },

  {
    path: 'forms/:id/submissions/:submissionID',
    component: () => import('@/components/Views/Content/Form/View'),
    name: 'content_form_datalist_details',
    meta: {
      title: 'Submission Data',
      type: 'view',
    },
  },
  {
    path: 'blocks/new',
    component: () => import('@/components/Views/Content/Block/Form'),
    name: 'content_blocks_new',
    meta: {
      title: 'New Block',
      type: 'new',
    },
  },
  {
    path: 'blocks/:id',
    component: () => import('@/components/Views/Content/Block/Form'),
    name: 'content_blocks_edit',
    meta: {
      title: 'Edit Block',
      type: 'edit',
    },
  },
  {
    path: 'popups/new',
    component: () => import('@/components/Views/Content/Popup/Form'),
    name: 'content_popups_new',
    meta: {
      title: 'New Popup',
      type: 'new',
    },
  },
  {
    path: 'popups/:id',
    component: () => import('@/components/Views/Content/Popup/Form'),
    name: 'content_popups_edit',
    meta: {
      title: 'Edit Popup',
      type: 'edit',
    },
  },
  {
    path: 'faq',
    component: () => import('@/components/Views/Content/FAQ/Main'),
    name: 'content_faq_main',
    meta: {
      title: 'FAQ Manager',
    },
  },
  /* Preset */
  {
    path: 'template-auth/:page/:tab?',
    component: () => import('@/components/Views/Content/TemplateAuth'),
    name: 'template_auth',
    meta: {
      title: 'Template auth',
    }
  },
  {
    path: 'headers',
    component: () => import('@/components/Views/Content/Header/Main'),
    name: 'Header',
    meta: {
      title: 'Header',
    },
  },
  {
    path: 'footers',
    component: () => import('@/components/Views/Content/Footer/Main'),
    name: 'Footer',
    meta: {
      title: 'Footer',
    },
  },
]
