import { checkClientType } from "@/utils/client"

export default {
  namespaced: true,
  state: {
    client: null,
  },
  mutations: {
    SET_CLIENT: (state, client) => {
      state.client = client
    },
  },
  getters: {
    client: (state) => state.client,
    isSuperClient: (state) => {
      if (state.client) {
        return checkClientType(
          ['essential', 'marketplace'],
          { client: state.client, isSuperClient: true }
        )
      }
      return false
    },
  },
}
