<template>
  <div>
    <div class="overlay-locker">
      <div class="position-relative">
        <div class="content">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="text-center">
                <div style="font-size: 5rem;"><i :class="{['text-' + currentStatus]: true, ['el-icon-' + (currentStatus === 'success' ? 'unlock': 'lock')]: true}"></i></div>
                <h1>Hi, {{ user.first_name }} {{ user.last_name }}</h1>
                <h4 class="py-4">Your session has been locked due to a security reason, please enter current password to continue!</h4>
                <div class="d-flex">
                  <div class="flex-fill">
                    <el-input placeholder="Your password" @keyup.enter.native="unlock" :class="currentStatus" v-model="password" show-password>
                    </el-input>
                  </div>
                  <div>
                    <el-button :type="currentStatus" class="rounded-0" @click="unlock">
                      <i class="el-icon-unlock"></i>
                      <span>Unlock</span>
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isLocked () {
      return this.$store.getters.isLocked
    },
    user () {
      return this.$store.getters.user
    }
  },
  data () {
    return {
      password: '',
      currentStatus: ''
    }
  },
  methods: {
    unlock (event) {
      this.$store.dispatch('unlockSession', this.password).then(() => {
        this.currentStatus = 'success'
      }).catch(() => {
        this.currentStatus = 'danger'
      })
    }
  }
}
</script>


<style lang="scss" scoped>
  :deep().overlay-locker {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #111;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 111111;
    .content {
      margin-top: 5rem;
      color: #fff;
    }
    .el-input__inner {
      background: #111;
      border-radius: 0 !important;
      border-right: none !important;
      border-color: var(--white) !important;
    }
    .el-input.danger {
       .el-input__inner {
        border-color: var(--danger) !important;
        &:focus {
          border-color: var(--danger) !important;
        }
      }
    }
    .el-input.success {
      .el-input__inner {
        border-color: var(--success) !important;
        &:focus {
          border-color: var(--success) !important;
        }
      }
    }
  }
</style>

