<template>
  <el-dialog
    :width="width"
    :top="topMargin"
    :title="title"
    :class="{'mx-auto el-dialog__jovy': !width, 'loading-modal': ['process', 'loading'].includes(typeDialog)}"
    append-to-body
    :visible.sync="visibleModal"
    :before-close="handleClose"
    :custom-class="classModal"
    :show-close="showClose"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="closeOnPressEscape"
    :destroy-on-close="destroyOnClose"
  >
    <div v-if="message || iconOnly">
      <div class="text-center fs-64" v-if="!['process', 'loading'].includes(typeDialog)">
        <i class="bi" :class="iconType + ' ' + customIconClass"></i>
      </div>
      <div class="text-center fs-20 lh-28 fw-700 text-primary" v-if="typeDialog !== 'process'" v-html="message"></div>
      <div :class="{'text-center dot-loader-content': true, 'mt-40': typeDialog === 'loading'}" v-if="['process', 'loading'].includes(typeDialog)">
        <div class="dot-loader"></div>
      </div>
      <div class="text-center fs-20 lh-28 fw-700 text-primary mt-20" v-if="typeDialog === 'process'" v-html="message"></div>
    </div>
    <slot v-if="$slots.content" name="content"></slot>
    <slot v-if="$slots.footer" name="footer" slot="footer" class="dialog-footer"></slot>
  </el-dialog>
</template>
<script>
export default {
  name: 'ModalWidget',
  props: {
    visibleSync: { type: Boolean, default: false },
    title: { type: String },
    customClass: { type: String || Array },
    dialogClass: { type: String, default: 'dialog-xlg' },
    typeDialog: { type: String, default: 'confirm' /* confirm, success, warning, error, info */ },
    message: { type: String },
    showClose: { type: Boolean, default: true },
    closeOnClickModal: { type: Boolean, default: false },
    closeOnPressEscape: { type: Boolean, default: true },
    customIconClass: { type: String, default: '' },
    width: { type: String, default: '' },
    topMargin: { type: String, default: '15vh' },
    iconOnly: { type: Boolean, default: false },
    destroyOnClose: { type: Boolean, default: false },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    visibleModal() {
      return this.visibleSync
    },
    classModal() {
      let dialogClass = this.dialogClass
      if (['process', 'loading'].includes(this.typeDialog)) {
        dialogClass = 'dialog-fit-content'
      }
      if (this.typeDialog == 'warning') {
        dialogClass += ' warning-dialog'
      }
      return (Array.isArray(this.customClass) ? this.customClass.join(' ') : this.customClass || '') + ` ${dialogClass}`
    },
    iconType() {
      if(this.typeDialog === 'confirm')
        return 'bi-question-circle text-brand-400'
      if(this.typeDialog === 'warning')
        return 'bi-exclamation-circle text-yellow-400'
      if(this.typeDialog === 'error')
        return 'bi-exclamation-triangle text-red-400'
      if(this.typeDialog === 'info')
        return 'bi-info-circle text-brand-400'
      return 'bi-check2-circle text-green-400'
    },
  },
  methods: {
    handleClose() {
      this.$emit('closeModal', false)
    },
  },
}
</script>

<style scoped lang="scss">
:deep(.warning-dialog) {
  .el-dialog__footer {
    .el-button--primary {
      background-color: var(--jv-bg-danger) !important;
      border-color: var(--jv-bg-danger) !important;
      color: white;
      &:hover {
        background-color: #C30E0E !important;
        color: white !important;
      }
    }
  }
}
</style>
