import request from '@/utils/request'

export function firstRequest () {
  return request({
    url: '/helper/init',
    method: 'get',
  })
}

export function getCountries() {
  return request({
    url: '/helper/countries',
    method: 'get'
  })
}

export function getGoogleReCaptchaSiteKey() {
  return request({
    url: '/helper/google-re-captcha-site-key',
    method: 'get'
  })
}

export function getPaymentProviders(params = {}) {
  return request({
    url: '/payment-providers',
    method: 'get',
    params,
  })
}

export function getPaymentProviderForSetting(params = {}) {
  return request({
    url: '/setting-payment-providers',
    method: 'get',
    params,
  })
}

export function validatePayments(data) {
  return request({
    url: '/validate-payments',
    method: 'post',
    data,
  })
}

export function getNavItems() {
  return request({
    method: 'get',
    url: '/helper/nav-items',
  })
}

export function getMenuBubbleCounter() {
  return request({
    method: 'get',
    url: '/helper/get-menu-bubble-counter',
  })
}

export function checkDocVersion(data) {
  return request({
    url: '/helper/check-doc-version',
    method: 'post',
    data,
  })
}

export function getCmsFontsAndColors() {
  return request({
    method: 'get',
    url: '/helper/cms-fonts-colors',
  })
}
