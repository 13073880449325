<template>
  <el-popover v-model="visible" popper-class="jv-pop-confirm-popper">
    <template slot="reference">
      <slot/>
    </template>
    <div class="message" v-if="isHtml" v-html="message"></div>
    <div v-else class="message text-center">{{ message }}</div>
    <div class="buttons">
      <jv-button plain size="mini" type="tertiary" :disabled="loading" @click="handleCancel">{{ cancelButtonText }}</jv-button>
      <jv-button plain size="mini" :type="confirmButtonType" :loading="loading" @click="handleConfirm">{{ confirmButtonText }}</jv-button>
    </div>
  </el-popover>
</template>
<script>
export default {
  name: 'JvPopConfirm',
  props: {
    message: { type: String, default: 'Are you sure?' },
    isHtml: { type: Boolean, default: false },
    placement: { type: String, default: 'bottom' },
    cancelButtonText: { type: String, default: 'Cancel' },
    confirmButtonText: { type: String, default: 'Confirm' },
    confirmButtonType: { type: String, default: 'danger' },
    confirmAction: { type: Function },
  },
  data () {
    return {
      visible: false,
      loading: false,
    }
  },
  methods: {
    handleCancel () {
      this.$emit('cancel')
      this.$emit('close')
      this.visible = false
    },
    async handleConfirm () {
      if (typeof this.confirmAction === 'function') {
        this.loading = true
        await this.confirmAction()
        this.loading = false
      } else {
        this.$emit('confirm')
      }
      this.$emit('close')
      this.visible = false
    },
  },
}
</script>
<style lang="scss">
.jv-pop-confirm-popper {
  max-width: 188px;
  .message {
    font-size: 14px;
    line-height: 22px;
    color: var(--jv-text);
    word-break: break-word;
  }
  .buttons {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    .el-button.el-button__jovy.el-button--mini {
      line-height: 30px;
    }
  }
}
</style>