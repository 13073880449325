<template>
    <div v-if="numberOfLine === 1" :class="className">
        {{formatValue()}}
    </div>
    <div v-else-if="numberOfLine === 2" :class="className">
        <span class="fs-14 fw-500 text-secondary">{{ getDate() }}</span><br/>
        <span class="fs-12 fw-400 text-gray-400">{{ getTime() }}</span>
    </div>
</template>
<script>
import moment from 'moment'
export default {
    name: 'type-date',
    props: {
        row: {
            type: Object,
            require: true
        },
        column: {
            type: Object,
            require: true
        },
        className: {
            type: String,
            default: null
        },
        numberOfLine: {
            type: Number,
            default: 1
        }
    },
    methods: {
        formatValue() {
            const format = this.column.format || 'MM/DD/YYYY hh:mm:ss A'
            let value;
            if(Array.isArray(this.column.field_name)) {
                value = []
                this.column.field_name.forEach(f => {
                    let itemValue = this.row[f]
                    if(this.column.relation) itemValue = this.row[this.column.relation][this.column.field_name]
                    if (itemValue) {
                      itemValue = moment(itemValue).format(format)
                      value.push(itemValue)
                    }
                })
                if (value) return value.join(' - ')
                return value
            } else {
                value = this.row[this.column.field_name]
                if(this.column.relation) value = this.row[this.column.relation][this.column.field_name]
                if (value) return moment(value).format(format)
                return ''
            }

        },
        getDate() {
            return this.formatValue().split(' ')[0]
        },
        getTime() {
            return [this.formatValue().split(' ')[1], this.formatValue().split(' ')[2]].join(' ')
        }
    }
}
</script>
