import request from "@/utils/request";

export function getSetting(prefix = null) {
  return request({
    url: "setting",
    method: "get",
    params: {
      prefix: prefix
    }
  });
}

export function getShopTourSetting(name) {
  if (name) {
    return request({
      url: "setting/shop-tour",
      method: "get",
      params: { name }
    })
  }
  return Promise.resolve({ show: false })
}

export function updateSetting(keys = {}) {
  return request({
    url: "setting",
    method: "post",
    data: keys,
    timeout: 0,
  });
}

export function useGlobalSetting(keys = []) {
  if (typeof keys === 'string') keys = [keys]
  if (!Array.isArray(keys)) {
    return Promise.reject(new Error('Invalid setting keys'))
  }
  return request({
    url: "setting/use-global",
    method: "post",
    data: { keys },
  });
}

export function updateScriptSetting(id, keys = {}) {
  return request({
    url: `setting/${id}`,
    method: "put",
    data: keys,
    timeout: 0,
  });
}


export function getTaxClass() {
  return request({
    url: "setting/taxjar-class",
    method: "get"
  });
}

export function getKlaviyoLists(key) {
  return request({
    url: `setting/klaviyo-lists/${key}`,
    method: "get"
  });
}

export function getSendinblueLists(key) {
  return request({
    url: `setting/sendinblue-lists/${key}`,
    method: "get"
  });
}

export function getConfiguration() {
  return request({
    url: 'setting/configuration',
    method: 'get'
  })
}

export function getConfigurationValues(models) {
  return request({
    url: 'setting/configuration',
    method: 'post',
    data: {
      models
    }
  })
}

export function TestSMTPEmail(data) {
  return request({
    url: 'setting/test-smtp-email',
    method: 'post',
    data: data,
    timeout: 0,
  })
}

export function sendTestEmail(email) {
  const time = new Date().toLocaleString('en-us', { timeZoneName: 'short', hour12: false })
  return request({
    url: `setting/send-test-email?email=${email}&time=${encodeURIComponent(time)}`,
    method: 'get',
  })
}

export function CheckTaxJarApiKey(data) {
  return request({
    url: 'setting/check-taxjar-api-key',
    method: 'post',
    data: data,
    timeout: 0,
  })
}

export function connectIOmnic(data) {
  return request({
    url: 'setting/connect-iomnic',
    method: 'post',
    data: data,
    timeout: 0,
  })
}

export function generateSitemap() {
  return request({
    url: 'setting/generate-sitemap',
    method: 'get',
    timeout: 0,
  })
}