import extendJS from './extend'
import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store'
import ElementUI from 'element-ui'
import { isMobile } from 'mobile-device-detect';
import globalDirectives from './global-directives'
import './components/Widgets/index'
import './components/Globals/index'
import './assets/css/index.css'
import './assets/scss/admin-dashboard.scss'
import locale from 'element-ui/lib/locale/lang/en'
import 'tinymce/tinymce.min'
import 'tinymce/themes/silver/theme.min'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
// import 'tinymce/skins/content/default/content.css'
import 'tinymce/plugins/wordcount/plugin.min'
import 'tinymce/plugins/code/plugin.min'
import 'tinymce/plugins/paste/plugin.min'
import 'tinymce/plugins/lists/plugin.min'
import './tinymce-plugins/image'
import 'tinymce/plugins/link/plugin.min'
import 'tinymce/plugins/anchor/plugin.min'
import 'tinymce/plugins/media/plugin.min'
import 'tinymce/plugins/table/plugin.min'
import 'tinymce/plugins/hr/plugin.min'
import 'tinymce/icons/default/icons.min'
import './utils/vee-validate'
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'

/* extend native JS functions */
extendJS()

Vue.use(ElementUI, { locale })
Vue.use(VueTour)

Vue.use(globalDirectives)
Vue.config.productionTip = false
Vue.prototype.$isMobile = isMobile

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
