<template>
  <section class="jovy-alert fs-14 fw-400" :class="[type, customClass]" v-if="content || $slots.body">
    <div class="icon" :class="customIconClass">
      <i class="bi" :class="icons[type]"></i>
    </div>
    <span class="p-2" :class="customContentClass" v-html="content"></span>
    <slot name="body" />
  </section>
</template>

<script>
const ICONS = {
  'info': 'bi-info-circle',
  'error': 'bi-exclamation-triangle',
  'success': 'bi-check2-circle',
  'warning': 'bi-exclamation-circle',
  'note': 'bi-journal-text',
}
export default {
  props: {
    content: { type: String, default: '' },
    type: { type: String, default: 'info' },
    customClass: { type: String, default: '' },
    customContentClass: { type: String, default: '' },
    customIconClass: { type: String, default: '' },
  },
  data() {
    return {
        icons: ICONS,
    }
 },
}
</script>
<style lang="scss" scoped>
  .note {
    color: var(--jv-secondary);
    border: 1px solid var(--jv-gray-300);
    background-color: var(--jv-gray-100);
    .icon {
      background-color: var(--jv-gray-200);
      border-right: 1px solid var(--jv-gray-300);
    }
  }
</style>
