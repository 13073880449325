import { FeatureToggleComponent as FeatureToggle } from 'vue-feature-toggle'

export default {
  components: { FeatureToggle },
  data() {
    return {
      // list all feature names that need to toggle
      // the component should define the list
      featuresToToggle: [],
    }
  },
  computed: {
    featuresConfig() {
      return this.$store.getters.features
    },
  },
  created() {
    this.toggleFeatures()
  },
  methods: {
    // toggle provided features (if any), and the features defined in the component
    toggleFeatures(features = []) {
      if (typeof features === 'string') {
        features = [features]
      }
      if (!Array.isArray(features)) {
        features = []
      }
      features = [...this.featuresToToggle, ...features]

      for (const feature of features) {
        if (feature in this.featuresConfig) {
          // should be 'none', 'old', 'new', or 'all'
          const variant = this.featuresConfig[feature]
          if (variant === 'none') {
            FeatureToggle.visibility(feature, false)
          } else if (variant === 'all') {
            FeatureToggle.visibility(feature, true)
          } else {
            FeatureToggle.visibility(feature, variant, true)
          }
        }
      }
    }
  },
}
