import { getConfiguration } from '@/api/setting'

export default {
  state: {
    base_url: '',
    template_builder_url: '',
    pageTitle: '',
    currentSidebar: null,
    currentHeader: null,
    currentContent: null,
    sidebarHeight: null,
    alerts: [],
    confirmModalItems: [],
    loadingStatus: 0,
    reRender: false,
    mapCustomerGroup: {
      general: 'General',
      'tax-exempt': 'Tax Exempt',
    },
    lastRoute: '',
    routeHistory: [],
    countries: {},
    stateRequired: [],
    customerGroups: [],
    websites: [],
    websiteId: 0,
    frequencies: {
      '1_week': '1 Week',
      '2_week': '2 Weeks',
      '1_month': '1 Month',
      '2_month': '2 Months',
      '3_month': '3 Months',
      '4_month': '4 Months',
    },
    acl: [],
    googleReCaptchaSiteKey: '',
    configuration: [],
    cmsBlocks: {},
    cmsPage: [],
    toolbarWidges: [],
    subtitle: '',
    modules: {},
    locale: {
      languages: [{ code: 'en', label: 'English' }],
      currencies: [{ code: 'USD', label: 'US Dollar' }],
    },
    attributeInfo: [],
    systemName: '',
    orderStates: {},
    invoiceStatuses: {},
    deliveryStatuses: {},
    claimStatuses: {},
    entities: {},
    paymentEvents: {},
    notificationEvents: {},
    breadcrumb: [],
    isNewTheme: false,
    deliveryResetOptions: {},
    orderTags: [],
    cluster_id: '',
    disabled_menus: [],
    disabled_setting_menus: [],
    placeholder_image: '',
    rightSidebarSelected: '',
    features: {},
    paymentProvidersMap: {},
    guid: '',
    success: false,
    parentCategory: null,
    mappedAttributes: [],
    QBO_middleware_client_ID: '',
    QBO_central_callback_URL: '',
    shop_setup: {},
    restrictedRoutes: [],
    isShowSettingSidebar: false,
    menuBubbleCounter: {},
  },
  mutations: {
    SET_MENU_BUBBLE_COUNTER: function (state, value) {
      state.menuBubbleCounter = value
    },
    SET_SHOW_SETTING_SIDEBAR: function (state, isShow) {
      state.isShowSettingSidebar = isShow
    },
    SET_PAGE_TITLE: function (state, title) {
      state.pageTitle = title
    },
    SET_CURRENT_SIDEBAR: function (state, value) {
      state.currentSidebar = value
    },
    SET_CURRENT_HEADER: function (state, value) {
      state.currentHeader = value
    },
    SET_CURRENT_CONTENT: function (state, value) {
      state.currentContent = value
    },
    SET_SIDEBAR_HEIGHT: function (state, value) {
      if (value) {
        state.sidebarHeight = 0
      } else {
        state.sidebarHeight = document.documentElement.scrollHeight
      }
    },
    SHOW_ALERT: function (state, object) {
      if (state.alerts.length === 3) {
        state.alerts.pop()
      }
      state.alerts.unshift(Object.assign({ variant: 'info' }, object))
    },
    DISMISS_ALERT: function (state, index) {
      state.alerts.splice(index, 1)
    },
    ADD_CONFIRM_MODAL_ITEM: function (state, object) {
      state.confirmModalItems.unshift({ ...object, type: object.status })
    },
    REMOVE_CONFIRM_MODAL_ITEM: function (state, index) {
      state.confirmModalItems.splice(index, 1)
    },
    SET_LOADING_STATUS: function (state, status) {
      if (status) {
        state.loadingStatus += 1
      }
      if (!status && state.loadingStatus > 0) {
        state.loadingStatus -= 1
      }
      if (status === 'reset') {
        state.loadingStatus = 0
      }
    },
    TOGGLE_RE_RENDER: function (state) {
      state.reRender = !state.reRender
    },
    SET_LAST_ROUTE: function (state, value) {
      state.lastRoute = value
    },
    PUSH_LAST_ROUTE: function (state, value) {
      state.routeHistory.unshift(value)
      state.routeHistory.length = 10
    },
    SET_FIRST_DATA: function (state, value) {
      state.base_url = value['base_url']
      state.guid = value['guid'] || ''
      state.template_builder_url = value['template_builder_url']
      state.locale = value['locale']
      state.countries = value['country']['countries']
      state.stateRequired = value['country']['stateRequired']
      state.customerGroups = value['customer_groups']
      state.websites = value['websites']
      state.modules = value['modules']
      state.orderStates = value['order_states'] || {}
      state.invoiceStatuses = value['invoice_statuses'] || {}
      state.deliveryStatuses = value['delivery_statuses'] || {}
      state.entities = value['entities'] || {}
      if (Array.isArray(state.websites) && state.websites.length) {
        state.websiteId = state.websites[0]['_id']
      }
      state.acl = value['acl']
      state.attributeInfo = value['attributeInfo']
      state.paymentEvents = value['payment_events'] || {}
      state.claimStatuses = value['claim_statuses'] || {}
      state.notificationEvents = value['notification_events'] || {}
      state.deliveryResetOptions = value['delivery_reset_options'] || {}
      state.orderTags = value['order_tags'] || []
      state.cluster_id = value['cluster_id']
      if (Array.isArray(value['disabled_menus'])) {
        state.disabled_menus.push(...value['disabled_menus'])
      }
      state.disabled_setting_menus = value['disabled_setting_menus'] || []
      state.placeholder_image = value['placeholder_image'] || ''
      state.features = value['features'] || []
      state.paymentProvidersMap = value['payment_providers_map']
      state.mappedAttributes = value['mapped_attributes']
      state.QBO_middleware_client_ID = value['QBO_middleware_client_ID']
      state.QBO_central_callback_URL = value['QBO_central_callback_URL']
      state.shop_setup = value['shop_setup']
    },
    SET_SHOP_SETUP: (state, value) => {
      state.shop_setup = value
    },
    SET_PLACEHOLDER_IMAGE: (state, value) => {
      state.placeholder_image = value
    },
    SET_WEBSITES: (state, value) => {
      state.websites = value
    },
    SET_WEBSITE_ID: (state, value) => {
      state.websiteId = value
    },
    SET_GOOGLE_RE_CAPTCHA_SITE_KEY: (state, value) => {
      state.googleReCaptchaSiteKey = value
    },
    SET_CONFIGURATION: (state, value) => {
      state.configuration = value
    },
    SET_TOOLBAR_WIDGETS: (state, value) => {
      state.toolbarWidges = value
    },
    SET_SUBTITLE: (state, value) => {
      state.subtitle = value
    },
    SET_SYSTEM_NAME: (state, value) => {
      state.systemName = value
    },
    SET_BREADCRUMB: (state, value) => {
      state.breadcrumb = value
    },
    SET_NEW_THEME: (state, value) => {
      state.isNewTheme = value
    },
    SET_DISABLED_MENUS: (state, value) => {
      state.disabled_menus = value
    },
    SET_DISABLED_SETTING_MENUS: (state, value) => {
      state.disabled_setting_menus = value
    },
    SET_RIGHT_SIDEBAR: (state, value) => {
      state.rightSidebarSelected = value
    },
    SET_RESTRICTED_ROUTES: (state, value) => {
      state.restrictedRoutes = value
    },
    SET_SUCCESS: (state, value) => {
      state.success = value
    },
    SET_PARENT_CATEGORY: (state, value) => {
      state.parentCategory = value
    },
  },
  actions: {
    setMenuBubbleCounter: function ({ commit }, isShow) {
      commit('SET_MENU_BUBBLE_COUNTER', isShow)
    },
    setShowSettingSidebar: function ({ commit }, isShow) {
      commit('SET_SHOW_SETTING_SIDEBAR', isShow)
    },
    setPageTitle: function ({ commit }, title) {
      if (typeof title === 'object') {
        if (title.page_title) {
          commit('SET_PAGE_TITLE', title.page_title)
        }
        if (title.subtitle) {
          commit('SET_SUBTITLE', title.subtitle || '')
        }
      } else {
        commit('SET_PAGE_TITLE', title)
      }
    },
    changeCurrentSidebar: function ({ commit }, value) {
      commit('SET_CURRENT_SIDEBAR', value)
    },
    changeCurrentHeader: function ({ commit }, value) {
      commit('SET_CURRENT_HEADER', value)
    },
    changeCurentContent: function ({ commit }, value) {
      commit('SET_CURRENT_CONTENT', value)
    },
    changeSidebarHeight: function ({ commit }, value) {
      commit('SET_SIDEBAR_HEIGHT', value)
    },
    showAlert: function ({ commit }, object) {
      commit('SHOW_ALERT', object)
    },
    dismissAlert: function ({ commit }, index) {
      commit('DISMISS_ALERT', index)
    },
    showConfirmModal: function ({ commit }, object) {
      commit('ADD_CONFIRM_MODAL_ITEM', object)
    },
    closeConfirmModal: function ({ commit }, index) {
      commit('REMOVE_CONFIRM_MODAL_ITEM', index)
    },
    toggleLoading: function ({ commit }, status) {
      commit('SET_LOADING_STATUS', status)
    },
    reRender: function ({ commit }) {
      commit('TOGGLE_RE_RENDER')
    },
    setLastRoute: function ({ commit }, value) {
      commit('SET_LAST_ROUTE', value)
      commit('PUSH_LAST_ROUTE', value)
    },
    setFirstData: function ({ commit }, value) {
      commit('SET_FIRST_DATA', value)
    },
    setWebsites: ({ commit }, value) => {
      commit('SET_WEBSITES', value)
    },
    setWebsiteId: ({ commit }, value) => {
      commit('SET_WEBSITE_ID', value)
    },
    fetchConfiguration: async ({ state, commit }) => {
      // only load setting configuration once
      if (!state.configuration || !state.configuration.length) {
        const _configuration = await getConfiguration()
          .then(({ data }) => data.settings)
          .catch(() => null)

        if (_configuration) {
          commit('SET_CONFIGURATION', _configuration)
        }
      }

      return state.configuration
    },
    getConfiguration: ({ state }, path) => {
      const { configuration } = state
      if (!configuration || !configuration.length) {
        return false
      }

      if (!path) return configuration[0].children[0]
      const [_tab, _section] = path.split('/').filter((name) => name !== '')
      const tab = configuration.find((t) => t['pathName'] === _tab)
      if (tab) {
        if (_section) {
          const section = tab.children.find((s) => s['pathName'] === _section)
          return section || false
        }
        return tab
      }

      return false
    },
    getBlocks: async ({ state }, type) => {},
    setToolbarWidgets: ({ commit }, value) => {
      commit('SET_TOOLBAR_WIDGETS', value)
    },
    setBreadcrumb: ({ commit }, value) => {
      commit('SET_BREADCRUMB', value)
    },
    setNewTheme: ({ commit }, value) => {
      commit('SET_NEW_THEME', value)
    },
    setDisabledMenus: ({ commit, state }, payload) => {
      let items = state.disabled_menus
      if (Array.isArray(payload)) {
        items = payload
      } else if (typeof payload === 'object' && payload.action && payload.items?.length) {
        if (typeof payload.items === 'string') {
          payload.items = [payload.items]
        }
        if (payload.action === 'add') {
          payload.items.forEach((item) => {
            if (!items.includes(item)) {
              items.push(item)
            }
          })
        } else if (payload.action === 'remove') {
          items = items.filter((item) => !payload.items.includes(item))
        }
      }
      commit('SET_DISABLED_MENUS', items)
    },
    setDisabledSettingMenus: ({ commit }, value) => {
      commit('SET_DISABLED_SETTING_MENUS', value)
    },
    setRightSidebarSeleted: ({ commit }, value) => {
      commit('SET_RIGHT_SIDEBAR', value)
    },
    setRestrictedRoutes: ({ commit }, value) => {
      commit('SET_RESTRICTED_ROUTES', value)
    },
  },
}
