const NAMESPACE = process.env.VUE_APP_NAMESPACE || 'hm'

export function getToken () {
  let token = localStorage.getItem(NAMESPACE + '.' + 'token')
  if (token != null) {
    return token
  }
  return null
}

export function setToken (token) {
  if (token) {
    localStorage.setItem(NAMESPACE + '.' + 'token', token)
  }
}

export function getUser () {
  let user = localStorage.getItem(NAMESPACE + '.' + 'user')
  if (user != null) {
    return JSON.parse(user)
  }
  return null
}

export function setUser (user) {
  if (user) {
    localStorage.setItem(NAMESPACE + '.' + 'user', JSON.stringify(user))
  }
}

export function unsetUser () {
  localStorage.removeItem(NAMESPACE + '.' + 'user')
  localStorage.removeItem(NAMESPACE + '.' + 'token')
  localStorage.removeItem(NAMESPACE + '.' + 'auth')
}

export function setWarehouse (value) {
  if (value) {
    localStorage.setItem('warehouse', value)
  }
}

export function setClient (clientId) {
  if (clientId) {
    localStorage.setItem(NAMESPACE + '.' + 'client_id', clientId)
    if (window.HM_ENV) {
      window.HM_ENV.CLIENT_ID = clientId
    }
  }
}

export function getClient () {
  return localStorage.getItem(NAMESPACE + '.' + 'client_id') || HM_ENV.CLIENT_ID || false
}


export function removeToken () {
  localStorage.removeItem(NAMESPACE + '.' + 'token')
}

export function removeClient () {
  localStorage.removeItem(NAMESPACE + '.' + 'client_id')
}

export function getLockPeriod () {
  let period = localStorage.getItem(NAMESPACE + '.' + 'lock_period')
  if (period != null) {
    return period
  }
  return 480 // In minutes = 8hour
}

export function setLockin (period) {
  if (period) {
    localStorage.setItem(NAMESPACE + '.' + 'lock_period', period)
  }
}
export function removeLockin () {
  localStorage.removeItem(NAMESPACE + '.' + 'lock_period')
}

// remove all data by namespace
export function clearAll () {
  for (const key in localStorage) {
    if (key.startsWith(`${NAMESPACE}.`) || ['idleStart'].includes(key)) {
      localStorage.removeItem(key)
    }
  }
}

/* Called before login flow, check and clear client data if needed */
export function clearClientData(clientId) {
  clientId = clientId || getClient()

  if (window.HM_ENV.CLIENT_ID === 'jovyessential') {
    clientId = 'jovyessential'
    clearAll()
  } else {
    // check subdomain to determine the client code
    const matches = window.location.hostname.match(/(\w*(-\w+)?)-?admin(-dev|-qa|-staging)?\.jovy\.(shop|local)/)
    const targetClientId = matches && matches[1]
    if (targetClientId && (targetClientId !== clientId || targetClientId !== window.HM_ENV.CLIENT_ID)) {
      clientId = window.HM_ENV.CLIENT_ID = targetClientId
      clearAll()
    }
  }
  return clientId
}
