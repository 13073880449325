import { snakeCase } from "lodash"
import { getShopTourSetting } from "@/api/setting"

export default {
  data() {
    return {
      shopTourOptions: {
        labels: {
          buttonSkip: 'Skip',
          buttonStop: 'Got it',
        },
        highlight: true,
      },
      autoShowShopTour: true,
      isShowShopTour: false,
    }
  },
  mounted() {
    if (this.autoShowShopTour) {
      this.showShopTour()
    }
  },
  methods: {
    async showShopTour(name, delayMs = 500) {
      if (this.shopTourSteps && this.shopTourSteps.length > 0) {
        name = name || this.shopTourKey || snakeCase(this.$route.name)
        if (name) {
          const { data } = await getShopTourSetting(name)
          if (data && data.show) {
            this.isShowShopTour = true
            setTimeout(
              () => {
                const tour = this.$tours['shopTour']
                if (tour) {
                  tour.start().then(() => {
                    for (const step of this.shopTourSteps) {
                      const target = document.querySelector(step.target)
                      if (target) {
                        target.addEventListener('click', () => tour.stop())
                      }
                    }
                  })
                }
              },
              delayMs
            )
          }
        }
      }
    }
  },
}