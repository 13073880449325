import store from "../store"
import { CLIENT_TYPES } from './constant'

function _getClientFromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem('jovy-client'))
  } catch (error) {
    console.error('Error loading client data from storage:', error)
  }
  return null
}

export function checkClientType(typeKeys, options = {}) {
  if (!typeKeys || !typeKeys.length) return false
  const client = options.client || store.getters['client/client'] || _getClientFromLocalStorage()
  if (!client || !client.type) return false
  if (typeof typeKeys == 'string') {
    typeKeys = [typeKeys.trim()]
  }
  
  const typeCodes = []
  typeKeys.forEach(key => {
    if (key.endsWith('*')) {
      // ex. "marketplace*" would match "marketplace" or "marketplaceseller"
      const prefix = key.replace('*', '')
      Object.keys(CLIENT_TYPES).forEach(k => {
        if (k.startsWith(prefix)) {
          typeCodes.push(CLIENT_TYPES[k].code)
        }
      })
    } else if (key in CLIENT_TYPES) {
      typeCodes.push(CLIENT_TYPES[key].code)
    }
  })
  const bool = typeCodes.includes(client.type)
  /**
   * @param options.isSuperClient (boolean) [optional]
   * Explicit check to see if the client is the "master" or not
   * - if true, only return true if "master" client
   * - if false, only return true if the client type is satisfied and not the "master"
   * - else, return true if the client type is satisfied, regardless it is "master" or not
   */
  if (bool && typeof options?.isSuperClient == 'boolean') {
    return options.isSuperClient === (client.type == CLIENT_TYPES.essential.code && client.code == 'master') || client.type == CLIENT_TYPES.marketplace.code
  }
  return bool
}
