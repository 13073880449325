<template>
  <el-input
    class="el-input__jovy"
    ref="jvInput"
    :value="value"
    v-bind="$attrs"
    :class="[isNumber ? 'is-number' : '', customClass]"
    @input="handleInputEvent($event, 'input')"
    @change="handleInputEvent($event, 'change')"
    :style="customStyle"
  >
    <slot v-if="$slots.prefix" name="prefix" slot="prefix"></slot>
    <slot v-if="$slots.suffix" name="suffix" slot="suffix"></slot>
    <slot></slot>
  </el-input>
</template>
<script>
export default {
  name: 'jv-input',
  props: {
    value: { type: [String, Number] },
    isNumber: { type: Boolean, default: false },
    numberPrecision: { type: Number, default: 16 }, /* 123.45 ➞ precision = 3 */
    numberScale: { type: Number, default: 2 }, /* 123.45 ➞ scale = 2 */
    customClass: { type: String, default: '' },
    numberMax: { type: Number, default: undefined },
    numberMin: { type: Number, default: undefined },
    customStyle: { type: String, default: '' },
  },
  methods: {
    handleInputEvent(val, eventName) {
      if (this.isNumber) {
        if (!isNaN(this.numberMax) && val > this.numberMax) {
          val = this.numberMax
        }
        if (!isNaN(this.numberMin) && val < this.numberMin) {
          val = this.numberMin
        }
        val = this.formatNumber(val)
        if (val === false) {
          return
        }
        if (!isNaN(val) && eventName === 'change' && this.numberScale >= 0) {
          val = Number(val).toFixed(this.numberScale)
        }
      }
      this.$emit(eventName, val)
      if (eventName === 'change' && this.isNumber) {
        this.$nextTick(() => this.$emit('input', val))
      }
    },
    formatNumber(val) {
      val = String(val).replace(/[^-\d.]/g, '').trim()
      if (val === '.') {
        return '0.'
      }
      if (val !== '-') {
        const [integerPart, decimalPart = ''] = val.replace('-', '').split('.')
        if (
          integerPart.length > this.numberPrecision ||
          decimalPart.length > this.numberScale ||
          isNaN(val)
        ) {
          return false
        }
      }
      return val
    }
  },
}
</script>
<style lang="scss" scoped>
.el-input.el-input__jovy {
  background: white;
  width: 100%;
  line-height: 34px;
  &.selected {
    :deep() {
      .el-input__inner {
        border: 1px solid var(--jv-brand-300);
      }
    }
  }
  :deep() {
    .el-input__inner {
      border: 1px solid var(--jv-gray-300);
      border-radius: 4px;
      padding: 7px 12px;
      &:focus {
        border: 1px solid var(--jv-brand-300);
      }
    }
    .el-input__prefix {
      left: 1px !important;
      width: 36px;
      height: 34px;
      background: var(--jv-gray-100);
      top: 1px;
      border-radius: 4px 0 0 4px;
      border-right: 1px solid var(--jv-gray-300);
    }
    .el-input__suffix {
      right: 1px !important;
      top: 1px;
      border-radius: 0 4px 4px 0;
      border-left: 1px solid var(--jv-gray-300);
      width: 36px;
      height: 34px;
    }
    .el-input__clear, .el-input__inner, .el-input__prefix, .el-input__suffix {
      line-height: 34px;
      height: 34px;
    }
    
    @media (min-width: $lg) {
      width: 240px;
    }

  }
  &.is-number {
    :deep() {
      .el-input__inner {
        text-align: right !important;
      }
    }
  }
  &.text-left {
    :deep() {
      .el-input__inner {
        text-align: left !important;
      }
    }
  }
  &.is-disabled {
    :deep(.el-input__inner) {
      background: var(--jv-gray-100);
    }
  }
  &.el-input--prefix {
    :deep(.el-input__inner) {
      padding-left: 46px;
    }
  }
  &.el-input--suffix {
    :deep(.el-input__inner) {
      padding-right: 46px;
    }
  }
  &.el-input--normal {
    background: none;
    :deep() {
      .el-input__inner {
        height: 36px;
      }
      .el-input__clear, .el-input__prefix, .el-input__suffix {
        height: 34px;
      }
    }
  }
  &.el-input--medium {
    line-height: 40px;
    :deep() {
      .el-input__inner {
        line-height: 40px;
        height: 40px;
      }
      .el-input__clear, .el-input__prefix, .el-input__suffix {
        line-height: 38px;
        height: 38px;
      }
    }
  }
  &.el-input--small {
    line-height: 36px;
    :deep() {
      .el-input__inner {
        line-height: 36px;
        height: 36px;
      }
      .el-input__clear, .el-input__prefix, .el-input__suffix {
        line-height: 34px;
        height: 34px;
      }
    }
  }
  &.el-input--mini {
    line-height: 34px;
    :deep() {
      .el-input__inner {
        line-height: 34px;
        height: 34px;
      }
      .el-input__clear, .el-input__prefix, .el-input__suffix {
        line-height: 32px;
        height: 32px;
      }
    }
  }

}
</style>