<template>
  <div class="mb-3" :class="$isMobile && filterType !== 'messages-filter' ? 'mx-3' : ''" @keypress.enter="handleKeypress">
    <div class="wr-filter-widget" :class="[ $isMobile ? 'mobile' : '', filterType ? `${filterType}` : '' ]">
      <div class="wr-scroll-fixed" :class="[ isFixed ? 'filter-fixed': '', filterType === 'messages-filter' ? 'd-none' : '' ]" v-if="!isFilterWidget">
        <div class="d-flex flex-wrap mt-xxl-0 w-100">
          <div class="flex-md-fill ml-0 ml-md-2 w-100">
            <el-button size="medium" class="el-button el-button--primary is-plain w-100" @click="isFilterWidget = true">
              {{ labelShowApplyFilters }}
            </el-button>
          </div>
        </div>
      </div>
      <div
        class="flex-wrap"
        :class="{ 'd-flex align-items-end': !$isMobile, 'filter-fixed': isFixed && isFilterWidget }"
        ref="elFilterWidget"
      >
        <transition name="el-zoom-in-top">
          <div class="flex-md-fill filter-left" v-if="!isShowMore">
            <div class="filter-default d-flex flex-wrap align-items-end" :class="filterType == 'messages-filter' ? 'w-100' : ''">
              <div
                v-for="(item, index) in data.default"
                :key="'default' + index"
                class="mt-3 mt-xxl-0 mr-0 mr-lg-2"
                :class="item.class"
              >
                <div
                  v-if="item.type !== 'hidden'"
                  class="d-flex w-100"
                  pickerOptions  :class="{ 'flex-column': item.positionLabel === 'top' }"
                >
                  <span
                    v-if="!item.hideLabel"
                    class="label flex-grow-0 text-width"
                    :class="{
                      'px-2': item.positionLabel === 'top',
                      'ml-md-2 mr-md-1 pr-1': item.positionLabel != 'top',
                      'mr-auto': item.key === 'info',
                    }"
                    >{{ item.label }}</span
                  >
                  <template v-if="datePickerType.includes(item.type)">
                    <el-date-picker
                      v-model="filters[item.key]"
                      :size="size"
                      :placeholder="'placeholder' in item ? item.placeholder : ''"
                      :type="item.type"
                      :format="item.format"
                      :align="$isMobile ? 'left' : 'center'"
                      :popper-class="`filter-widget ${$isMobile ? 'mobile' : ''}`"
                      :picker-options="datePickerOptions"
                      :rangeSeparator="filters[item.key] ? '-' : ''"
                      :startPlaceholder="item.placeholder"
                      @change="!filters[item.key] && clearInput(item.key) "
                    >
                    </el-date-picker>
                  </template>
                  <template v-if="item.type === 'select'">
                    <el-select
                      filterable
                      v-model="filters[item.key]"
                      :placeholder="'placeholder' in item ? item.placeholder : ''"
                    >
                      <el-option
                        v-if="!item.disableDefault"
                        value=""
                        :label="item.defaultLabel || item.placeholder"
                      ></el-option>
                      <el-option
                        v-for="(val, index) in item.options"
                        :key="'option-state' + index"
                        :value="val._id"
                        :label="(item.showIdAndName && val._id ? val._id + ' - ' : '') + val.name"
                        :default="12"
                      >
                        <div v-if="item.showIdAndName">
                          <strong class="mr-md-2">{{ val._id }}</strong>
                          <span class="float-right">{{ val.name }}</span>
                        </div>
                      </el-option>
                    </el-select>
                  </template>
                  <template v-if="item.type === 'multiselect'">
                    <el-select
                      v-model="filters[item.key]"
                      multiple
                      collapse-tags
                      filterable
                      remote
                      reserve-keyword
                      :placeholder="'placeholder' in item ? item.placeholder : ''"
                      >
                      <el-option
                        v-for="(val, index) in item.options"
                        :key="'option-state' + index"
                        :value="val.value"
                        :label="(item.showValueAndLabel && val.value ? val.value + ' - ' : '') + val.label"
                      >
                      </el-option>
                      <div v-if="item.showValueAndLabel">
                        <strong class="mr-md-3">{{ val.value }}</strong>
                        <span class="float-right">{{ val.label }}</span>
                      </div>
                    </el-select>
                  </template>
                  <template v-if="item.type === 'text' || item.type === 'id'">
                    <el-input
                      class="d-inline-block"
                      v-model="filters[item.key]"
                      :size="size"
                      :placeholder="'placeholder' in item ? item.placeholder : ''"
                      :prefixIcon="item.icon || ''"
                      :clearable="true"
                      @clear="clearInput(item.key.toString())"
                    />
                  </template>
                  <template v-if="item.type === 'checkbox'">
                    <el-checkbox :key="keyCheck" v-model="filters[item.key]" :class="item.class">{{ item.label }}</el-checkbox>
                  </template>
                  <template v-if="item.type === 'popover'">
                    <el-popover :popper-class="item.class" :width="item.width" trigger="hover">
                      <div :class="item.class" v-html="item.html"></div>
                      <i :class="item.icon" slot="reference"></i>
                    </el-popover>
                  </template>
                </div>
              </div>
              <template v-if="buttons">
                <toolbar-buttons :buttons="buttons" :className="$isMobile ? 'mt-3 w-100' : 'mt-3 '" />
              </template>
            </div>
          </div>
        </transition>
        <div class="filter-action ml-md-auto text-right" :class="$isMobile ? 'mt-3 w-100' : 'mt-3'" v-if="buttonData">
          <toolbar-buttons :buttons="buttonData" :className="$isMobile ? 'justify-content-between' : ''" />
        </div>
        <div
            v-for="(item, index) in data.extra"
            :key="'default' + index"
            class="mt-3 mt-xxl-0 mr-0 mr-lg-2"
            :class="item.class"
          >
          <div
            v-if="item.type !== 'hidden'"
            class="d-flex w-100"
            pickerOptions  :class="{ 'flex-column': item.positionLabel === 'top' }"
          >
            <span
              v-if="!item.hideLabel"
              class="label flex-grow-0 text-width"
              :class="{
                'px-2': item.positionLabel === 'top',
                'ml-md-2 mr-md-1 pr-1': item.positionLabel != 'top',
                'mr-auto': item.key === 'info',
              }"
              >{{ item.label }}</span
            >
          </div>
        </div>
      </div>
      <el-collapse-transition>
        <div class="row" v-if="isShowMore">
          <div v-for="(item, index) in mergeFilter" :key="'default' + index" class="col-3">
            <div class="d-flex flex-column mr-md-2">
              <div class="flex-grow-0 mr-md-2 font-weight-bold" :class="size === 'mini' ? 'py-md-1' : 'py-md-2'">
                {{ item.label }}:
              </div>
              <template v-if="item.hasRange">
                <div class="d-flex mb-2">
                  <span class="flex-grow-0 py-md-2" style="width: 80px">From: </span>
                  <component
                    class="d-inline-block"
                    style="width: 100%"
                    :is="mapElement[item.type].el"
                    v-model="filter[item.key + '.from']"
                    :size="size"
                    :placeholder="'placeholder' in item ? item.placeholder : ''"
                    :type="mapElement[item.type].type"
                    :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                    :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                  ></component>
                </div>
                <div class="d-flex">
                  <span class="flex-grow-0 py-md-2" style="width: 80px">To: </span>
                  <component
                    class="d-inline-block"
                    style="width: 100%"
                    :is="mapElement[item.type].el"
                    v-model="filter[item.key + '.to']"
                    :size="size"
                    :placeholder="'placeholder' in item ? item.placeholder : ''"
                    :type="mapElement[item.type].type"
                    :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                    :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                  >
                  </component>
                </div>
              </template>
              <template v-else>
                <component
                  :is="mapElement[item.type].el"
                  style="width: 100%"
                  v-model="filters[item.key]"
                  :size="size"
                  :placeholder="'placeholder' in item ? item.placeholder : ''"
                  :type="mapElement[item.type].type"
                  :format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                  :value-format="item.type === 'datetime' ? 'yyyy/MM/dd' : ''"
                >
                  <template v-if="mapElement[item.type].el === 'el-select'">
                    <template v-if="item.type === 'boolean'">
                      <el-option
                        v-for="(option, index) in mapElement[item.type].child"
                        :key="'option-boolean' + index"
                        :label="option.label"
                        :value="option.value"
                      ></el-option>
                    </template>
                    <template v-if="item.type === 'select'">
                      <template v-for="(item, index) in item.options">
                        <el-option
                          v-if="item.value === undefined"
                          :key="'option' + index"
                          :label="item.label"
                          :value="item.key"
                        ></el-option>
                        <el-option v-else :key="'option' + index" :label="item.label" :value="item.value"></el-option>
                      </template>
                    </template>
                  </template>
                </component>
              </template>
            </div>
          </div>
        </div>
      </el-collapse-transition>
    </div>
  </div>
</template>

<script>
import { setOne, getAll } from '@/utils/app'
import { getOne } from '../../utils/app'

export default {
  props: {
    data: {
      type: Object,
    },
    buttonData: {
      type: Array,
    },
    reload: {
      default: false,
    },
    isShowSearchMore: {
      type: Boolean,
      default: false,
    },
    size: {
      default: 'medium',
    },
    resetShow: {
      type: Boolean,
      default: true,
    },
    CID: {
      type: String,
      default: null,
    },
    customFilterButtons: {
      type: Array,
      default: () => []
    },
    filterType: {
      type: String
    }
  },
  data() {
    return {
      isShowMore: false,
      isSave: false,
      datePickerType: ['daterange', 'date'],
      selectOptionType: ['multiselect', 'select'],
      pickerOptions: {},
      datePickerOptions: {},
      isFixed: false,
      labelShowApplyFilters: 'Apply Filters',
      isFilterWidget: true,
      buttons: [
        {
          label: 'Apply Filters',
          icon: 'el-icon-check',
          type: 'primary',
          plain: true,
          callback: this.handleSearch,
          typeButton: 'search',
          class: this.$isMobile ? 'w-100' : '',
        },
        {
          label: 'Remove Filters',
          icon: 'el-icon-close',
          type: 'danger',
          plain: true,
          callback: this.handleReset,
          typeButton: 'reset',
          hidden: true,
          class: this.$isMobile ? 'w-100' : '',
        },
      ],
      clearFilter: false,
      filters: {},
      heightFilterWidget: 150,
      firstTime: true,
      keyCheck: 1,
    }
  },
  computed: {
    mergeFilter() {
      const filter = this.data
      return filter.default.concat('more' in filter ? filter.more : [])
    },
    WID() {
      return this.CID || this.$route.name
    },
  },
  mounted() {
    if (this.customFilterButtons && this.customFilterButtons.length) {
      Object.assign(this.buttons[0], this.customFilterButtons[0])
      Object.assign(this.buttons[1], this.customFilterButtons[1])
    }
    if (this.$refs.elFilterWidget) this.heightFilterWidget = this.$refs.elFilterWidget.getBoundingClientRect().bottom
    if (this.$isMobile) window.addEventListener('scroll', this.handleScroll)
    const { filters } = getAll(this.WID)
    if (filters) this.filters = filters

    this.data.default.forEach((item) => {
      if (this.datePickerType.includes(item.type)) {
        if (item.disableFutureDate) {
          this.datePickerOptions = {
            disabledDate(time) {
              return time.getTime() > Date.now();
            },
          }
        }
        if (item.defaultDate) {
          this.filters.toDate = (new Date()).toISOString() // Default value for the to Date.
        }
      }
      if (this.selectOptionType.includes(item.type)) {
        if (item.defaultSelectedValue) {
          this.filters.week = item.defaultSelectedValue // Set Default value
        }
      }
    })

    let now = new Date();
    // Set the time for 'backdate' to 00:00:00
    const backdate = new Date(now);
    backdate.setDate(now.getDate() - 30);
    backdate.setHours(0, 0, 0, 0);

    // Create a separate 'to' date and set its time to 11:59:59
    const toDate = new Date(now);
    toDate.setHours(23, 59, 59, 999);

    this.filters.customDate = [backdate.toISOString(), toDate.toISOString()];
    this.clearFilter = filters && Object.keys(filters).length > 0
    this.handleSearch(false)
  },
  destroyed() {
    if (this.$isMobile) window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleFilter(filter = null) {
      if (filter !== null) {
        this.firstTime = true;
        this.filters = Object.assign({}, this.filters, filter);
        this.handleSearch(true);
      }
    },
    handleSearch(resetPagination = true) {
      const filters = this.getFilterData()
      const btnApply = Object.keys(filters).length > 0
      this.updateButtonFilter((this.firstTime && !btnApply), btnApply)
      this.$emit('handleSearch', { filters, resetPagination })
    },
    handleKeypress($event) {
      this.handleSearch()
    },
    handleScroll() {
      let scroll = window.scrollY
      if (scroll > this.heightFilterWidget) {
        this.isFixed = true
        this.isFilterWidget = false
      } else {
        this.isFixed = false
        this.isFilterWidget = true
      }
    },
    handleReset() {
      this.$emit('onreset')
      this.filters = {}
      this.keyCheck = Math.random()
      this.handleSearch()
    },
    updateButtonFilter(isWatch = false, btnApply = true) {
      this.buttons.forEach((f) => {
        if (f.typeButton === 'reset') f.hidden = isWatch || (!isWatch && !btnApply)
        if (f.typeButton === 'search') f.hidden = !isWatch && btnApply
      })
    },
    getFilterData() {
      let filterData = {}
      this.mergeFilter.forEach((f) => {
        if (this.filters[f.key]) {
          filterData[f.key] = this.filters[f.key]
          if (f.type === 'text') {
            if (Array.isArray(f.key) && f.key.length) {
              filterData['$or'] = []
              f.key.forEach((key) => {
                const query = {}
                query[key] = { $regex: this.filters[f.key], $options: 'i' }
                filterData['$or'].push(query)
              })
              delete filterData[f.key]
            } else filterData[f.key] = { $regex: this.filters[f.key], $options: 'i' }
          }
          if (f.type === 'daterange') {
            const start = (new Date (this.filters[f.key][0])).getTime()
            // const addedTime = 24*60*60*1000 - 1 // 1 day
            // const end = (new Date(this.filters[f.key][1])).getTime() + addedTime
            const end = (new Date(this.filters[f.key][1])).getTime()
            filterData[f.key] = {
              $gte: start,
              $lte: end

            }
          }
        }
      })
      /** delete filter items with 'field' properties */
      const filterByFields = this.mergeFilter.filter((f) => f.field)
      if (Array.isArray(filterByFields) && filterByFields.length) {
        filterByFields.forEach((f) => {
          if (this.filters[f.key]) {
            if (f.type === 'text' && this.filters[f.field]) {
              filterData[this.filters[f.field]] = { $regex: this.filters[f.key], $options: 'i' }
              delete filterData[f.field]
              delete filterData[f.key]
            }
          }
        })
      }
      setOne(this.WID, 'filters', this.filters)
      return filterData
    },
    clearInput(key){
      let filters = getOne(this.WID, 'filters')
      if(filters[key]) {
        delete(this.filters, key)
        filters = this.getFilterData()
        this.$emit('handleSearch', { filters, resetPagination: true })
      }
    },
  },
  watch: {
    filters: {
      handler() {
        if(!this.firstTime) {
          this.updateButtonFilter(true)
        }
        this.firstTime = false
      },
      deep: true
    },
  },
}
</script>

<style lang="scss" scoped>
.text-width {
  min-width: max-content;
}
.filter-left {
  @media (max-width: var(--breakpoint-md)) {
    width: 100%;
  }
}
.w-240px {
  width: 240px !important;
}
.wr-filter-widget {
  background: #f2f2f2;
  padding: 0 10px 15px;
  color: var(--gray);
  font-size: 14px;
  border: 1px solid var(--light);
  border-radius: 4px;
  &.messages-filter {
    background: inherit;
    padding: 0;
    border: 0;
  }
  &.mobile {
    padding: 0 !important;
    border: none !important;
    background: #fff;
  }
  .filter-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    padding: 10px !important;
    background: #fff;
    animation: slideDown 0.2s linear;
    transform-origin: top;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  :deep().el-input__inner {
    height: 36px;
    line-height: 36px;
    border-color: var(--input-border);
    &:hover,
    &:focus {
      border-color: var(--primary);
    }
  }

  :deep().el-date-editor .el-range__close-icon {
    order: 1;
  }
  :deep().el-date-editor .el-range__icon {
    order: 2;
  }
  :deep().el-date-editor .el-range-input {
    text-align: right;
  }
  :deep().el-date-editor .el-range-separator {
    padding-right: 1em !important;
  }

  :deep().el-date-editor input:nth-child(2).el-range-input {
    text-align: left;
  }

  .no-m-bottom {
    .el-form-item {
      margin-bottom: 0 !important;

      label {
        margin-bottom: 0 !important;
      }
    }
  }

  .filter-action {
    .el-button {
      margin-bottom: 2px;
    }
  }
  .w-228 {
    width: 228px !important;
    input {
      width: 228px !important;
    }
  }
  .w-171 {
    width: 171px !important;
    input {
      width: 171px;
    }
  }
  .w-210 {
    width: 210px !important;
    input {
      width: 210px !important;
    }
    .el-range-editor {
      width: 210px !important;
      input {
        width: 70px !important;
      }
    }
  }
  .w-100 {
    input,
    .el-input,
    .el-select,
    .el-date-editor {
      width: 100% !important;
    }
  }
  .w-300 {
    width: 300px !important;
    input {
      width: 300px !important;
    }
  }
  .label {
    color: var(--color);
    line-height: 22px;
  }
  :deep().el-checkbox__input {
    margin-bottom: 2px;
  }
}
.filter-widget {
  max-width: 100vw;
  left: 0;
  &.el-date-range-picker {
    .el-picker-panel__body {
      max-width: 100vw;
      min-width: auto;
      .el-date-range-picker__content {
        width: 100%;
      }
    }
  }
  &.mobile {
    width: 330px !important;
    top: 200px !important;
    left: 16px !important;

    .el-picker-panel__body-wrapper {
      width: 100%;
      .el-picker-panel__body {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: auto;
        .el-picker-panel__content {
          width: 100%;
        }
      }
    }
  }
}
@keyframes slideDown {
  from {
    transform: scale(1, 0);
  }
  to {
    transform: scale(1, 1);
  }
}
.filter-widget {
  &.mobile {
  width: 330px !important;
  top: 200px !important;
  left: 16px !important;

  .el-picker-panel__body-wrapper {
    width: 100%;
    .el-picker-panel__body {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: auto;
      .el-picker-panel__content {
        width: 100%;
      }
    }
  }
}
}

.el-tag {
 max-width: 80% !important;
}
</style>
