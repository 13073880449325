<template>
  <div>
    <modal-widget v-for="(item, index) of confirmModalItems" :key="index "
        :typeDialog="item.type"
        :message="item.message"
        dialogClass="dialog-md"
        visible-sync
        @closeModal="handleCloseConfirmModal(index)"
      >
			<div slot="footer" :class="getCustomFooterClass(item)">
        <jv-button v-if="item.cancelAction" type="default" plain @click="handleCloseConfirmModal(index, item)">Close</jv-button>
        <jv-button v-if="item.confirmAction" type="primary" @click="handleConfirm(index, item)">{{ item.confirmAction.label }}</jv-button>
      </div>
		</modal-widget>
  </div>
</template>

<script>
export default {
  computed: {
    confirmModalItems: function() {
      return this.$store.getters.confirmModalItems
    },
  },
  methods: {
    getCustomFooterClass(item) {
      const customFooterClass = []
      if (item.confirmAction && !item.cancelAction) {
        customFooterClass.push('justify-content-end')
      }
      return customFooterClass
    },
    handleCloseConfirmModal(index, item) {
      this.$store.dispatch('closeConfirmModal', index)
      if (item && item.cancelAction && item.cancelAction.type === 'redirect' && item.cancelAction.target) {
        this.$router.push(item.cancelAction.target)
      }
    },
    handleConfirm(index, item) {
      if (item && item.confirmAction && item.confirmAction.type === 'refresh') {
        this.$store.dispatch('closeConfirmModal', index)
        this.$store.dispatch('reRender')
      }
    }
  },
}
</script>