export default [
  {
    path: 'messages',
    component: () => import('@/components/Views/Conversations/Messages/Main'),
    name: 'messages_dashboard',
    meta: {
      title: 'Messages',
    },
  },
]
