<template>
  <el-radio-group ref="elRadioGroup" :value="value" class="jv-radio-group" @input="handleChange">
    <slot v-if="$slots.default"></slot>
    <template v-if="options && options.length">
      <el-radio-button v-for="(option, index) of options" :key="index" :label="option.value" class="jv-radio-button">
        <span v-if="useHtml && option.titleHtml" v-html="option.titleHtml" :class="option.cssClass || ''"></span>
        <span v-else :class="['jv-radio-button--title', option.cssClass || '']">{{ option.title || option.titleHtml || `Option ${index}` }}</span>
      </el-radio-button>
    </template>
  </el-radio-group>
</template>
<script>
export default {
  name: 'JvToggleButton',
  props: {
    /* The value for v-model */
    value: { type: null },
    useHtml: { type: Boolean, default: false },
    /**
     * @prop options items data
     * Sample: [
     *  { value, title | titleHtml, cssClass }
     * ]
     */
    options: { type: Array, default: () => ([]) },
  },
  mounted () {
    // event proxy
    Object.keys(this.$listeners).forEach(eventName => {
      this.$refs.elRadioGroup.$on(eventName, (...args) => this.$emit(eventName, ...args))
    })
  },
  methods: {
    handleChange ($event) {
      this.$emit('input', $event)
    }
  }
}
</script>
<style lang="scss" scoped>
.jv-radio-group {
  vertical-align: top;
  margin-right: 12px;
  :deep(.el-radio-button) {
    margin-bottom: 0;
    .el-radio-button__inner {
      color: var(--jv-tertiary);
      border-color: var(--jv-gray-300);
      font-weight: 500;
      padding: 10px 15px;
    }
    &.is-active {
      .el-radio-button__inner {
        color: var(--jv-brand-500);
        border-color: var(--jv-brand-300);
        box-shadow: -1px 0 0 0 var(--jv-brand-300);
        background-color: var(--jv-brand-100);
      }
    }
    &:hover {
      .el-radio-button__inner {  
        color: var(--jv-green-400);
        border-color: var(--jv-green-300);
        box-shadow: -1px 0 0 0 var(--jv-green-300);
        background-color: var(--jv-green-100);
      }
    }
  }
}
</style>