<template>
  <div :class="['jv-collapse-box', { expanded: isExpanded }]">
    <div class="header">
      <div class="header_left">
        <div v-if="hasIcon" class="icon-box">
          <slot v-if="hasIcon" name="icon">
            <i v-if="typeof icon === 'string'" :class="['icon fs-20 bi cursor-pointer', `bi-${icon}`, iconCustomClass]"></i>
          </slot>
        </div>
      </div>
      <div class="header-right-container">
        <div class="d-flex align-items-center">
          <slot name="before-title"></slot>
          <div class="title px-12"><span v-html="title"></span><slot name="inside-title"></slot></div>
        </div>
        <div class="header_right">
          <div class="buttons mr-12">
            <slot name="buttons" class="__buttons"></slot>
          </div>
          <!-- TODO - There are 2 various styles for this toggle-button, depends on if we have addition buttons or not -->
          <span class="toggle-button" v-if="showToggleButton" @click="toggleCollapse">
            <i class="bi bi-chevron-down"></i>
          </span>
        </div>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="isExpanded" class="body">
        <slot name="default"></slot>
      </div>
    </el-collapse-transition>
  </div>
</template>
<script lang="js">
export default {
  props: {
    icon: {
      type: [String, Boolean],
      default: false,
    },
    title: {
      type: String
    },
    iconCustomClass: {
      type: String,
      default: ''
    },
    /** For default expanded state */
    expanded: {
      type: Boolean,
      default: false,
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    'expanded': function () {
      this.isExpanded = this.expanded
    }
  },
  data() {
    return {
      isExpanded: this.expanded,
    }
  },
  computed: {
    hasIcon() {
      return this.icon !== false
    }
  },
  methods: {
    toggleCollapse() {
      this.isExpanded = !this.isExpanded
      this.$emit('expanded', this.isExpanded)
    }
  },
}
</script>
<style scoped lang="scss">
.jv-collapse-box {
  .header {
    display: flex;
    align-items: center;
    color: var(--jv-gray-500);
    border: solid 1px var(--jv-gray-300);
    border-radius: 4px;
    background: #FFFFFF;
    overflow: hidden;
    transition: all 0.5s;
    max-height: 60px;
    &:hover .header_right > .toggle-button {
      color: var(--jv-brand-500);
      background: var(--jv-brand-100);
    }
    .header_left {
      display: flex;
      align-items: center;
      .icon-box {
        width: 48px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: solid 1px var(--jv-gray-300);
      }
    }

    .header-right-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 20em;
        font-size: 1rem;
      }
      .header_right {
        display: flex;
        align-items: center;
        .toggle-button {
          width: 44px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: solid 1px var(--jv-gray-300);
          transition: all 0.25s;
          cursor: pointer;
          .bi {
            transition: transform 0.25s ease-out;
          }
        }
      }
      & div:nth-child(1) {
        gap: 12px;
      }
    }
    @media(max-width: $sm) {
        max-height: 95px !important;
        .header-right-container {
          flex-direction: column;
          align-items: start;
          padding: 12px;
          gap: 12px;
          max-height: 95px;
          .title {
            padding-left: 0px !important;
          }
          .header_right {
            padding-left: 5px;
          }
        }
        & .icon-box {
          height: 90px !important;
        }

        & .buttons {
          max-height: 30px !important
        }
        & .toggle-button {
          max-height: 30px !important;
        }
      }
    }

  .body {
    margin: 0px 4px 20px;
    padding: 12px;
    border: solid 1px var(--jv-gray-300);
    border-top: none;
    border-radius: 0 0 4px 4px;
    background: #FFFFFF;
    opacity: 0.5;
  }

  &.expanded {
    .header {
      color: var(--jv-gray-500);
      border-color: var(--jv-gray-300);
      box-shadow: 0 2px 4px var(--jv-gray-300);
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12); 
      background: var(--jv-gray-100);
      .icon-box {
        border-color: var(--jv-gray-300);
        background: var(--jv-gray-200);
      }
      .header_right .toggle-button {
        border-color: var(--jv-gray-300);
        background: var(--jv-gray-200);
      }
    }
    .toggle-button .bi {
      transform: rotate(-180deg);
    }
    .body {
      box-shadow: 0 2px 4px var(--jv-gray-300);
      opacity: 1;
    }
  }
  &.success {
    .header {
      background: var(--jv-green-200);
      border-color: var(--jv-green-300);
      color: var(--jv-green-500);
    }
  }
  &.warning {
    .header {
      background: var(--jv-yellow-200);
      border-color: var(--jv-yellow-300);
      color: var(--jv-yellow-500);
    }
    &:hover .header-right-container .header_right > .toggle-button {
      color: var(--jv-yellow-500);
      background: var(--jv-yellow-200);
    }
    .header_left .icon-box, .header-right-container .header_right .toggle-button {
      background: var(--jv-yellow-200);
      border-color: var(--jv-yellow-300);
    }
  }
  &.disabled-collapse {
    .header {
      color: var(--jv-gray-500);
      border-color: var(--jv-gray-300);
      background: #FFFFFF;
      &:hover .header-right-container .header_right > .toggle-button {
        color: var(--jv-gray-500);
        background: var(--jv-gray-200);
      }
      .header_left .icon-box, .header-right-container .header_right .toggle-button {
        background: var(--jv-gray-200);
        border-color: var(--jv-gray-300);
      }
    }
    &.expanded {
      .header {
        color: var(--jv-gray-500);
        background: var(--jv-gray-100);
        border-color: var(--jv-gray-300);
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
        &:hover .header-right-container .header_right > .toggle-button {
          color: var(--jv-gray-500);
          background: var(--jv-gray-200);
        }
        .header_left .icon-box, .header-right-container .header_right .toggle-button {
          background: var(--jv-gray-200);
          color: var(--jv-gray-500);
          border-color: var(--jv-gray-300);
        }
      }
    }
  }
  &.is-error {
    .header, .body, .header_left .icon-box, .header_right .toggle-button {
      border-color: var(--jv-danger) !important;
    }
  }
  &.header-44 {
    .header {
      height: 44px;
      .header_left {
        .icon-box {
          width: 44px;
          height: 44px;
        }
      }
      .header-right-container {
        .header_right {
          .toggle-button {
            width: 44px;
            height: 44px;
          }
        }
      }
    }
  }
  &.header-56 {
    .header {
      height: 56px;
      .header_left {
        .icon-box {
          width: 56px;
          height: 56px;
        }
      }
      .header-right-container {
        .header_right {
          .toggle-button {
            width: 56px;
            height: 56px;
          }
        }
      }
    }
  }
}
.parent-widget.jv-collapse-box .body {
  background: #FAFBFB;
}

.parent-widget.jv-collapse-box {
  @media (max-width: $sm) {
    .header:first-child .header-right-container {
      .header_right {
        & > .buttons {
          margin-right: 0 !important;
          .fa-trash {
            padding-right: 12px;
          }
        }
      }
    }
  }
}

.child-widget.jv-collapse-box .body {
  background: #F8FAFC;
}

.child-widget.jv-collapse-box {
  .toggle-button .bi {
      transform: rotate(0deg);
    }
  &.expanded {
    .toggle-button .bi {
      transform: rotate(-180deg);
    }
  }
  .header {
    .header-right-container {
      @media(max-width: $sm) {
        flex-direction: row !important;
        .preview-img-container {
          & img:first-child {
            width: 30px;
            height: 30px;
            object-fit: cover;
          }
        }
      }
    }
  }
}


</style>
