<template>
  <jv-button class="button mt-2 ml-0" @click="$emit('click', $event)">
    <template v-if="completed">
      <i class="bi bi-arrow-counterclockwise fs-16"></i> Mark as Incomplete
    </template>
    <template v-else>
      <i class="bi bi-check2-circle fs-16 lh-16"></i> Mark as Complete
    </template>
  </jv-button>
</template>
<script>
export default {
  props: {
    completed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  font-weight: 600;
  :deep(span) {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    line-height: 16px;
  }
}
</style>