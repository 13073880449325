export default [
  {
    path: 'setting',
    component: () => import('@/components/Views/System/Setting'),
    name: 'system_setting',
    meta: {
      title: 'Settings'
    },
    children: [
      {
        path: '*',
        name: 'setting_paths',
        component: () => import('@/components/Views/System/Setting/ChildSetting'),
        meta: {
          title: 'Settings'
        }
      },
    ]
  },
  {
    path: 'redirect',
    component: () => import('@/components/Views/System/Redirect/Main'),
    name: 'system_redirect_manage',
    meta: {
      title: 'Redirect'
    }
  },
  {
    path: 'redirect/new',
    component: () => import('@/components/Views/System/Redirect/Edit'),
    name: 'system_redirect_new',
    meta: {
      title: 'Add New Redirect'
    }
  },
  {
    path: 'redirect/:id',
    component: () => import('@/components/Views/System/Redirect/Edit'),
    name: 'system_redirect_edit',
    meta: {
      title: 'Edit Redirect',
      type: 'edit'
    }
  },
  {
    path: 'file_manager/_beta',
    component: () => import('@/components/Views/System/FileManager/Main'),
    name: 'system_file_manager',
    meta: {
      title: 'File Manager',
    }
  },
]
