/**
 * Note for field "clientType":
 * - "all" or omitted: show for all clients
 * - "full": only show for full client (JFC)
 * - "essential": only show for essential client (JEC)
 */
export default [
  {
    name: 'Shop Setup',
    url: 'settings/shop-setup',
    children: [],
    clientType: ['essential', 'marketplaceseller'],
  },
  {
    name: 'General',
    url: 'settings/general',
    children: [
      {
        name: 'Site Settings',
        url: 'site-settings',
      },
      {
        name: 'Sitemap',
        url: 'site-map',
        clientType: 'full',
      },
      {
        name: 'Robots.txt',
        url: 'robots-txt',
        clientType: 'full',
      },
      {
        name: 'Search Index',
        url: 'search',
        clientType: 'full',
      },
      {
        name: 'Login Options',
        url: 'login-options',
        clientType: 'full',
      },
      {
        name: 'Job Scheduler',
        url: 'job-scheduler',
        clientType: 'full',
      },
      {
        name: 'Site Redirects',
        url: 'site-redirects',
        clientType: 'full',
      },
      {
        name: 'Messaging',
        url: 'messaging',
      },
      {
        name: 'Password Protection',
        url: 'password-protection',
        clientType: 'full',
      },
    ]
  },
  {
    name: 'Notifications',
    url: 'settings/notifications',
    children: [
      {
        name: 'Email Settings',
        url: 'email-settings',
      },
      {
        name: 'Admin Notifications',
        url: 'admin-notifications',
        clientType: 'full',
      },
      {
        name: 'Customer Notifications',
        url: 'customer-notifications',
        clientType: 'full',
      },
      {
        name: 'PDF Quote Templates',
        url: 'pdf-quote-templates',
        clientType: 'full',
      },
      // moved to integration > novita
      // {
      //   name: 'Opt-In',
      //   url: 'opt-in',
      // },
    ]
  },
  {
    name: 'Orders',
    url: 'settings/orders',
    children: [
      {
        name: 'Payment Providers',
        url: 'payment-providers',
      },
      {
        name: 'Order Status Manager',
        url: 'order-status-manager',
        clientType: 'full',
      },
      {
        name: 'Order Rules',
        url: 'order-rules',
        clientType: 'full',
      },
      {
        name: 'Fraud Rules',
        url: 'fraud-rules',
        clientType: 'full',
      },
      {
        name: 'Order Defaults',
        url: 'order-defaults',
      },
      {
        name: 'Shopping List',
        url: 'shopping-list',
        clientType: 'full',
      },
      {
        name: 'Save for Later',
        url: 'save-for-later',
      },
      {
        name: 'Order Attributes',
        url: 'order-attributes',
        clientType: 'full',
      },
      {
        name: 'PDF Invoice Templates',
        url: 'pdf-invoice-templates',
        clientType: 'full',
      },
    ]
  },

  {
    name: 'Tax',
    url: 'settings/tax',
    children: [
      // {
      //   name: 'Tax Calculation Method',
      //   url: 'tax-calulation-method',
      //   clientType: 'full',
      // },
      {
        name: 'Tax Rules',
        url: 'tax-rules',
        clientType: 'full',
      },
      {
        name: 'Tax Categories',
        url: 'tax-categories',
        clientType: 'full',
      },
      {
        name: 'Tax Rates',
        url: 'tax-rates',
      },
    ]
  },
  {
    name: 'Shipping',
    url: 'settings/shipping',
    children: [
      {
        name: 'Rate Groups',
        url: 'rate-groups',
      },
      {
        name: 'Package Types',
        url: 'package-types',
      },
      {
        name: 'Shipping Days',
        url: 'shipping-days',
        clientType: 'full',
      },
      {
        name: 'Shipping Options',
        url: 'shipping-options',
      },
      {
        name: 'Shipping Labels',
        url: 'shipping-labels',
      },
      {
        name: 'Delivery Estimates',
        url: 'delivery-estimates',
      },
      {
        name: 'Shipping Location',
        url: 'shipping-location',
      },
      {
        name: 'Carrier Management',
        url: 'carrier-management',
        clientType: 'full',
      },
      {
        name: 'Packing Slip Template',
        url: 'packing-slip-template',
        clientType: 'full',
      },
    ]
  },
  {
    name: 'Products',
    url: 'settings/products',
    children: [
      {
        name: 'Product Attributes',
        url: 'product-attributes',
        clientType: 'full',
      },
      {
        name: 'Product Attribute Presets',
        url: 'product-attribute-presets',
        clientType: 'full',
      },
      {
        name: 'Product Types',
        url: 'product-types',
        clientType: 'full',
      },
      {
        name: 'Variant Templates',
        url: 'variant-templates',
      },
      {
        name: 'Options',
        url: 'options',
      },
      {
        name: 'Option Sets',
        url: 'option-sets',
      },
      {
        name: 'Product Customizer',
        url: 'product-customizer-template-list'
      },
      {
        name: 'MAP Setting',
        url: 'map-setting',
        clientType: 'full',
      },
      {
        name: 'Inventory Defaults',
        url: 'inventory-defaults',
      },
      {
        name: 'Product Reviews',
        url: 'product-reviews',
      },
      {
        name: 'API Logs',
        url: 'api-log',
        clientType: 'full',
      },
      {
        name: 'Placeholder Image',
        url: 'placeholder-image',
        clientType: 'full',
      },
      {
        name: 'SKU Defaults',
        url: 'sku-defaults',
      },
    ]
  },
  {
    name: 'Customers',
    url: 'settings/customers',
    children: [
      {
        name: 'Customer Attributes',
        url: 'customer-attributes'
      }
    ],
    clientType: 'full',
  },
  {
    name: 'Categories',
    url: 'settings/categories',
    children: [
      {
        name: 'Category Attributes',
        url: 'category-attributes',
      },
      {
        name: 'Category Attribute Presets',
        url: 'category-attribute-presets',
      },
      {
        name: 'Category Types',
        url: 'category-types',
      },
    ],
    clientType: 'full',
  },
  {
    name: 'Content',
    url: 'settings/content',
    children: [
      {
        name: 'Banner Generator',
        url: 'banner-generator',
        clientType: 'full',
      },
      {
        name: 'Page Attributes',
        url: 'page-attributes',
        clientType: 'full',
      },
      {
        name: 'Page Attribute Presets',
        url: 'page-attribute-presets',
        clientType: 'full',
      },
      {
        name: 'Page Types',
        url: 'page-types',
        clientType: 'full',
      },
      {
        name: 'Page Mapping',
        url: 'page-mapping',
        clientType: 'full',
      },
      /**
       *  Note:additional scripts moved to content>header and content>footer
       * */
      // {
      //   name: 'Additional Scripts',
      //   url: 'additional-scripts',
      // },
      {
        name: 'Global Metatags',
        url: 'global-metatags',
        clientType: 'full',
      },
      {
        name: 'Site Design',
        url: 'site-design',
      },
      {
        name: 'Header',
        url: 'headers',
        clientType: 'essential',
      },
      {
        name: 'Footer',
        url: 'footer',
        clientType: 'essential',
      },
      {
        name: 'Site Messaging',
        url: 'site-messaging',
        clientType: 'full',
      },
      {
        name: 'Store Terms and Privacy',
        url: 'term-and-privacy',
        clientType: 'essential',
      },
    ]
  },
  {
    name: 'Integration',
    url: 'settings/integration',
    children: [
      {
        name: 'Google',
        url: 'google',
      },
      {
        name: 'iOmniC',
        url: 'etsy'
      },
      {
        name: 'Etsy',
        url: 'etsy-connector',
        featureToggle: 'new',
        toggleKey: 'ETSY_CONNECTOR',
        clientType: 'full',
      },
      {
        name: 'Novita',
        url: 'novita',
        clientType: 'full',
      },
      {
        name: 'QuickBooks',
        url: 'quickbooks',
        featureToggle: 'new',
        toggleKey: 'QUICKBOOKS_CONNECTOR',
        clientType: 'full',
      },
      {
        name: 'QuickBooks Queue',
        url: 'quick-books-queue',
        clientType: 'full',
      },
      {
        name: 'QuickBooks Log',
        url: 'quick-books-log',
        clientType: 'full',
      },
      {
        name: 'Klaviyo',
        url: 'klaviyo',
        clientType: 'full',
      },
    ]
  },
  {
    name: 'Permissions',
    url: 'settings/permissions',
    children: [
      {
        name: 'Users',
        url: 'users',
      },
      {
        name: 'User Roles',
        url: 'user-roles',
        clientType: 'full',
      },
      {
        name: 'User Activity',
        url: 'user-activity',
        clientType: 'full',
      },
      {
        name: 'User Invites',
        url: 'user-invites',
      }
    ]
  }
]
