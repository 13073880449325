import Vue from 'vue'

import Button from '@/components/Globals/Button.vue'
import Textarea from '@/components/Globals/Textarea'
import Checkbox from '@/components/Globals/Checkbox'
import Input from '@/components/Globals/Input'
import Select from '@/components/Globals/Select'
import InputNumber from '@/components/Globals/InputNumber'
import Radio from '@/components/Globals/Radio'
import JvCollapseBox from '@/components/Globals/JvCollapseBox.vue'
import Table from '@/components/Globals/Table'
import JvPopConfirm from '@/components/Globals/JvPopConfirm.vue'
import JvToggleButton from '@/components/Globals/JvToggleButton.vue'
import ConfirmModal from '@/components/Globals/ConfirmModal.vue'

Vue.component('jv-button', Button)
Vue.component('jv-textarea', Textarea)
Vue.component('jv-checkbox', Checkbox)
Vue.component('jv-input', Input)
Vue.component('jv-select', Select)
Vue.component('jv-input-number', InputNumber)
Vue.component('jv-radio', Radio)
Vue.component('jv-collapse-box', JvCollapseBox)
Vue.component('jv-table', Table)
Vue.component('jv-pop-confirm', JvPopConfirm)
Vue.component('jv-toggle-button', JvToggleButton)
Vue.component('confirm-modal', ConfirmModal)
