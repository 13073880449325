import request from '@/utils/request'

/* USER */

export function lock () {
  return request({
    url: '/user/lock-session',
    method: 'get'
  })
}
export function unlock (password = '') {
  return request({
    url: '/user/unlock-session',
    method: 'post',
    data: {
      password
    }
  })
}
export function saveUser (params) {
  return request({
    url: `/user`,
    method: 'post',
    data: params
  })
}

export function getUsers (params = {}) {
  return request({
    url: '/user/collection',
    method: 'post',
    data: params
  })
}

export function getUser (id) {
  return request({
    url: `/user/${id}`,
    method: 'get',
  })
}

export function deleteUser (id) {
  return request({
    url: `/user/${id}`,
    method: 'delete'
  })
}

export function checkUserEmail(email) {
  return request({
    url: '/user/check-email',
    method: 'get',
    params: { email }
  })
}

export function getHash() {
  return request({
    url: '/user/hash',
    method: 'get',
  })
}

export function getSalesReps(params) {
  return request({
    url: '/user/sales-reps',
    method: 'post',
    data: params
  })
}

/* ROLE */
export function createRole (params) {
  return request({
    url: `/user/role`,
    method: 'post',
    data: params
  })
}

export function updateRole (id, params = {}) {
  return request({
    url: `/user/role/` + id,
    method: 'put',
    data: params
  })
}

export function getRoles (params = {}) {
  return request({
    url: '/user/role/collection',
    method: 'post',
    params: params
  })
}

export function getAllRoles () {
  return request({
    url: '/user/role/collection/all',
    method: 'get',
  })
}

export function getRole (id) {
  return request({
    url: `/user/role/${id}`,
    method: 'get',
  })
}

export function getACL () {
  return request({
    url: '/user/role/acl',
    method: 'get'
  })
}

export function deleteRole (id) {
  return request({
    url: `/user/role/${id}`,
    method: 'delete'
  })
}

/* USER ACTIVITY */
export function getActivities (params = {}) {
  return request({
    url: 'user/activity',
    method: 'post',
    data: params
  })
}

/*

/* USER GRID INVENTORY LOG */
export function getUserGrid (grid_name) {
  return request({
    url: `user/grid/${grid_name}`,
    method: 'get'
  })
}

export function saveUserGrid (params = {}) {
  return request({
    url: 'user/grid',
    method: 'post',
    data: params
  })
}

export function syncUsersInIDP (params = {}) {
  return request({
    url: 'user/sync-users-idp',
    method: 'post',
    data: params
  })
}

export function verifyUserEmail (params = {}) {
  return request({
    url: 'user/verify-email',
    method: 'post',
    data: params
  })
}
