<template>
  <div v-if="pages && pages.length">
    <el-select :value="value" @change="$emit('input', $event)" filterable>
      <el-option v-for="(page, index) in pages" :key="'page' + index" :label="page.title || page.label" :value="page._id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { getAllPages } from '@/api/content'
export default {
  props: ['value', 'attribute', 'entityData'],
  data() {
    return {
      pages: [],
    }
  },
  methods: {
    async init() {
      const filter = {}
      if (this.attribute.code === 'parent_page' && this.entityData && this.entityData._id) {
        filter._id = { $ne: this.entityData._id }
      }
      const { success, pages } = await getAllPages({ filter })
        .then(res => res.data)
        .catch(() => ({ success: false }))
      if (success) {
        const defaultOptions = (this.attribute?.options || []).map(opt => {
          if (opt.label.toLowerCase() === 'none') {
            opt._id = null;
          }
          return opt;
        });
        this.pages = [...defaultOptions, ...pages]
      }
    }
  },
  mounted() {
    this.init()
  },
}
</script>
