<template>
    <el-tag :type="type" :class="className">{{ formatValue() }}</el-tag>
</template>
<script>
export default {
    name: 'type-tag',
    props: {
        row: {
            type: Object,
            require: true
        },
        column: {
            type: Object,
            require: true
        },
        className: {
            type: String,
            default: null
        },
    },
    data(){
        return {
            status: [
                {
                    code: ['active', 'success'],
                    value: 'success'
                },
                {
                    code: ['expired', 'failed'],
                    value: 'danger'
                },
                {
                    code: ['disabled', 'processing'],
                    value: 'info'
                },
            ]
        }
    },
    computed: {
        type() {
          const value = this.formatValue(), status = this.status.find(f => f.code.includes(value))
          if (status) return status.value
          return ''
        },
    },
    methods: {
        formatValue() {
            let value = this.row[this.column.field_name]
            if(this.column.relation) value = this.row[this.column.relation][this.column.field_name]
            return value
        }
    }
}
</script>
