import request from '@/utils/request'

export function uploadImage (formData, image = {}, dir = 'tmp', previousFile= '') {
  let paramObj = {dir: dir}
  if (previousFile) {
    paramObj.previous_file =  previousFile
  }
  return request({
    url: '/upload/uploadImage',
    method: 'post',
    data: formData,
    timeout: 0,
    params: {...paramObj},
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (e) => {
      if (image) {
        image.percent = Math.floor(e.loaded * 100 / e.total)
      }
    }
  })
}

export function getFolderTree () {
  return request({  
    url: '/upload/tree',
    method: 'get'
  })
}

export function getFiles (params) {
  return request({
    url: '/upload/detail',
    method: 'post',
    data: params
  })
}

export function deleteFile (id) {
  return request({
    url: `/upload/${id}`,
    method: 'delete'
  })
}

export function getFileInfoById (fileId) {
  return request({
    url: `/upload/${fileId}`,
    method: 'get'
  })
}

export function createFolder (data) {
  return request({
    url: `/upload/folder`,
    method: 'post',
    data: data
  })
}

export function deleteFileByName (filename) {
  return request({
    url: `/upload/removeImage`,
    method: 'post',
    data: {filename}
  })
}

// Only fetch the file from URL and response as is
export function fetchMedia(url) {
  return request({
    url: `/upload/media/fetch`,
    method: 'get',
    params: { url },
    responseType: 'blob',
    timeout: 0,
  })
}
