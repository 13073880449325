<template>
  <el-radio-group class="el-radio-group__jovy" v-if="isGroup" :value="value" v-bind="$attrs">
    <template v-if="Array.isArray(options) && options.length">
      <el-radio class="el-radio__jovy" v-for="option in options" :key="option.value" :label="option.value" @change="$emit('input', option.value)">{{ option.label }}</el-radio>
    </template>
    <slot />
  </el-radio-group>
  <el-radio v-else class="el-radio__jovy" :value="value" :label="label" v-bind="$attrs" @change="$emit('input', label)">
    <slot />
  </el-radio>
</template>
<script>
export default {
  name: 'jv-radio',
  props: {
    value: {
      type: undefined,
    },
    label: {
      type: undefined
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.el-radio.el-radio__jovy {
  color: var(--jv-text);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  :deep() {
    .el-radio__input {
      .el-radio__inner {
        width: 16px;
        height: 16px;
        background: --jv-white;
        border: 1px solid var(--jv-gray-350);
        &::after {
          width: 5px;
          height: 5px;
        }
      }
      &.is-checked {
        .el-radio__inner {
          background: var(--jv-bg-primary);
          border-color: var(--jv-bg-primary);
        }
        &+.el-radio__label {
          color: var(--jv-text);
        }
      }
    }
    .el-radio__label {
      padding-left: 4px;
    }
  }
}
</style>