<template>
  <div class="d-flex d-lg-none mb-3 align-items-center align-items-md-start">
      <div class="toolbar-buttons mr-auto">
        <h4>{{ pageTitle }}</h4>
      </div>
      <div class="toolbar-buttons ml-auto">
        <template v-for="(button, i) in buttons">
          <template v-if="button.more">
            <el-dropdown :key="i" size="medium" split-button :type="button.type" @click="doAction(button)" @command="handleMoreAction">
              {{ button.label }}
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(btn, j) in button.more" :key="j" :command="btn">{{ btn.label }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-else>
            <jv-button
              :key="i"
              size="medium"
              :type="button.type"
              :icon="button.icon"
              class="mx-1"
              @click="doAction(button)"
              :disabled="button.disabled"
              :id="button.id || `toolbar-button-${i}`"
            >
              {{ button.label }}
            </jv-button>
          </template>
        </template>
      </div>
    </div>
</template>

<script>
  // import WebsiteWidget from './WebsiteWidget'

  export default {
    // components: { WebsiteWidget },
    props: ['buttons', 'noFixed', 'websiteSelector', 'subtitle', 'success', 'noSaveToStore'],
    data () {
      return {
        scrollY: null,
        originalTop: 0,
        isFixed: false
      }
    },
    methods: {
      updateButtonsToStore() {
        if (this.noSaveToStore) {
          this.$store.dispatch('setToolbarWidgets', [])
        } else {
          this.$store.dispatch('setToolbarWidgets', this.buttons)
        }
      },
      doAction (button) {
        if (button.callback && typeof button.callback == 'function') {
          button.callback()
        } else if (button.route) {
          let route = { name: button.route }
          if (button.routeParams) {
            route.params = button.routeParams
          }
          this.$router.push(route)
        }  else if (button.path) {
          this.$router.push(button.path)
        } else {
          console.warn(`Button "${button.label}" has an invalid action`)
        }
      },
      handleMoreAction (btn) {
        this.doAction(btn)
      },
      handleWindows: function () {
        this.scrollY = Math.round(window.scrollY)
      },
      handleWebsiteChanged (id) {
        this.$emit('websiteChanged', id)
        this.$parent.handleWebsiteChanged && this.$parent.handleWebsiteChanged(id)
      }
    },
    computed: {
      pageTitle () {
        return this.$store.getters.pageTitle
      },
      wid () {
        return this.$parent.wid || 0
      },
      showWebsites () {
        return typeof this.$parent.handleWebsiteChanged === 'function' || this.websiteSelector
      }
    },
    mounted () {
      let el = this.$el.querySelector('.wr-scroll-source')
      if (el) {
        this.originalTop = +el.getBoundingClientRect().top + +el.getBoundingClientRect().height
      }
      window.addEventListener('scroll', this.handleWindows)
      this.updateButtonsToStore()
    },
    watch: {
      scrollY () {
        this.isFixed = this.scrollY > this.originalTop;
      },
      subtitle (val) {
        this.$store.commit('SET_SUBTITLE', val)
      },
      buttons () {
        this.updateButtonsToStore()
      },
      success () {
        this.$store.commit('SET_SUCCESS', this.success)
      },
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleWindows)
      this.$store.dispatch('setToolbarWidgets', [])
      this.$store.commit('SET_SUBTITLE', '')
    }
  }
</script>

<style lang="scss" scoped>
  #main-toolbar {
    padding: 15px 0;
    margin-bottom: 5px;
  }
  .wr-scroll-fixed {
    position: fixed;
    top: 0;
    right: 0;
    background: #fff;
    width: 100%;
    z-index: 111;
    box-shadow: -4px -5px 7px black;
    padding: 15px 15px 15px ($sidebar-size + 15);
    height: $wr-nav-menu-height;
    max-height: $wr-nav-menu-height;
  }
  .toolbar-buttons {
    h4 {
      @media(max-width: $sm) {
        font-size: 1.1rem !important;
      }
    }
  }

</style>
