<template>
  <div class="position-relative">
    <el-input
      type="textarea"
      :placeholder="placeholder"
      class="h-100 el-textarea__jovy"
      :rows="rows"
      v-bind:value="value"
      @input="handleChange"
      :maxlength="maxLength">
    </el-input>
    <span class="limit-note" v-if="extantLimitNote">{{ extantLimitNote }}</span>
  </div>
</template>
<script>
export default {
  name: 'Textarea',
  props: {
    maxLength: Number,
    value: String,
    placeholder: String,
    rows: { type: Number, default: 5 }
  },
  computed: {
    extantLimitNote() {
      return this.maxLength && this.maxLength - (this.value ? this.value.length : 0)
    },
  },
  methods: {
    handleChange(event) {
      this.$emit('input', event)
    },
  },
}
</script>
<style lang="scss" scoped>
span.limit-note {
  position: absolute;
  bottom: 5px;
  right: 15px;
  color: var(--jv-tertiary);
  font-size: 12px;
  line-height: 12px;
}
:deep(.el-textarea__jovy) {
  .el-textarea__inner {
    height: 100%;
  }
}
</style>
