<template>
  <div></div>
</template>

<script>
import idleJS from 'idle-js'
import { getAdminUrl } from '@/utils/helper'
export default {
  computed: {
    lock() {
      return this.$store.state.user.lock
    },
    isLocked() {
      return this.$store.getters.isLocked
    },
  },
  watch: {
    'lock.enabled'(value) {
      if (value) {
        this.resetIdleCtx()
      } else if (this.ctxIdle) {
        this.ctxIdle.stop()
      }
    },
    'lock.period'() {
      this.resetIdleCtx()
    },

    isLocked(value) {
      if (value) {
        this.ctxIdle.stop()
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
        this.resetIdleCtx()
      }
    },
  },
  data() {
    return {
      ctxIdle: null,
      resetIdleCtxTimer: false,
      events: ['mousemove', 'scroll', 'keydown', 'mousedown', 'touchstart'],
    }
  },
  methods: {
    resetIdleCtx() {
      if (!this.resetIdleCtxTimer) {
        this.resetIdleCtxTimer = true
        if (this.ctxIdle) {
          this.ctxIdle.stop()
        }
        const period = this.getMilisOfMinute(this.lock.period)
        const oneDayMs = 86394000
        if (period > oneDayMs * 2) {
          // not set idleJS while ExpiresIn > 2 day
          return
        }
        this.createIdleInstance(period)
        setTimeout(() => {
          this.resetIdleCtxTimer = false
        }, 300000)
      }
    },
    createIdleInstance(period) {
      this.ctxIdle = new idleJS({
        idle: period,
        onIdle: this.handleIdle,
        onActive: this.handleActive,
      }).start()
      this.setIdleStartTime()
    },
    handleIdle() {
      if (!document.hasFocus()) {
        this.ctxIdle.reset()
      }
      if (this.$route.path !== '/login') {
        const idleStartTime = this.getStorageValue('idleStart')
        const period = this.getMilisOfMinute(this.lock.period)
        const currentTime = new Date().getTime()
        if (currentTime > idleStartTime + period) {
          this.handleRedirectLogin()
        }
      }
    },
    setIdleStartTime() {
      const currentTime = new Date().getTime()
      this.setStorage('idleStart', currentTime)
    },
    getMilisOfMinute(minute) {
      return minute * 60 * 1000
    },
    handleRedirectLogin() {
      if (!window.HM_ENV.LOCAL) {
        this.logout()
      }
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        let adminUrl = getAdminUrl(window.location.href)
        // eslint-disable-next-line no-undef
        window.location.href = `${HM_ENV.API_AUTH}/logout?inactive=true&client=${this.$store.getters['client']}&app_url=${adminUrl}`
        //window.location.href = `${this.$store.getters['baseUrl']}/api/auth/sso/logout?inactive=true&client=${this.$store.getters['client']}&app_url=${adminUrl}`
      })
    },
    initStorageEvt() {
      window.addEventListener('storage', ({ key, oldValue, newValue }) => {
        if (oldValue == newValue) return
        if (key === 'idleStart' && typeof JSON.parse(JSON.parse(JSON.stringify(newValue))) === 'number') {
          this.resetIdleCtx()
        }
      })
    },
    initActionEvt() {
      this.events.forEach((event) => {
        window.addEventListener(event, this.resetIdleCtx, { passive: true })
      })
    },
    removeActionEvt() {
      this.events.forEach((event) => {
        window.removeEventListener(event, this.resetIdleCtx, { passive: true })
      })
    },
    removeStorageEvt() {
      window.removeEventListener('storage', undefined)
    },
    setStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value))
    },
    getStorageValue(key) {
      return JSON.parse(localStorage.getItem(key))
    },
  },
  created() {
    this.setStorage('idleStart', new Date().getTime())
  },
  mounted() {
    this.resetIdleCtx()
    this.initStorageEvt()
    this.initActionEvt()
  },
  beforeDestroy() {
    this.removeStorageEvt()
    this.removeActionEvt()
  },
}
</script>
