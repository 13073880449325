export const ORDER_STATUS = {
  PROCESSING: 'processing',
  READY_TO_PICK: 'ready_to_pick',
  PACKING: 'packing',
  READY_TO_SHIP: 'ready_to_ship',
  COMPLETE: 'complete',
  PENDING: 'pending',
  CANCELED: 'canceled',
  BACKORDERED: 'backordered',
  BALANCE_DUE: 'balance_due',
  FRAUD_REVIEW: 'fraud_review',
  QUOTE: 'quote',
}

export const SELECT_YES_NO = [
  { _id: true, name: 'Yes' },
  { _id: false, name: 'No' }
]

export const ONE_MEGABYTE = 1048576

export const CLIENT_TYPES = {
  'full': { code: 'full', name: 'Full eCommerce' }, /* aka. JFC */
  'essential': { code: 'essential', name: 'Essential' }, /* aka. JEC */
  'marketplace': { code: 'marketplace', name: 'Marketplace (master)' }, /* aka. JMC Master */
  'marketplaceseller': { code: 'marketplaceseller', name: 'Marketplace (seller)' }, /* aka. JMC Seller */
}

export default { ORDER_STATUS, SELECT_YES_NO, ONE_MEGABYTE, CLIENT_TYPES }
