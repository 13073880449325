/* eslint-disable no-undef */
import Vue from 'vue'
import Router from 'vue-router'
// Load Auth Middleware
import routerBefore from './router-before'
import routerAfter from './router-after'
// Component
import RootLayout from '@/components/Layouts/RootLayout'
import NotFound from '@/components/GeneralView/NotFoundPage'

import SystemRoutes from './children/system'
import CatalogRoutes from './children/catalog'
import EtsyRoutes from './children/etsy'
import CustomerRoutes from './children/customer'
import SaleRoutes from './children/sale'
import ContentRoutes from './children/content'
import MarketingRoutes from './children/marketing'
import MessagesRoutes from './children/messages'
import ReportRoutes from './children/report'
import SettingsRoutes from './children/settings'
import { getAdminUrl, getParsedCallbackUrl } from '@/utils/helper'
import { clearClientData } from '@/utils/auth'

Vue.use(Router)

const pageRoutes = [
  {
    path: '/login',
    name: 'Login',
    beforeEnter: async (to, from) => {
      const clientId = clearClientData(to.query.client)
      const adminUrl = getAdminUrl(window.location.href)
      const callbackUrl = getParsedCallbackUrl(`${adminUrl}/#/callback`)

      let ssoLoginUrl = `${HM_ENV.API_AUTH}/login/sso?callbackUrl=${callbackUrl}`
      if (clientId) {
        ssoLoginUrl += `&client=${clientId}`
      }

      const target = to.query.target || from?.path || '/'
      // array to store the admin paths that we don't want user to redirect back to
      const invalidTargetPath = ['/invites-user'];
      if (!invalidTargetPath.includes(target)) {
        ssoLoginUrl+= `&target=${target}`
      }

      if (to.query.flow) {
        ssoLoginUrl += `&flow=${to.query.flow}`
      }
      if (to.query.email) {
        ssoLoginUrl += `&email=${encodeURIComponent(to.query.email)}`
      }

      setTimeout(()=> {
        window.location.href= ssoLoginUrl
      }, 100)
    },
    component: () => import('@/components/Pages/Login')
  },
  {
    path: '/invites-user',
    name: 'Create New User',
    component: () => import('@/components/Pages/CreateNewUser')
  },
  {
    path: '/callback',
    name: 'SSO Login',
    component: () => import('@/components/Pages/AuthCallback')
  }
]

const adminRoutes = {
  path: '/',
  component: RootLayout,
  redirect: 'sales/orders-management',
  name: 'app',
  meta: {
    authRequired: true,
  },
  children: [
    {
      path: 'dashboard',
      component: () => import('@/components/Views/Overview'),
      name: 'dashboard',
      meta: {
        title: 'Dashboard',
      },
    },
    {
      path: 'system',
      component: () => import('@/components/Layouts/ChildLayout'),
      children: SystemRoutes,
      meta: {
        title: 'System',
      },
    },
    {
      path: 'catalog',
      component: () => import('@/components/Layouts/ChildLayout'),
      children: CatalogRoutes,
      meta: {
        title: 'Catalog',
      },
    },
    {
      path: 'etsy',
      component: () => import('@/components/Layouts/ChildLayout'),
      children: EtsyRoutes,
      meta: {
        title: 'Etsy',
      },
    },
    {
      path: 'customer',
      alias: 'sales/customer',
      component: () => import('@/components/Layouts/ChildLayout'),
      children: CustomerRoutes,
      meta: {
        title: 'Customer',
      },
    },
    {
      path: 'sales',
      component: () => import('@/components/Layouts/ChildLayout'),
      children: SaleRoutes,
      meta: {
        title: 'Sales',
      },
    },
    {
      path: 'content',
      component: () => import('@/components/Layouts/ChildLayout'),
      children: ContentRoutes,
      meta: {
        title: 'Content',
      },
    },
    {
      path: 'marketing',
      component: () => import('@/components/Layouts/ChildLayout'),
      children: MarketingRoutes,
      meta: {
        title: 'Marketing',
      },
    },
    {
      path: 'messages',
      component: () => import('@/components/Layouts/ChildLayout'),
      children: MessagesRoutes,
      meta: {
        title: 'Messages',
      },
    },
    {
      path: 'report',
      component: () => import('@/components/Layouts/ChildLayout'),
      children: ReportRoutes,
      meta: {
        title: 'Report',
      },
    },
   {
      path: 'settings',
      component: () => import('@/components/Views/Settings/Main'),
      children: SettingsRoutes,
      meta: {
        title: 'Settings',
      },
    },
  ],
}

const registrationRoute = {
  name: 'registration',
  path: '/registration/:issue?',
  component: () => import('@/components/Pages/Registration'),
}

const router = new Router({
  // mode: 'history',
  routes: [
    registrationRoute,
    adminRoutes,
    ...pageRoutes,
    { path: '*', name: 'not_found', component: NotFound, meta: { title: '404 Not Found' } }
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        behavior: 'smooth',
        selector: to.hash,
        offset: { x: 0, y: 155 },
      }
    }
  },
})

router.onError((error) => {
  if (/Loading (chunk|CSS chunk) [\w|-]* failed/gi.test(error.message) && navigator.onLine) {
    if (router.history.pending) {
      router.go(router.history.pending)
    }
  }
});

router.beforeEach(routerBefore)
router.afterEach(routerAfter)

export default router
