import store from '../store'
import { getUser } from '@/utils/auth'
import { checkClientType } from '@/utils/client'

const nonRestrictedRoutes = ['login', 'orders-management', 'callback', 'registration', 'shop-setup', 'invites-user']
export default function (to, from, next) {
  const restrictedRoutes = store.getters['restrictedRoutes'] && store.getters['restrictedRoutes'].length
    ? store.getters['restrictedRoutes']
    : JSON.parse(localStorage.getItem('restrictedItems'))

  const currentStoreUser = store.getters['user']
  if (currentStoreUser && restrictedRoutes && restrictedRoutes.length) {
    if (checkClientType(['essential', 'marketplaceseller'], { isSuperClient: false })
      && !currentStoreUser.is_super
      && to.path
      && restrictedRoutes.some(restrictedRoute => to.path.startsWith(restrictedRoute.url))
    ) {
      if (from.path.includes('404')) { // go back from not found page
        return next(store.getters.lastRoute)
      }
      return next('/404')
    }
  } else if (nonRestrictedRoutes.every(item => !to.path.includes(item))) {
    return next('/')
  }

  store.dispatch('setLastRoute', from.fullPath)
  const isAuthRequired = to.matched.some((re) => re.meta.authRequired)
  if (to.meta.isRerenderComponent) {
    store.dispatch('reRender')
  }

  const currentUser = getUser()
  if (isAuthRequired && !currentUser) {
    next('/login')
  } else if (to.path === '/login' && currentUser) {
    next('/')
  } else if (to.path === '/logout' && currentUser) {
    store.dispatch('logout').then(() => {
      next('/login')
    })
  } else {
    next()
  }
}
