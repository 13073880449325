<template>
    <div :class="className">{{formatValue()}}</div>
</template>
<script>
import { formatNumber } from '@/utils/helper'
export default {
    name: 'type-price',
    props: {
        row: {
            type: Object,
            require: true
        },
        column: {
            type: Object,
            require: true
        },
        className: {
            type: String,
            default: null
        }
    },
    methods: {
        formatValue() {
            let value = this.row[this.column.field_name]
            if(this.column.relation) value = this.row[this.column.relation][this.column.field_name]
            return formatNumber(value, 'price')
        }
    }
}
</script>
