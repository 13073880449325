<template>
  <el-select
    :disabled="disabled"
    :size="size"
    :placeholder="placeholder"
    :value="selected"
    @change="handleChange"
    :popper-class="`jovy-select-popper ${isEditTemplate ? 'select-popper-cls' : ''}`"
    class="jovy-select"
    :filterable="filterable"
  >
    <template v-if="prefixIcon" #prefix>
      <i :class="['icon', prefixIcon]" :style="{ color: iconColor }"></i>
    </template>
    <slot></slot>
  </el-select>
</template>

<script>
export default {
  name: 'Select',
  props: ['placeholder', 'value', 'change', 'size', 'disabled', 'filterable', 'prefixIcon', 'iconColor'],
  inject: {
    isEditTemplate: {
      default: false, 
    }
  }, 
  computed: {
    selected: {
      get() {
        return this.value;
      },
    },
  },
  methods: {
    handleChange($event) {
      this.$emit('input', $event, this.selected)
      this.$emit('change', $event)
    },
  },
}
</script>

<style lang="scss">

.jovy-select {
  .el-input {
    & .el-input__inner {
      border: 1px solid var(--jv-gray-300);
      border-color: var(--jv-gray-300);
      border-radius: 4px;

      &:focus {
        border: 1px solid var(--jv-gray-300);
        border-color: var(--jv-gray-300);
      }
    }

    &.is-focus .el-input__inner {
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14);
      border-radius: 4px 4px 0 0;
    }
    &.el-input--suffix {
      .el-input__inner {
        padding-right: 40px;
      }
    }
    .el-input__suffix {
      border-left: 1px solid var(--jv-gray-300);
      padding: 0;
      right: 0;
      width: 36px;
      line-height: 36px;
    }
    &.el-input--prefix {
      .el-input__inner {
        padding-left: 44px;
      }
    }
    .el-input__prefix {
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0px;
      width: 36px;
      background: var(--jv-gray-100);
      color: var(--jv-secondary);
      border: solid 1px var(--jv-gray-300);
      border-radius: 4px 0px 0px 4px;
      .icon {
        font-size: 16px;
        color: var(--jv-secondary);
      }
    }
  }
}

.jovy-select-popper {
  border: 1px solid var(--jv-gray-300);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14);
  margin: 0 !important;

  .popper__arrow {
    display: none;
  }

  .el-scrollbar__view {
    padding: 0;
  }

  .el-select-dropdown__item {
    border-radius: 4px;
    color: var(--jv-text);

    &.selected {
      color: var(--jv-brand-500);
    }
  }
}

.select-popper-cls {
  & .el-select-dropdown__item.selected {
    color: #04747c;
  }
}

</style>
