<template>
  <el-checkbox
    :class="['el-checkbox__jovy', { 'no-label': !label }]"
    :indeterminate="indeterminate"
    :value="value"
    :checked="value"
    @change="handleChange"
    >
    <template v-if="label" >
      <span :class="labelClass">{{ label }}</span>
    </template>
    <slot />
  </el-checkbox>
</template>
<script>
export default {
  name: 'jv-checkbox',
  props: ['value', 'label', 'indeterminate', 'labelClass'],
  methods: {
    handleChange(checked) {
      this.$emit('input', checked)
      this.$emit('change')
    },
  },
}
</script>
<style lang="scss" scoped>
.el-checkbox {
  &.el-checkbox__jovy {
    background: var(--jv-bg-primary-plain);
    padding: 0;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 0;
    height: 40px;
    :deep(.el-checkbox__label),
    :deep(.el-checkbox__input) {
      line-height: 40px;
      padding: 0px 7px 0px 7px;
      border: 1px solid var(--jv-primary-border);
    }
    :deep(.el-checkbox__input) {
      margin-top: 0;
      padding-top: 2px;
      line-height: 38px;
      border-radius: 4px 0 0 4px;
      &.is-checked {
        background-color: var(--jv-green-200);
      }
      .el-checkbox__inner {
        border-color: var(--jv-green-400);
      }
    }
    :deep(.el-checkbox__label) {
      border-left: 0;
      color: var(--jv-green-400);
      font-weight: 500;
      border-radius: 0 4px 4px 0;
      flex-grow: 1;
    }
    &[size='mini'] {
      height: 32px;
      :deep(.el-checkbox__label) {
        line-height: 30px;
      }
      :deep(.el-checkbox__input) {
        line-height: 28px;
      }
    }
    &[size='small'] {
      height: 32px;
      :deep(.el-checkbox__label) {
        line-height: 34px;
      }
      :deep(.el-checkbox__input) {
        line-height: 28px;
      }
    }
    &[size='medium'] {
      height: 36px;
      :deep(.el-checkbox__label) {
        line-height: 34px;
      }
      :deep(.el-checkbox__input) {
        line-height: 32px;
      }
    }
    &[size='large'] {
      height: 38px;
      :deep(.el-checkbox__label) {
        line-height: 38px;
      }
      :deep(.el-checkbox__input) {
        line-height: 36px;
      }
    }
    &.is-checked,
    &.is-indeterminate {
      :deep(.el-checkbox__label) {
        color: var(--jv-green-400);
      }
    }
    :deep(.el-checkbox__input) {
      &.is-checked,
      &.is-indeterminate {
        .el-checkbox__inner {
          background-color: var(--jv-bg-primary);
          border-color: var(--jv-bg-primary);
        }
      }
    }
    &.no-label {
      :deep(.el-checkbox__label) {
        display: none;
      }
      :deep(.el-checkbox__input) {
        border-radius: 4px;
      }
    }
    &.default {
      :deep(.el-checkbox__input) {
        background-color: transparent;
        border: 0;
      }
      :deep(.el-checkbox__label) {
        background-color: transparent;
        border: 0;
        color: var(--jv-text);
      }
    }
  }

  &.el-checkbox__jovy.product-section {
    height: 34px;
    margin: 0;

    :deep(.el-checkbox__input) {
      align-items: center;
      background: var(--jv-white);
      border: none;
      display: flex;
      flex-direction: row;
      height: fit-content;
      padding: 4px;
      width: fit-content;

      & .el-checkbox__inner {
        background: var(--jv-white);
        border: 1px solid var(--jv-gray-350);
        border-radius: 2px;
        height: 18px;
        position: relative;
        width: 18px;
      }

      &.is-checked {
        background: var(--jv-white);

        & .el-checkbox__inner {
          background: var(--jv-green-400);
          border: 1px solid var(--jv-green-400);

          &:after {
            font-size: 18px;
            left: 47%;
            position: absolute;
            top: 47%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &.is-checked {
      :deep(.el-checkbox__label) {
        color: var(--jv-text);
      }
    }

    :deep(.el-checkbox__label) {
      background: var(--jv-white);
      border: none;
      color: var(--jv-text);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin: 0 0 0 5px;
      padding: 0;
    }
    &.no-bg {
      background: none;
    }
    &.no-padding {
      :deep(.el-checkbox__input) {
        padding: 0px;
      }
    }
  }
}
</style>
